import React from "react";
const RenderKMLocation = ({
    item,
    className
}) => {
    return (
        <>
            <div className={className ? className : 'mb-3 col-md-2 col-sm-6'}>
                <span className='text-secondary'>
                    KM Inicial:
                </span> {item?.pv_loc_km_ini},{item?.pv_loc_km_init_mts} m
            </div>
            <div className={className ? className : 'mb-3 col-md-2 col-sm-6'}>
                <span className='text-secondary'>
                    KM final:
                </span> {item?.pv_loc_km_fim},{item?.pv_loc_km_fim_mts} m
            </div>
        </>
    )
}
export default RenderKMLocation