import React, { useContext, useEffect } from 'react'
import { formContext } from '../../../context/formContext'
import { requestContext } from '../../../context/requestContext'
import { find } from '../../../services/arrayHandless'
import { addPoints } from '../../../services/formateNumber'
import { removeDots } from '../../../services/stringHandless'
const FormWorkOfArt = () => {
	const { state, onChange, handleForm } = useContext(formContext)
	const { obArtType,
		obArtElem,
		obArtClass,
		highway,
		cardinal_points,
		data,
		getAllItemHighway,
		getItemCardinalPoints
	} = useContext(requestContext)
	const {
		ob_art_desc,
		ob_art_item_cod,
		ob_art_tipo,
		ob_art_class,
		ob_art_elem,
		ob_art_loc_km,
		ob_art_loc_mts,
		ob_art_rodovia,
		ob_art_lote,
		ob_art_sent,
		ob_art_ativo,
	} = state
	useEffect(() => {
		const loadHighwsByLote = () => {
			if (ob_art_lote && data) {
				const { data: items } = data
				if (!items?.length) {
					return
				}
				const { data: loteSelected } = find(items, 'conc_lote', parseInt(ob_art_lote))
				if (!loteSelected) {
					return
				}
				const { conc_cod: rod_concessionaria } = loteSelected
				getAllItemHighway(rod_concessionaria)
			}
		}
		loadHighwsByLote()
	}, [ob_art_lote, data])

	useEffect(() => {
		if (ob_art_rodovia !== '' && ob_art_rodovia > 0) {
			getItemCardinalPoints(ob_art_rodovia)
		}
	}, [ob_art_rodovia])
	const onBlurItemCod = ({ target: { value } }) => {
		const temp_value = removeDots(value)
		const new_value = addPoints(temp_value)
		return handleForm({
			ob_art_item_cod: new_value
		})
	}
	return (
		<div className="row">
			<div className="form-group col-12">
				<label>Nome:</label>
				<div className="input-group">
					<input type="text"
						onChange={onChange}
						name='ob_art_desc' value={ob_art_desc}
						className={`form-control ${!ob_art_desc && 'is-invalid'}`}
						placeholder='Nome da obra de arte' />
				</div>
			</div>
			<div className="form-group col-md-6 col-sm-6">
				<label>Código:</label>
				<div className="input-group">
					<input type="text"
						onChange={onChange}
						onBlur={onBlurItemCod}
						name='ob_art_item_cod' value={ob_art_item_cod}
						placeholder="Código da obra de arte"
						className={`form-control ${!ob_art_item_cod && 'is-invalid'}`}
					/>
				</div>
			</div>
			<div className="form-group col-md-6 col-sm-6">
				<label>Tipo:</label>
				<div className="input-group">
					<select name="ob_art_tipo" value={ob_art_tipo}
						className={`form-control ${!ob_art_tipo && 'is-invalid'}`}
						onChange={onChange}
					>
						<option>Selecione</option>
						{obArtType?.data?.map((item) => (
							<option value={item.ob_art_tipo_cod} key={item.ob_art_tipo_cod}>
								{item.ob_art_tipo_desc}
							</option>)
						)}
					</select>
				</div>
			</div>
			<div className="form-group col-md-6 col-sm-6">
				<label>Elementos:</label>
				<div className="input-group">
					<select name="ob_art_elem" value={ob_art_elem}
						className={`form-control ${!ob_art_elem && 'is-invalid'}`}
						onChange={onChange}
					>
						<option>Selecione</option>
						{obArtElem?.data?.map((item) => (
							<option value={item.ob_art_elem_cod} key={item.ob_art_elem_cod}>
								{item.ob_art_elem_desc}
							</option>)
						)}
					</select>
				</div>
			</div>
			<div className="form-group col-md-6 col-sm-6">
				<label>Classificador:</label>
				<div className="input-group">
					<select name="ob_art_class" value={ob_art_class}
						className={`form-control ${!ob_art_class && 'is-invalid'}`}
						onChange={onChange}
					>
						<option>Selecione</option>
						{obArtClass?.total > 0 && obArtClass.data.map((item) => (
							<option value={item.ob_art_class_cod} key={item.ob_art_class_cod}>
								{item.ob_art_class_desc}
							</option>)
						)}
					</select>
				</div>
			</div>
			<div className="form-group col-md-4 col-sm-6">
				<label>Lote:</label>
				<div className="input-group">
					<select name="ob_art_lote" value={ob_art_lote}
						className={`form-control ${!ob_art_lote && 'is-invalid'}`}
						onChange={onChange}
					>
						<option>Selecione</option>
						{data?.data?.map((item) => (
							item.lot_conc_desc !== '0' && (
								<option value={item.conc_lote} key={item.conc_lote}>
									{item.lot_conc_desc} - {item.conc_nome}
								</option>)
						)
						)}
					</select>
				</div>
			</div>
			<div className="form-group col-md-4 col-sm-6">
				<label>Rodovia:</label>
				<div className="input-group">
					<select name="ob_art_rodovia" value={ob_art_rodovia}
						className={`form-control ${!ob_art_rodovia && 'is-invalid'}`}
						onChange={onChange}
					>
						<option>{(ob_art_lote !== '' && ob_art_lote >= 0) ? 'Selecione' : 'Selecione um lote'}</option>
						{
							(ob_art_lote !== '' && ob_art_lote >= 0)
								? highway?.data?.map((item) => (
									<option value={item?.rod_cod} key={item?.rod_cod}>
										{item?.rod_desc}
									</option>)
								)
								: null
						}
					</select>
				</div>
			</div>
			<div className="form-group col-md-4 col-sm-6">
				<label>Sentido:</label>
				<div className="input-group">
					<select name="ob_art_sent" value={ob_art_sent}
						className={`form-control ${!ob_art_sent && 'is-invalid'}`}
						onChange={onChange}
					>
						<option>{(ob_art_rodovia !== '' && ob_art_rodovia > 0) ? 'Selecione' : 'Selecione uma rodovia'}</option>
						{cardinal_points?.data?.map((item) => (
							<option value={item.rod_sent_cod} key={item.rod_sent_cod}>
								{item.rod_sent_desc}
							</option>)
						)}
					</select>
				</div>
			</div>
			<div className="form-group col-4">
				<label>Km:</label>
				<div className="input-group">
					<input type="number"
						onChange={onChange}
						name='ob_art_loc_km' value={ob_art_loc_km}
						className={`form-control ${!ob_art_loc_km && 'is-invalid'}`}
					/>
				</div>
			</div>
			<div className="form-group col-4">
				<label>Metros:</label>
				<div className="input-group">
					<input type="number"
						onChange={onChange}
						name='ob_art_loc_mts' value={ob_art_loc_mts}
						className={`form-control ${!ob_art_loc_mts && 'is-invalid'}`}
					/>
				</div>
			</div>
			<div className="form-group col-4">
				<label>Situação:</label>
				<div className="input-group">
					<select name="ob_art_ativo" value={ob_art_ativo}
						className={`form-control ${!ob_art_ativo && 'is-invalid'}`}
						onChange={onChange}
					>
						<option value={0}>
							Inativo
						</option>
						<option value={1}>
							Ativo
						</option>
					</select>
				</div>
			</div>
		</div>
	)
}
export default FormWorkOfArt