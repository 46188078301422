import React, { useContext, useEffect, useRef, useState } from 'react'
import { LOGO } from '../../../../../config/flavor'
import { formatDate } from '../../../../../services/dateHandless'
import { requestContext } from '../../../../../context/requestContext'
import { useNavigate } from 'react-router-dom'
import { useReactToPrint } from 'react-to-print'
import { handleSuccess, handleWaring } from '../../../../../services/isValid'
import { stylesReport } from '../../../../../css/globlalStyle'
const ReportNCPhotosPDF = ({
	reportData,
	handleCancel
}) => {
	const { setInfoRequest } = useContext(requestContext)
	const [printMode, setPrintMode] = useState(false)
	const [invisible, setInvisible] = useState(false)
	const componentRef = useRef(null)
	const navigate = useNavigate()

	const styles = {
		page: {
			...stylesReport.page,
			height: printMode ? '100vh' : 'auto',
		},
		section: stylesReport.section,
		header: stylesReport.header,
		headerImage: stylesReport.headerImage,
		headerText: stylesReport.headerText,
		line: stylesReport.line,
		smallText: stylesReport.smallText,
		verySmallText: stylesReport.verySmallText,
		smallHeaderText: stylesReport.smallHeaderText,
		breakLine: stylesReport.breakLine,
		box: stylesReport.box,
		photoBox: {
			...stylesReport.photoBox,
			height: printMode ? '42vh' : 'auto',
		},
		photoItem: stylesReport.photoItem,
		imgPhoto: stylesReport.imgPhoto,
		invisible: stylesReport.invisible,
		visible: stylesReport.visible
	}
	const generatePdfDocument = async () => {
		setInvisible(true)
		setPrintMode(true)
		setInfoRequest(handleWaring('Se certifique de que todas as imagens foram carregadas antes de imprimir'))
		setInfoRequest(handleWaring('Aguarde gerando relatório'))
		setTimeout(() => {
			handlePrint()
		}, 6000)
	}
	const handlePrint = useReactToPrint({
		content: () => componentRef.current,
		documentTitle: 'Relatório de conservação',
		copyStyles: true,
		onAfterPrint: () => afterPrint(),
	})
	const afterPrint = () => {
		setInvisible(false)
		setPrintMode(false)
		if (handleCancel) {
			handleCancel()
		}
		setInfoRequest(handleSuccess('Relatório gerado com sucesso!'))
	}
	return (
		<div ref={componentRef}>
			{reportData?.length ? (
				reportData?.map(item => (
					<>
						<div key={item} size="A4" style={styles.page}>
							<div style={styles.header}>
								<img src={require('../../../../../assets/Artesp/images/relatorio_logo.png')} alt="image_logo" style={styles.headerImage} />
								<div style={styles.headerText}>
									<p style={styles.smallHeaderText}>Relatório de conservação</p>
									<p style={styles.smallHeaderText}>Gerado no dia {formatDate()}</p>
								</div>
							</div>
							<div style={styles.breakLine} />
							<div style={styles.box}>
								<p style={{ ...styles.smallText, fontWeight: 'bold' }}>Detalhes</p>
								<div style={styles.breakLine} />
								<div style={styles.line}>
									<p style={{ ...styles.smallText, width: '50%' }}>Não conformidade Nº: {item.pedido_venda.sisf_sinc_id || item.pedido_venda.ped_venda_cod} (Nº Rastreio: {item.pedido_venda.ped_venda_cod})</p>
									<p style={{ ...styles.smallText, width: '50%' }}>Concessionária: {item.pedido_venda.conc_nome}</p>
								</div>
								<div style={styles.line}>
									<p style={{ ...styles.smallText, width: '50%' }}>Lote: {item.pedido_venda.lot_conc_desc}</p>
									<p style={{ ...styles.smallText, width: '50%' }}>Data: {formatDate(item.pedido_venda.ped_venda_date)}</p>
								</div>
								<div style={styles.line}>
									<p style={styles.smallText}>Rodovia: {item.pedido_venda.rod_desc}</p>
								</div>
							</div>
							<div style={styles.breakLine} />
							{item?.pedido_venda_servico?.length > 0 && item?.pedido_venda_servico?.map(servico => (
								<>
									<div key={servico} style={styles.box}>
										<p style={{ ...styles.smallText, fontWeight: 'bold' }}>Serviço</p>
										<div style={styles.breakLine} />
										<div style={styles.line}>
											<p style={{ ...styles.smallText, width: '50%' }}>Tipo Atividade: {servico.serv_desc}</p>
											<p style={{ ...styles.smallText, width: '50%' }}>Grupo Atividade: {servico.serv_cat_desc}</p>
										</div>
										<div style={styles.line}>
											<p style={styles.smallText}>Atividade: {servico.serv_sub_cat_desc}</p>
										</div>
										<div style={styles.line}>
											<p style={{ ...styles.smallText, width: '50%' }}>Status: {servico.st_desc}</p>
											<p style={{ ...styles.smallText, width: '50%' }}>Prazo: {formatDate(servico.pvs_prazo_fim)}</p>
										</div>
									</div>
									<div style={styles.breakLine} />
									<div key={servico} style={styles.box}>
										<p style={{ ...styles.smallText, fontWeight: 'bold' }}>Localização</p>
										<div style={styles.breakLine} />
										<div style={styles.line}>
											<p style={{ ...styles.smallText, width: '30%' }}>Inicial: {servico.pv_loc_km_ini + ',' + servico.pv_loc_km_init_mts + ' Km'}</p>
											<p style={{ ...styles.smallText, width: '30%' }}>Final: {servico.pv_loc_km_fim + ',' + servico.pv_loc_km_fim_mts + ' Km'}</p>
											<p style={{ ...styles.smallText, width: '30%' }}>Sentido: {servico.rod_sent_desc}</p>
										</div>
										<div style={styles.line}>
											<p style={styles.smallText}>Observações: {servico.pv_loc_obs}</p>
										</div>
									</div>
									<div style={styles.breakLine} />
									{servico?.pedido_venda_servico_foto?.length > 0 && (
										<>
											<p style={{ ...styles.smallText, fontWeight: 'bold' }}>Fotos</p>
											<div style={styles.breakLine} />
											<div style={styles.photoBox}>
												{servico.pedido_venda_servico_foto.map(foto => !foto?.pvs_loc_foto_path?.includes('.mp4') && (
													<div style={styles.photoItem} key={foto.pvs_loc_foto_cod}>
														<img src={foto.pvs_loc_foto_path} alt={item.pvs_loc_foto_legenda} style={styles.imgPhoto} />
														<p style={styles.verySmallText}>{foto.pvs_loc_foto_legenda}</p>
													</div>
												))}
											</div>
										</>
									)}
								</>
							))}
						</div>
						{item?.otherPhotos?.length > 0 && <PhotoPage data={item.otherPhotos} styles={styles} />}
					</>

				))
			) : null}
			{!invisible && (
				<div className="container-fluid">
					<div className="card">
						<div className="card-body">
							<button className="btn btn-info mr-3" onClick={generatePdfDocument}>
								Imprimir <i className="fa fa-print"></i>
							</button>
							{handleCancel ? (
								<button className="btn btn-secondary" onClick={handleCancel}>
									Cancelar <i className="fa fa-times-circle"></i>
								</button>
							) : null}
						</div>
					</div>
				</div>
			)}
		</div>
	)
}

const PhotoPage = ({ data, styles }) => {
	return data.map(page => (
		<div key={page} size="A4" style={styles.page}>
			<div style={styles.header}>
				<img src={require('../../../../../assets/Artesp/images/relatorio_logo.png')} alt="image_logo" style={styles.headerImage} />
				<div style={styles.headerText}>
					<p style={styles.smallHeaderText}>Relatório de conservação (Continuação)</p>
					<p style={styles.smallHeaderText}>Gerado no dia {formatDate()}</p>
				</div>
			</div>
			<div>
				<div style={styles.photoBox}>
					{page.map(foto => (
						<div style={styles.photoItem} key={foto.pvs_loc_foto_cod}>
							<img src={foto.pvs_loc_foto_path} alt={foto.pvs_loc_foto_legenda} style={styles.imgPhoto} />
							<p style={styles.verySmallText}>{foto.pvs_loc_foto_legenda}</p>
						</div>
					))}
				</div>
			</div>
		</div>
	))
}

export default ReportNCPhotosPDF