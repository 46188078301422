import React, { useContext } from 'react'
import { formContext } from '../../../../context/formContext'
const FormStatusColor = () => {
	const { state, onChange } = useContext(formContext)
	const {
		st_cor_valor,
	} = state
	return (
		<div className="row">
			<div className="form-group col-md-6 col-sm-6">
				<label>Cor: {st_cor_valor}</label>
				<div className="input-group">
					<input type="color"
						onChange={onChange}
						name='st_cor_valor' value={st_cor_valor}
						className={`form-control ${!st_cor_valor && 'is-invalid'}`}
					/>
				</div>
			</div>
		</div>
	)
}
export default FormStatusColor