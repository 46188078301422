import React, { useContext } from 'react'
import { formContext } from '../../../context/formContext'
const ElementWorkOfArt = () => {
	const { state, onChange } = useContext(formContext)
	const {
		ob_art_elem_desc,
		ob_art_elem_ativo,
	} = state
	return (
		<div className="row">
			<div className="form-group col-md-6 col-sm-6">
				<label>Descrição:</label>
				<div className="input-group">
					<input
						type="text"
						onChange={onChange}
						name="ob_art_elem_desc"
						value={ob_art_elem_desc}
						className={`form-control ${!ob_art_elem_desc && 'is-invalid'
						}`}
						placeholder="Descrição do tipo de obra de arte"
					/>
				</div>
			</div>
			<div className="form-group col-md-6 col-sm-6">
				<label>Situação:</label>
				<div className="input-group">
					<select
						type="text"
						onChange={onChange}
						name="ob_art_elem_ativo"
						value={ob_art_elem_ativo}
						className={`form-control ${!ob_art_elem_ativo && 'is-invalid'
						}`}
					>
						<option value={0}>
							Inativo
						</option>
						<option value={1}>
							Ativo
						</option>
					</select>
				</div>
			</div>
		</div>
	)
}
export default ElementWorkOfArt