import React, { useContext, useEffect } from 'react'
import { formContext } from '../../../context/formContext'
import { ImageBox } from '../PhotoSection/ImageBox'
import './style.css'
import { formatDate, formateValidatedDate, parseFromMysqlDateToJSDate } from '../../../services/dateHandless'

export const ResumeSection = () => {
    const { handleForm, bases64, state } = useContext(formContext)
    const {
        conc_nome,
        rod_desc,
        rod_sent_desc,
        comp_descricao,
        rod_km_fim,
        rod_km_fim_mt,
        rod_km_ini,
        rod_km_ini_mt,
        serv_desc,
        serv_cat_desc,
        serv_sub_cat_desc,
        serv_cat_prazo,
        pvs_cod,
        pvs_prazo_fim
    } = state
    useEffect(() => {
        const fixedServCatPrazWhenExistPvsCod = () => {
            if (pvs_prazo_fim && pvs_cod) {
                let formated_pvs_prazo_fim = formateValidatedDate(parseFromMysqlDateToJSDate(pvs_prazo_fim))
                if (formated_pvs_prazo_fim === "NaN-aN-aN") {
                    formated_pvs_prazo_fim = pvs_prazo_fim.slice(0, 10)
                }
                handleForm({
                    serv_cat_prazo: formated_pvs_prazo_fim
                })
            }
        }
        fixedServCatPrazWhenExistPvsCod()
    }, [pvs_prazo_fim, pvs_cod])

    return (
        <>
            <h5 className='mb-2'>Revisão</h5>
            <ImageBox bases64={bases64} onlyView />
            <p><strong>Concessionária:</strong> {conc_nome} </p>
            <p><strong>Rodoviária:</strong> {rod_desc} </p>
            <p><strong>Sentido:</strong> {rod_sent_desc} </p>
            <p><strong>Complemento:</strong> {comp_descricao} </p>
            <p><strong>Localização inicial:</strong> {rod_km_ini}km {rod_km_ini_mt}m </p>
            <p><strong>Localização final:</strong> {rod_km_fim}km {rod_km_fim_mt}m </p>
            <p><strong>Tipo:</strong> {serv_desc} </p>
            <p><strong>Grupo:</strong> {serv_cat_desc} </p>
            <p><strong>Atividade:</strong> {serv_sub_cat_desc} </p>
            <p><strong>Prazo:</strong> {serv_cat_prazo ? formatDate(serv_cat_prazo) : ''} </p>
            <button className='d-flex d-md-none btn btn-info'>Salvar</button>
        </>
    )
}