import React, { useContext, useEffect, useRef } from 'react'
import { formContext } from '../../../context/formContext'
import { requestContext } from '../../../context/requestContext'
import { handleWaring } from '../../../services/isValid'
import { generateBase64 } from '../../../services/files'
import { find } from '../../../services/arrayHandless'

const FormSpreedsheetConstructionPlan = () => {
	const { state, onChange, bases64, setBases64, handleForm } = useContext(formContext)
	const { type_constructions, setInfoRequest } = useContext(requestContext)
	const {
		pmo_tip_pl_ob,
		tip_ob_desc,
	} = state
	useEffect(() => {
		const searchNameTipObDesc = () => {
			if (Array.isArray(type_constructions?.data) && pmo_tip_pl_ob) {
				const { data } = type_constructions
				const { data: find_ } = find(data, 'tip_ob_cod', parseInt(pmo_tip_pl_ob))
				if (find_) {
					handleForm({
						tip_ob_desc: find_.tip_ob_desc,
					})
				}
			}
		}
		searchNameTipObDesc()
	}, [type_constructions, pmo_tip_pl_ob])
	const input_file_ref = useRef(null)
	const onChangeFile = async ({ target }) => {
		const { files } = target
		if (files.length === 0) {
			return setBases64(null)
		}
		if (files.length > 1) {
			setBases64(null)
			return setInfoRequest(handleWaring('Você só pode selecionar só uma planilha.'))
		} else {
			const { name } = files[0]
			const allowedExtensions = [
				'xls',
				'xlsx',
				'xlsm',
				'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
				'application/vnd.ms-excel.sheet.DisciplinaEnabled.12'
			]
			const fileExtension = name.split('.').pop().toLowerCase()
			if (allowedExtensions.includes(fileExtension)) {
				const base64_ = await generateBase64(files[0])
				if (!base64_) {
					return setInfoRequest(handleWaring(`O arquivo ${name} não é válido`))
				}
				return setBases64({
					bases64: base64_,
					fileName: name,
				})
			}
			setBases64({
				base64: '',
				fileName: ''
			})
			return setInfoRequest(handleWaring(`O arquivo ${name} não é válido`))
		}
	}
	const handleFile = () => {
		if (input_file_ref?.current) {
			input_file_ref.current.click()
		}
	}
	return (
		<div className='row'>
			<div className="form-group col-md-6 col-sm-6">
				<label>Tipo de obra:</label>
				<div className="input-group">
					<select name="pmo_tip_pl_ob" value={pmo_tip_pl_ob}
						className={`form-control ${!pmo_tip_pl_ob && 'is-invalid'}`}
						onChange={onChange}
					>
						{Array.isArray(type_constructions?.data) && type_constructions?.data.map(item => (
							<option key={item.tip_ob_cod} value={item.tip_ob_cod}>
								{item.tip_ob_desc}
							</option>
						))}
					</select>
				</div>
			</div>
			<div className="col-md-6 mt-4 col-sm-12">
				<div className="btn btn-info" onClick={handleFile}>
					{bases64?.fileName ? `Anexado ${bases64?.fileName}` : 'Anexar a Planilha Planilha Plano Obras'} {tip_ob_desc ? `para ${tip_ob_desc}` : ''}
				</div>
			</div>
			<div className='d-none col-12'>
				<input
					name="file_attachments"
					type="file"
					onChange={onChangeFile}
					ref={input_file_ref}
					multiple
				/>
			</div>
		</div>
	)
}
export default FormSpreedsheetConstructionPlan