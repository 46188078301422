import React, { useContext, useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { authContext } from '../../../context/authContext'
import { formContext } from '../../../context/formContext'
import { requestContext } from '../../../context/requestContext'
import returnPosition from '../../../services/geolocation'
import { handleWaring } from '../../../services/isValid'
import { formatDate } from '../../../services/dateHandless'
import { namesScreens, closeModal, openModal } from '../../../constants'
import RenderActive from './renderActive'
import RenderKMLocation from './renderKMLocation'
import ModalShowCheckInspection from '../../modal/modalShowCheckInspection'
const ListOversight = ({
	data,
	selectedItems,
	setSelectedItems
}) => {
	const { handleForm,
		state: {
			type_date,
			serv_cat_prazo_from,
			serv_cat_prazo_to
		} } = useContext(formContext)
	const {
		getAllItemCheckInspection,
		deleteItemOversight,
		setInfoRequest,
		isConection, } =
		useContext(requestContext)
	const [items, setItems] = useState([])
	const [openModalFisc, setOpenModalFisc] = useState(closeModal)
	const navigate = useNavigate()
	useEffect(() => {
		const prepareDataToList = () => {
			if (!data?.data?.length) {
				return setItems([])
			}
			const { data: dataFromApi } = data
			setItems(dataFromApi)
		}
		prepareDataToList()
	}, [data])
	const { user } = useContext(authContext)
	const handleDelete = async (item) => {
		const confirm = window.confirm(
			`Deseja mesmo cancelar a ocorrência para a concessionária ${item.conc_nome}?`
		)
		if (!confirm) {
			return
		}
		if (confirm) {
			const fisc_obs = window.prompt(
				`Explique o porque você está cancelando essa ocorrência para concessionária ${item.conc_nome}`
			)
			if (!fisc_obs) {
				return
			}
			if (fisc_obs.length > 500) {
				return setInfoRequest(
					handleWaring('Você não pode colocar mais do que 500 caracteres')
				)
			}
			if (fisc_obs.length < 5) {
				return setInfoRequest(
					handleWaring('Você precisa colocar mais do que 5 caracteres')
				)
			}
			const postion = isConection
				? isConection
					? await returnPosition()
					: { lat: 0, lng: 0 }
				: { lat: 0, lng: 0 }
			deleteItemOversight({
				id: item.ped_venda_cod,
				fisc_obs,
				pv_servico: item.pv_servico,
				fisc_lat: postion.lat,
				fisc_lng: postion.lng,
				ped_venda_date_from: serv_cat_prazo_from,
				ped_venda_date_to: serv_cat_prazo_to,
				type_date: type_date
			})
		}
	}
	const checked = ({
		ped_venda_cod
	}) => {
		if (!selectedItems.length) {
			return false
		}
		return selectedItems?.includes(ped_venda_cod)
	}
	const onChangeCheckBox = ({ target: { value } }) => {
		if (selectedItems.length) {
			if (selectedItems.find(i => i === Number(value))) {
				return setSelectedItems(selectedItems.filter(i => i !== Number(value)))
			}
			return setSelectedItems([...selectedItems, Number(value)])
		}
		return setSelectedItems([Number(value)])
	}
	const handleOpenOversights = () => {
		if (!selectedItems.length) {
			return setInfoRequest(handleWaring('Você precisa selecionar pelo menos uma NC para poder visualizar o relatório'))
		}
		handleForm({
			ped_venda_cod: selectedItems.join(',')
		})
		return navigate(namesScreens.OVERSIGHTVIEW)
	}
	const handleOpenModalFisc = (item) => {
		handleForm({
			...item,
		})
		getAllItemCheckInspection(item.ped_venda_cod)
		setOpenModalFisc(openModal)
	}
	const handleCloseModal = () => {
		handleForm({
			conc_nome: '',
			lot_conc_desc: '',
			ped_venda_cod: '',
			ped_venda_date: '',
			ped_venda_status: '',
			ped_venda_concessionaria: '',
			ped_venda_responsavel: '',
			ped_venda_rodovia: '',
			last_modified_ped_venda: '',
			st_desc: '',
			st_cor_valor: '',
			name: '',
			rod_desc: '',
			rod_km: '',
			pvs_prazo_fim: '',
			tip_ped_venda_desc: '',
			sisf_sinc_id: '',
			gp_desc: '',
			sinc_st_desc: '',
			serv_desc: '',
			serv_cat_desc: '',
			serv_sub_cat_desc: '',
			pvs_obs: '',
			pv_loc_pedidovenda: '',
			pv_loc_sentido: '',
			pv_loc_km_ini: '',
			pv_loc_km_init_mts: '',
			pv_loc_km_fim: '',
			pv_loc_km_fim_mts: '',
			pv_loc_obs: '',
			pv_loc_lat: '',
			pv_loc_lng: '',
			rod_sent_desc: '',
		})
		setOpenModalFisc(closeModal)
	}
	if (!items.length) {
		return (
			<div className='col-12'>
				Não há itens para serem exibidos
			</div>
		)
	}
	return (
		<div className='col-12'>
			{items.map((item) => (
				<div className='p-3'>
					<div className='row'>
						<div className='col-md-10 col-sm-12'>
							<div className='row'>
								<div className='col-md-4 col-sm-6 mb-3'>
									<span className='text-secondary'>Não Conformidade n°: </span> {item?.sisf_sinc_id || item.ped_venda_cod}
								</div>
								<div className='col-md-4 col-sm-12 mb-3'>
									{item.conc_nome} <span className='text-secondary'>Lote</span> {item.lot_conc_desc}
								</div>
								<div className='col-md-2 col-sm-6 mb-3'>
									<span className='text-secondary mr-2'>
										Abertura:
									</span>
									{formatDate(item.ped_venda_date)}
								</div>
								<div className='col-md-2 col-sm-6 mb-3 mb-3'>
									<span className='text-secondary mr-2'>
										Prazo:
									</span>
									{formatDate(item.pvs_prazo_fim)}
								</div>
								{item.cont_ncontrato && (
									<div className='col-md-4 col-sm-6 mb-3'>
										<div className='text-secondary mr-2'>
											N° do Contrato
										</div>
										<span className='fw-bold mr-1'>
											{item.lot_conc_desc || item.cont_ncontrato}
										</span>
									</div>
								)}
							</div>
						</div>
						<div className='col-md-2 col-sm-12 mb-3 mt-3'>
							<div className='row'>
								<div className='col-6' onClick={() => onChangeCheckBox({
									target: { value: item.ped_venda_cod }
								})} style={{
									cursor: 'pointer'
								}}>
									<div className='form-check'>
										<input
											className='form-check-input'
											type='checkbox'
											value={item.ped_venda_cod}
											id='flexCheckChecked'
											checked={checked({ ped_venda_cod: item.ped_venda_cod })}
											onChange={onChangeCheckBox} />
										<label>
											<i className='fa fa-eye bg-info p-1 rounded'></i> &nbsp;
											{item?.st_desc === "Vencido" &&<i className='fa fa-bell bg-warning p-1 rounded'></i>}
										</label>
									</div>
								</div>
								<div className='col-6'>
									{user
										? user.set_nivel === 0 &&
										(item.ped_venda_status !== 3 ||
											item.ped_venda_status !== 4) && (
											<button
												className='btn btn-secondary btn-sm'
												onClick={() => handleDelete(item)}
											>
												<i className='fa fa-trash'></i>
											</button>
										)
										: null}
								</div>
							</div>
						</div>
					</div>
					<div className='row'>
						<RenderActive item={item} />
						<div className='col-md-4 col-sm-12 mb-3'>
							<span className='text-secondary'>Responsável:</span> {item.name}
						</div>
						<div className='col-md-4 col-sm-12'>
							<Link className='text-secondary' onClick={() => handleOpenModalFisc(item)} to={'#'}>
								Ver Fiscalizações
							</Link>
						</div>
						<div className='col-md-3 col-sm-12 mb-3'>
							{item.st_desc && (
								<div className='col-md-1 col-sm-6 mr-2'>
									<span
										className='badge text-light text-center p-2'
										style={{ backgroundColor: item.st_cor_valor }}
									>
										{item.st_desc}
									</span>
								</div>
							)}
						</div>
						<div className='col-md-3 col-sm-12 mb-3'>
							{item.tip_ped_venda_desc && (
								<div className='col-md-1 col-sm-6 mr-2'>
									<span
										className='badge text-light bg-secondary text-center p-2'
									>
										{item.tip_ped_venda_desc}
									</span>
								</div>
							)}
						</div>
						<div className='col-md-3 col-sm-12 mb-3'>
							{item.sinc_st_desc && (
								<div className='col-md-1 col-sm-6 mr-2'>
									<span className='badge text-light bg-dark text-center p-2'											>
										{item.sinc_st_desc} no SISF
									</span>
								</div>
							)}
						</div>
						{item.gp_desc && (
							<div className='col-md-3 col-sm-12 mb-3'>
								<span className='badge text-light bg-info text-center p-2'											>
									Grupo {item.gp_desc}
								</span>
							</div>
						)}
						{item.rod_desc && (
							<div className='col-md-6 col-sm-12 mb-3'>
								<span className='text-secondary'>Rodovia:</span> {item?.rod_km} - {item.rod_desc}
							</div>
						)}
						{item.rod_desc && (
							<div className='col-md-2 col-sm-12 mb-3'>
								<span className='text-secondary'>Sentido:</span> {item.rod_sent_desc}
							</div>
						)}
						{(
							item.pv_loc_km_ini !== null &&
							item.pv_loc_km_init_mts !== null &&
							item.pv_loc_km_fim !== null &&
							item.pv_loc_km_fim_mts !== null) ? (
							<RenderKMLocation item={item} />
						) : null}
					</div>
					<hr />
				</div>
			))}
			{selectedItems.length ? (
				<div className="floating-button">
					<button type="button" className="btn btn-info"
						onClick={handleOpenOversights}>
						<i className="fa fa-chevron-right"></i> <span className="badge badge-light">
							{selectedItems.length}
						</span>
					</button>
				</div>
			) : <div className="floating-button">
				<button disabled type="button" className="btn btn-info">
					<i className="fa fa-chevron-right"></i>
				</button>
			</div>}
			<ModalShowCheckInspection handleCloseModal={handleCloseModal} openModalFisc={openModalFisc} />
		</div>
	)
}
export default ListOversight
