import React from 'react'
import { useContext, useEffect, useState } from 'react'
import { formContext } from '../../../context/formContext'

const FormConfigurationsParematers = () => {
	const { state, onChange } = useContext(formContext)
	const {
		config_para_desc,
		config_para_value,
		sett_desc,
	} = state
	const [formated_config_para_desc, setFormatedConfigParaDesc] = useState('')
	useEffect(() => {
		const replaceStringConfigParaDesc = () => {
			if (!config_para_desc) {
				setFormatedConfigParaDesc('')
			}
			const novaString = config_para_desc.replace(/ /g, '_')
			setFormatedConfigParaDesc(novaString)
		}
		replaceStringConfigParaDesc()
	}, [config_para_desc])
	return (
		<div className="row">
			<div className="form-group col-md-6 col-sm-12">
				<label>Descrição para essa configuração:</label>
				<div className="input-group">
					<input type="text"
						className="form-control"
						onChange={onChange}
						name='sett_desc' value={sett_desc}
						placeholder='Descreva qual é objetivo dessa descrição' />
				</div>
			</div>
			<div className="form-group col-md-6 col-sm-12">
				<label>Nome da chave de configuração:</label>
				<div className="input-group">
					<input type="text"
						className="form-control"
						onChange={onChange}
						name='config_para_desc' value={formated_config_para_desc}
						placeholder='Descreva o nome da chave que deve ser usado' />
				</div>
			</div>
			<div className="form-group col-md-6 col-sm-12">
				<label>Valor da chave de configuração:</label>
				<div className="input-group">
					<input type="text"
						className="form-control"
						onChange={onChange}
						name='config_para_value' value={config_para_value}
						placeholder='Descreva o valor da chave que deve ser usado' />
				</div>
			</div>
		</div>
	)
}
export default FormConfigurationsParematers