import React, { useContext, useEffect } from 'react'
import { formContext } from '../../../context/formContext'
import { requestContext } from '../../../context/requestContext'
const SelectSyncStatus = ({ hidden }) => {
	const { onChangeSelectInput } = useContext(formContext)
	const { syncStatus, getItemAllSyncStatus } = useContext(requestContext)
	useEffect(() => {
		getItemAllSyncStatus()
	}, [])
	return (
		<div className="form-group">
			{!hidden &&
				<label>Situação de sincronização</label>
			}
			<select onChange={onChangeSelectInput}
				className="form-control">
				<option value={JSON.stringify({
					sinc_st_cod: 0,
					sinc_st_desc: 'Todos'
				})}>Todos as Situações</option>
				{Array.isArray(syncStatus?.data) && syncStatus.data.map(item => (
					<option key={item.st_cod} value={JSON.stringify({
						...item,
					})}>{item.sinc_st_desc}</option>
				))}
			</select>
		</div>
	)
}
export default SelectSyncStatus