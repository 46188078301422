import React, { useContext } from 'react'
import { formContext } from '../../../context/formContext'
const FormSyncStatus = () => {
	const { state, onChange } = useContext(formContext)
	const {
		sinc_st_desc
	} = state
	return (
		<div className="row">
			<div className="form-group col-md-6 col-sm-12">
				<label>Descrição:</label>
				<div className="input-group">
					<input type="text"
						onChange={onChange}
						name='sinc_st_desc' value={sinc_st_desc}
						className={`form-control ${!sinc_st_desc && 'is-invalid'}`}
						placeholder='Descrição do status' />
				</div>
			</div>
		</div>
	)
}
export default FormSyncStatus