import React from "react"
import { useContext } from "react"
import { formContext } from "../../../context/formContext"

const FormTypeSubServiceCategory = () => {
    const { onChange, state } = useContext(formContext)
    const {
        tip_serv_sub_cat_desc,
        tip_serv_sub_cat_disp,
    } = state
    return (
        <div className="row">
            <div className="form-group col-md-6 col-sm-6">
                <label>Descrição:</label>
                <div className="input-group">
                    <input type="text"
                        onChange={onChange}
                        name='tip_serv_sub_cat_desc' value={tip_serv_sub_cat_desc}
                        className={`form-control ${!tip_serv_sub_cat_desc && 'is-invalid'}`}
                        placeholder='Descrição da Categoria' />
                </div>
            </div>
            <div className="form-group col-md-6 col-sm-6">
                <label>Disponível:</label>
                <div className="input-group">
                    <select
                        onChange={onChange}
                        name='tip_serv_sub_cat_disp' value={tip_serv_sub_cat_disp}
                        className={`form-control ${!tip_serv_sub_cat_disp && 'is-invalid'}`}
                    >
                        <option>Selecionar</option>
                        <option value={1}>Ativo</option>
                        <option value={0}>Inativo</option>
                    </select>
                </div>
            </div>
        </div>
    )
}
export default FormTypeSubServiceCategory