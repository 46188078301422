import React, { useContext, useRef } from 'react'
import { changeUnit, formatCurrency } from '../../../services/stringHandless'
import { formContext } from '../../../context/formContext'
import { requestContext } from '../../../context/requestContext'
import { handleWaring } from '../../../services/isValid'
import { loadConfigOutros } from '../../../services/constructionPlan'
import { isBase64 } from '../../../services/files'
import { authContext } from '../../../context/authContext'
import Spinner from '../../spinner'
const ServiceAdvancement = ({ closeModal }) => {
	const input = useRef(null)
	const { user } = useContext(authContext)
	const { state, onChange, setBases64 } = useContext(formContext)
	const { setInfoRequest, postItemProgressConstructionPlan, getItemCalcMemorySpreadSheet, postItemAttachmentsConstructionPlan } = useContext(requestContext)
	const {
		serv_sub_cat_desc,
		prog_serv_qtd_acul,
		current_prog_serv_qtd_acul,
		ob_serv_med_desc,
		pvs_qtd,
		pl_ob_cod,
		pvs_pedidovenda,
		prog_serv_cod,
		ane_pl_ob_path,
		ane_pl_ob_pvs_cod
	} = state
	const handleSaveProgress = async () => {
		if ((Number(current_prog_serv_qtd_acul) <= Number(prog_serv_qtd_acul)) && user?.set_nivel !== 0) {
			return setInfoRequest(handleWaring('Valor do executado acumulado igual ou menor do que já existente'))
		}
		if ((Number(current_prog_serv_qtd_acul) > Number(pvs_qtd)) && user?.set_nivel !== 0) {
			return setInfoRequest(handleWaring('Valor do executado acumulado maior do que já existente'))
		}
		const response = await postItemProgressConstructionPlan({
			id: prog_serv_cod,
			pvs_pedidovenda,
			prog_serv_qtd_acul: current_prog_serv_qtd_acul,
		})
		if (response?.ok && closeModal?.current?.click) {
			closeModal?.current?.click()
		}
		if (response?.ok && isBase64(ane_pl_ob_path) && ane_pl_ob_pvs_cod) {
			postItemAttachmentsConstructionPlan({
				pl_ob_cod,
				ob_attachments: [{
					ane_pl_ob_tipo: await loadConfigOutros(),
					ane_pl_ob_path,
					ane_pl_ob_obs: `Memória de calculo do serviço ${serv_sub_cat_desc}`,
					ane_pl_ob_pvs_cod,
				}]
			})
		}
	}
	const handleDowloadCalcMemorySpreedSheet = async () => {
		if (!prog_serv_cod) {
			return setInfoRequest(handleWaring(`Não foi possível obter o código do serviço ${serv_sub_cat_desc}`))
		}
		await getItemCalcMemorySpreadSheet(prog_serv_cod)
	}
	const importMemoryCalc = () => {
		if (input?.current?.click) {
			setBases64(null)
			// input?.current?.value = ''
			input?.current?.click()
		}
	}
	const onChangeFile = ({ target }) => {
		const { files } = target
		if (files?.length > 1) {
			return setInfoRequest(handleWaring('Você só pode enviar um arquivo por vez'))
		}
		setBases64(files)
	}
	return (
		<div className='row'>
			<div className='col-12'>
				<h5>Dar avanço no serviço {serv_sub_cat_desc}</h5>
			</div>
			<div className='form-group col-12'>
				<label>Escolha um valor entre {formatCurrency(prog_serv_qtd_acul, false)}{changeUnit(ob_serv_med_desc)} e {formatCurrency(pvs_qtd, false)}{changeUnit(ob_serv_med_desc)}:</label>
				<input
					className='form-control'
					placeholder={prog_serv_qtd_acul}
					type='number'
					name='current_prog_serv_qtd_acul'
					value={current_prog_serv_qtd_acul}
					min={parseFloat(prog_serv_qtd_acul) + 1}
					max={parseFloat(pvs_qtd)}
					onChange={onChange}
				/>
			</div>
			<div
				className='btn btn-info btn-sm mr-2'
				onClick={handleSaveProgress}
			>
				<i className='fa fa-save'></i> Salvar <Spinner direction={'center'} label={' '}/>
			</div>
			<div
				className='btn btn-dark btn-sm mr-2'
				onClick={handleDowloadCalcMemorySpreedSheet}
			>
				<i className='fa fa-download'></i> Memória de Cálculo
			</div>
			{pl_ob_cod && (
				<div className='btn btn-secondary btn-sm mr-2' onClick={importMemoryCalc}>
					<i className='fa fa-table'></i> Importar Memória de Cálculo
				</div>
			)}
			<input ref={input} className='d-none' type='file' onChange={onChangeFile} />
		</div>
	)
}
export default ServiceAdvancement