import React, { useContext, useEffect, useState } from 'react'
import { formatDate } from '../../../services/dateHandless'
import { filterAndRemove } from '../../../services/arrayHandless'
import { formContext } from '../../../context/formContext'
import { requestContext } from '../../../context/requestContext'
import { handleSuccess } from '../../../services/isValid'
const ListTimeLine = ({ items, setItems, editItem }) => {
	const { state: { ped_venda_cod }, handleForm } = useContext(formContext)
	const { setInfoRequest } = useContext(requestContext)
	const [itemIsEditing, setItemIsEditing] = useState(null)

	useEffect(() => {
		const cancelSelectedItemToEdit = () => {
			if (!editItem) {
				setItemIsEditing(null)
			}
		}
		cancelSelectedItemToEdit()
	}, [editItem])
	const handleRemove = (item) => {
		const confirm = window.confirm(`Deseja mesmo remover a atividade ${item.pvs_obs} do cronograma?`)
		if (item?.pvs_cod && confirm) {
			return
		}
		if (item.pvs_servico_sub_cat && confirm) {
			const { data } = filterAndRemove(items, 'pvs_servico_sub_cat', item.pvs_servico_sub_cat)
			setItems(data)
		}
	}
	const handleEdit = (item) => {
		setItemIsEditing(item.pvs_cod)
		const { pedido_venda_local, pvs_obs: serv_sub_cat_desc,
			pvs_servico: serv_cod,
			pvs_servico_cat: serv_cat_cod,
			pvs_servico_sub_cat: serv_sub_cat_cod,
		} = item
		const {
			pv_loc_sentido,
			pv_loc_km_ini: rod_km_ini,
			pv_loc_km_init_mts: rod_km_ini_mt,
			pv_loc_km_fim: rod_km_fim,
			pv_loc_km_fim_mts: rod_km_fim_mt,
			rod_desc,
		} = pedido_venda_local
		handleForm({
			rod_desc,
			rod_km_ini,
			rod_km_ini_mt,
			rod_km_fim,
			rod_km_fim_mt,
			rod_sent_cod: pv_loc_sentido,
			...pedido_venda_local,
			serv_sub_cat_desc,
			serv_cod,
			serv_cat_cod,
			serv_sub_cat_cod,
			...item,
		})
		return setInfoRequest(handleSuccess(`Atividade ${serv_sub_cat_desc} colocada para edição`))
	}
	return (
		<div className="col-12">
			{Array.isArray(items) && items.map((item, i) => (
				<div key={item.serv_sub_cat_cod} className={itemIsEditing === item?.pvs_cod ? 'row bg-secondary' : 'row'}>
					{i > 0 && (
						<div className="col-12">
							<hr className="w-100" />
						</div>
					)}
					{i === 0 && (
						<div className="col-12">
							<h5>Atividades inclusas no cronograma do mês de {item.month}</h5>
						</div>
					)}
					<div className="col-md-6 col-sm-12 mb-3">
						<span className="text-secondary">Concessionária: </span>
						{item.conc_nome}
					</div>
					<div className="col-md-3 col-sm-12 mb-3">
						<span className="text-secondary">Inicio: </span>
						{formatDate(`${item.pvs_prazo_ini}`)}{' '}
					</div>
					<div className="col-md-3 col-sm-12">
						<span className="text-secondary">Termino: </span>
						{formatDate(`${item.pvs_prazo_fim}`)}
					</div>
					{item.serv_desc && item.serv_cat_desc ? (
						<div className="col-md-10 col-sm-8 mb-3">
							<div className="row">
								<div className="col-md-4 col-sm-12 mb-3">
									<span className="text-secondary">Atividade: </span>
									{parseFloat(item.pvs_qtd).toFixed(2)} x {item.pvs_obs}
								</div>
								<div className="col-md-4 col-sm-12 mb-3">
									<span className="text-secondary">Tipo atividade: </span>
									{item.serv_desc}
								</div>
								<div className="col-md-4 col-sm-12 mb-3">
									<span className="text-secondary">Grupo atividade: </span>
									{item.serv_cat_desc}
								</div>
							</div>
						</div>
					) : (
						<div className="col-md-10 col-sm-8 mb-3">
							<div className="col-md-4 col-sm-12 mb-3">
								<span className="text-secondary">Atividade: </span>
								{parseFloat(item.pvs_qtd).toFixed(2)} x {item.pvs_obs}
							</div>
						</div>
					)}
					<div className="col-md-2 col-sm-4 mb-3">
						{ped_venda_cod && item?.pvs_cod && (
							<button onClick={() => handleEdit(item)}
								disabled={itemIsEditing === item?.pvs_cod}
								className="mr-2 btn btn-info btn-sm">
								<i className="fa fa-edit"></i>
							</button>
						)}
						<button disabled={itemIsEditing === item?.pvs_cod}
							onClick={() => handleRemove(item)} className="btn btn-secondary btn-sm">
							<i className="fa fa-trash"></i>
						</button>
					</div>
					<div className="col-md-3 col-sm-12 mb-3">
						<span className="text-secondary">Rodovia: </span>
						{item.rod_desc}
					</div>
					<div className="col-sm-12 col-md-3">
						<span className="text-secondary">Km Inicial: </span>
						{item.pedido_venda_local.pv_loc_km_ini}, {item.pedido_venda_local.pv_loc_km_init_mts}
					</div>
					<div className="col-sm-12 col-md-3">
						<span className="text-secondary">Km Final: </span>
						{item.pedido_venda_local.pv_loc_km_fim}, {item.pedido_venda_local.pv_loc_km_fim_mts}
					</div>
					{item.pedido_venda_local?.rod_sent_desc && (
						<div className="col-sm-12 col-md-3">
							<span className="text-secondary">Sentido: </span>
							{item.pedido_venda_local.rod_sent_desc}
						</div>
					)}
					<div className="col-12 mb-3">
						<span className="text-secondary">Observações: </span>
						{item.pedido_venda_local.pv_loc_obs}
					</div>
					<hr className="w-100" />
				</div>
			))}
		</div>
	)
}
export default ListTimeLine