import React from 'react'
import { formatDate } from '../../../services/dateHandless'
const ListServiceOversightReport = ({ data, generateReport }) => {
	return data.map((item) => (
		<div className="row" key={item.pvs_cod}>
			{item?.serv_desc && (
				<div className="col-sm-6 col-md-4 mb-2">
					<span className="text-secondary mr-2">
						Tipo atividade:
					</span>
					{item?.serv_desc}
				</div>
			)}
			{item?.serv_cat_desc && (
				<div className="col-sm-6 col-md-4 mb-2">
					<span className="text-secondary mr-2">
						Grupo da atividade:
					</span>
					{item?.serv_cat_desc}
				</div>
			)}
			{item.serv_sub_cat_desc && (
				<div className="col-sm-6 col-md-4 mb-2">
					<span className="text-secondary mr-2">
						Atividade:
					</span>
					{item?.serv_sub_cat_desc}
				</div>
			)}
			{item?.pvs_obs && !item.serv_sub_cat_desc && (
				<div className="col-sm-6 col-md-4 mb-2">
					<span className="text-secondary mr-2">
						Atividade:
					</span>
					{item?.pvs_obs}
				</div>
			)}
			<div className="col-md-3 col-sm-6">
				<span className="text-secondary mr-2">Status:</span>
				{generateReport ? (
					<span
					>
						{item.st_desc}
					</span>

				) : (
					<span
						className="text-light badge"
						style={{ backgroundColor: item.st_cor_valor }}
					>
						{item.st_desc}
					</span>
				)}
			</div>
			<div className="col-md-3 col-sm-12">
				<div className="form-group">
					<div className="input-group">
						<div className="input-group-prepend">
							<span className="text-secondary mr-2">Prazo:</span>
						</div>
						<div className="m-0">
							{item.pvs_prazo_fim ?
								formatDate(item.pvs_prazo_fim.replace('000Z', '')) : ''}
						</div>
					</div>
				</div>
			</div>
			<div className="col-md-3 col-sm-6 mb-3">
				<span className="text-secondary p-0 m-0">KM Inicial:</span> {item.pv_loc_km_ini},{item.pv_loc_km_init_mts} m

			</div>
			<div className="col-md-3 col-sm-6 mb-3">
				<span className="text-secondary p-0 m-0">KM Final:</span> {item.pv_loc_km_fim},{item.pv_loc_km_fim_mts} m

			</div>
			<div className="col-md-3 mb-3">
				<span className="text-secondary p-0 m-0">Sentido:</span> {item.rod_sent_desc}
			</div>
			{item.pv_loc_obs ? (
				<div className="col-12 mb-3">
					<span className="text-secondary p-0 m-0">Observações:</span> {item.pv_loc_obs}
				</div>
			) : null}
		</div >
	))
}
export default ListServiceOversightReport
