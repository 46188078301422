import React, { useContext, useEffect } from "react";
import { formContext } from "../../../context/formContext";
import { requestContext } from "../../../context/requestContext";
const FormServiceConstruction = () => {
    const { getAllItemTypeConstructions, getItemMeasureService, type_constructions, deadline } = useContext(requestContext)
    const { state, onChange } = useContext(formContext)
    const {
        serv_desc,
        serv_sub_cat_desc,
        tip_ob_cod,
        ob_serv_med_cod
    } = state
    useEffect(() => {
        getAllItemTypeConstructions()
        getItemMeasureService()
    }, [])
    return (
        <div className="row">
            <div className="form-group col-md-6 col-sm-6">
                <label>Discplina Descrição:</label>
                <div className="input-group">
                    <input type="text"
                        onChange={onChange}
                        name='serv_desc' value={serv_desc}
                        className={`form-control ${!serv_desc && 'is-invalid'}`}
                        placeholder='Descrição da Displina' />
                </div>
            </div>
            <div className="form-group col-md-6 col-sm-6">
                <label>Serviço Descrição:</label>
                <div className="input-group">
                    <input type="text"
                        onChange={onChange}
                        name='serv_sub_cat_desc' value={serv_sub_cat_desc}
                        className={`form-control ${!serv_sub_cat_desc && 'is-invalid'}`}
                        placeholder='Descrição da Atividade' />
                </div>
            </div>
            <div className="form-group col-md-6 col-sm-6">
                <label>Tipo de Obra:</label>
                <div className="input-group">
                    <select name="tip_ob_cod" value={tip_ob_cod}
                        className={`form-control ${!tip_ob_cod && 'is-invalid'}`}
                        onChange={onChange}
                    >
                        {Array.isArray(type_constructions?.data) && type_constructions.data.map(item => (
                            <option key={item.tip_ob_cod} value={item.tip_ob_cod}>
                                {item.tip_ob_desc}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
            <div className="form-group col-md-6 col-sm-6">
                <label>Unidade de medida:</label>
                <div className="input-group">
                    <select name="ob_serv_med_cod" value={ob_serv_med_cod}
                        className={`form-control ${!ob_serv_med_cod && 'is-invalid'}`}
                        onChange={onChange}
                    >
                        {Array.isArray(deadline?.data) && deadline.data.map(item => (
                            <option key={item.ob_serv_med_cod} value={item.ob_serv_med_cod}>{item.ob_serv_med_desc}</option>
                        ))}
                    </select>
                </div>
            </div>
        </div>
    )
}
export default FormServiceConstruction