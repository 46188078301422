import React, { useContext } from 'react'
import ContentHeader from '../../../components/contentHeader'
import { requestContext } from '../../../context/requestContext'
import { formContext } from '../../../context/formContext'
import { handleWaring, isValid } from '../../../services/isValid'
import { useNavigate } from 'react-router-dom'
import FormTypeSubServiceCategory from '../../../components/form/typeSubServiceCategory'
const NewTypeServiceSubCategory = () => {
	const { state, handleForm } = useContext(formContext)
	const { setInfoRequest, postItemTypeSubServiceCategory } = useContext(requestContext)

	const navigate = useNavigate()
	const {
		tip_serv_sub_cat_cod,
		tip_serv_sub_cat_desc,
		tip_serv_sub_cat_disp,
	} = state
	const handleCancel = () => {
		handleForm({
			tip_serv_sub_cat_cod: '',
			tip_serv_sub_cat_desc: '',
			tip_serv_sub_cat_disp: '',
		})
		return navigate('/type_service')
	}
	const onSubmit = (e) => {
		e.preventDefault()
		window.scrollTo(0, 0)
		const item = {
			tip_serv_sub_cat_desc,
			tip_serv_sub_cat_disp,
		}
		const validate = isValid(item)
		if (!validate) {
			return setInfoRequest(handleWaring())
		}
		postItemTypeSubServiceCategory({
			...item,
			id: tip_serv_sub_cat_cod
		})
		handleCancel()
	}
	return (
		<>
			<ContentHeader title={'Novo tipo de serviço'} />
			<div className='content'>
				<form onSubmit={onSubmit}>
					<div className="card card-info">
						<div className="card-header">
							<h3 className="card-title">Cadastro novo tipo de serviço</h3>
							<div className="card-tools">
								<button
									type="button"
									className="btn btn-tool"
									data-card-widget="collapse"
								>
									<i className="fas fa-minus"></i>
								</button>
								<button
									type="button"
									className="btn btn-tool"
									data-toggle="modal"
									data-target="#concessionaria"
								>
									<i className="fa fa-question-circle"></i>
								</button>
							</div>
						</div>
						<div className="card-body">
							<FormTypeSubServiceCategory />
						</div>
					</div>
					<div className="card">
						<div className="card-body">
							<button type="submit" className="btn btn-info mr-3">
                                Salvar <i className="fa fa-save"></i>
							</button>
							<div className="btn btn-secondary" onClick={() => handleCancel()}>
                                Cancelar <i className="fa fa-times-circle"></i>
							</div>
						</div>
					</div>
				</form>
			</div>
		</>
	)
}
export default NewTypeServiceSubCategory