import { useContext, useEffect, useState } from "react"
import { requestContext } from "../../../context/requestContext"
import Selectservice from "../../form/selectservice"
import SelectServiceCat from "../../form/selectServiceCat"
import SelectServiceSubCat from "../../form/selectServiceSubCat"
import { formContext } from "../../../context/formContext"

export const ServiceSection = () => {
    const {
        getAllItemService,
        getAllItemCategoryService,
        getAllItemServiceSubCat } = useContext(requestContext)
    const { state, onChange, handleForm } =
        useContext(formContext)
    const {
        serv_cod,
        serv_cat_cod,
        serv_cat_prazo,
        praz_dias,
    } = state

    const [minSelectedDate, setMinSelectedDate] = useState(null)

    useEffect(() => {
        getAllItemService()
    }, [])
    useEffect(() => {
        const loadServiceCat = () => {
            if (serv_cod) {
                getAllItemCategoryService(serv_cod)
            }
        }
        loadServiceCat()
    }, [serv_cod])
    useEffect(() => {

        const loadServiceSubCat = () => {
            if (serv_cat_cod) {
                handleForm({
                    serv_sub_cat_serv_cat: serv_cat_cod
                })
                getAllItemServiceSubCat(serv_cat_cod)
            }
        }
        loadServiceSubCat()
    }, [serv_cat_cod])
    return (
        <>
            <h5 className="mb-2">Passo 4/4</h5>
            <h4 className="mb-2 mt-2">Atividades</h4>
            <Selectservice label={'Tipo Atividade:'} />
            <SelectServiceCat newlayout={true} />
            <SelectServiceSubCat newlayout={true} />
            <label>Prazo:</label>
            <input
                type="date"
                onChange={onChange}
                min={minSelectedDate}
                name="serv_cat_prazo"
                value={serv_cat_prazo}
                className={'form-control'}
                placeholder="Selecionar Serviço"
                disabled={praz_dias}
            />
        </>
    )
}