import React, { useContext, useEffect } from 'react'
import { requestContext } from '../../context/requestContext'
import { toast } from 'react-toastify'
import { recoverCache, storageCache } from '../../services/cacheManager'
import { namesCache } from '../../constants'

const Toasy = () => {
	const { infoRequest, setInfoRequest } = useContext(requestContext)
	useEffect(() => {
		setInfoRequest({
			status: '',
			message: '',
			color: ''
		})
	}, [])
	useEffect(() => {
		const handleClose = () => {
			setInfoRequest({
				status: '',
				message: '',
				color: ''
			})
			storageCache(namesCache.LASS_MESSAGE)
		}
		try {
			const {
				message, color
			} = infoRequest
			if (!message || !color) {
				return
			}
			const lass_message = recoverCache(namesCache.LASS_MESSAGE)
			if (lass_message?.message === message && lass_message?.color === color) {
				return
			}
			storageCache(namesCache.LASS_MESSAGE, {
				message,
				color
			})
			switch (color) {
			case 'success':
				toast.success(message)
				break
			case 'warning':
				toast.warn(message)
				break
			case 'danger':
				toast.error(message)
				break
			default:
				toast.success(message)
				break
			}
		} catch (error) {
			handleClose()
		}
	}, [infoRequest, setInfoRequest])
	return (
		<></>
	)
}
export default Toasy