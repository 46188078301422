import React, { useContext } from 'react'
import { formContext } from '../../../context/formContext'
import { formatDate } from '../../../services/dateHandless'
import { requestContext } from '../../../context/requestContext'
import { useNavigate } from 'react-router-dom'
import { filterAndRemove, find } from '../../../services/arrayHandless'
import { namesScreens } from '../../../constants'
const ListUsers = ({ data, resetPassword, setResetPassWord, isListResetPassword }) => {
	const { handleForm, set_cod } = useContext(formContext)
	const { deleteItemUsers } = useContext(requestContext)
	const navigate = useNavigate()
	const handleEdit = (item) => {
		try {
			handleForm({
				...item,
				email: item.usu_email,
				set_nivel: item?.set_nivel
			})
			navigate(`${namesScreens.REGISTERS}${namesScreens.USERS}`, {
				state: {
					updateUser: true
				}
			})
		} catch (error) {
			console.log(error)
		}
	}
	const handleDelete = (item) => {
		const confirm = window.confirm(`Você deseja mesmo remover o usuário ${item.name}`)
		if (confirm) {
			deleteItemUsers(item.id)
		}
	}
	if (!Array.isArray(data)) {
		return <div></div>
	}

	const handleResetPassword = (item) => {
		const {
			id, usu_email
		} = item
		if (Array.isArray(resetPassword)) {
			const findItem = find(resetPassword, 'id', id)
			if (findItem?.data) {
				const { data: filtered } = filterAndRemove(resetPassword, 'id', id)
				return setResetPassWord(filtered)
			}
			return setResetPassWord([
				...resetPassword, { id, usu_email }
			])
		}
		return setResetPassWord([{ id, usu_email }])
	}
	const VerifyIsSelected = ({ item }) => {
		if (!Array.isArray(resetPassword)) {
			return (
				<button
					className="btn btn-info btn-sm mr-2"
					onClick={() => handleResetPassword(item)}
				>
					Selecionar
				</button>
			)
		}
		const { data: findItem } = find(resetPassword, 'id', item.id)
		if (!findItem) {
			return (
				<button
					className="btn btn-info btn-sm mr-2"
					onClick={() => handleResetPassword(item)}
				>
					Selecionar
				</button>
			)
		}
		return (
			<button
				className="btn btn-info btn-sm mr-2"
				onClick={() => handleResetPassword(item)}
			>
				Selecionado <i className="fa fa-check-square"></i>
			</button>
		)
	}
	if (!set_cod) {
		<div className='p-3'>
			<p>Selecione um setor para que a lista de usuários apareça aqui</p>
		</div>
	}
	return data.map((item) => (
		<div className="row p-3" key={item.id}>
			<div className="col-md-10 col-sm-8">
				<div className="row">
					<div className="col-md-4 col-sm-12">{item.name}</div>
					<div className="col-md-8 col-sm-12">
						<span className="text-secondary mr-2">Email:</span>
						{item.usu_email}</div>
				</div>
			</div>
			{isListResetPassword ? (
				<VerifyIsSelected item={item} />
			) : (
				<div className="col-md-2 col-sm-4">
					<button
						className="btn btn-info btn-sm mr-2"
						onClick={() => handleEdit(item)}
					>
						<i className="ml-1 fa fa-edit"></i>
					</button>
					<button
						className="btn btn-secondary btn-sm"
						onClick={() => handleDelete(item)}
					>
						<i className="fa fa-trash"></i>
					</button>
				</div>
			)}
			<div className="col-md-4 col-sm-6">
				<span className="text-secondary">Setor: </span> {item.set_desc}
			</div>
			<div className="col-md-4 col-sm-6">
				<span className="text-secondary">Setor ativo: </span> {item.set_disp === 1 ? 'Ativo' : 'Inativo'}
			</div>
			{item?.set_nivel === 3 ? (
				<div className="col-md-4 col-sm-6">
					<span className="text-secondary">Concessionaria: </span> {item.conc_nome}
				</div>
			) : (
				<div className="col-md-4 col-sm-6">
					<span className="text-secondary">Empresa: </span> {item.emp_nome}
				</div>
			)}
			<div className="col-md-4 col-sm-6">
				<span className="text-secondary">Ativo: </span> {item.usu_ativo === 1 ? 'Ativo' : 'Inativo'}
			</div>
			<div className="col-md-4 col-sm-6">
				<span className="text-secondary">Grupo: </span> {item.gp_desc}
			</div>
			<div className="col-md-4 col-sm-6">
				<span className="text-secondary">Criado dia: </span> {formatDate(item.created_at)}
			</div>
			<div className='col-12'>
				<hr />
			</div>
		</div>
	))
}
export default ListUsers