import React, { useContext } from 'react'
import { formContext } from '../../../context/formContext'
import { requestContext } from '../../../context/requestContext'
import { styleButtonPlus } from '../../../css/globlalStyle'
import { handleWaring } from '../../../services/isValid'
import ListItemSubService from './listItem'
const ServiceSubCategory = () => {
	const { state, onChange, handleForm } = useContext(formContext)
	const { setInfoRequest } = useContext(requestContext)
	const {
		serv_desc,
		serv_disp,
		serv_cat_desc,
		serv_cat_disp,
		serv_sub_cat_desc,
		serv_sub_cat_disp,
		pv_servico,
	} = state
	const handleSubService = () => {
		if (!serv_desc || !serv_disp) {
			return setInfoRequest(handleWaring('Você precias preencher os campos do serviço'))
		}
		if (!serv_cat_desc || !serv_cat_disp) {
			return setInfoRequest(handleWaring('Você precisa preencher os campos da categoria'))
		}
		if (!serv_sub_cat_desc || !serv_sub_cat_desc) {
			return setInfoRequest(handleWaring('Você precisa preencher os campos da Atividade'))
		}
		Array.isArray(pv_servico) ? handleForm({
			pv_servico: [...pv_servico, {
				serv_desc,
				serv_disp,
				serv_cat_desc,
				serv_cat_disp,
				serv_sub_cat_desc,
				serv_sub_cat_disp,
			}]
		}) : handleForm({
			pv_servico: [{
				serv_desc,
				serv_disp,
				serv_cat_desc,
				serv_cat_disp,
				serv_sub_cat_desc,
				serv_sub_cat_disp,
			}]
		})
		handleForm({
			serv_sub_cat_desc: '',
			serv_sub_cat_disp: '',
		})
	}
	return (
		<div className='row'>
			<div className="form-group col-md-6 col-sm-6">
				<label>Atividade Descrição:</label>
				<div className="input-group">
					<input type="text"
						onChange={onChange}
						name='serv_sub_cat_desc' value={serv_sub_cat_desc}
						className={`form-control ${!serv_sub_cat_desc && 'is-invalid'}`}
						placeholder='Descrição da Categoria' />
				</div>
			</div>
			<div className="form-group col-md-6 col-sm-6">
				<label>Atividade Disponibilidade:</label>
				<div className="input-group">
					<select
						onChange={onChange}
						name='serv_sub_cat_disp' value={serv_sub_cat_disp}
						className={`form-control ${!serv_sub_cat_disp && 'is-invalid'}`}
					>
						<option>Selecionar</option>
						<option value={1}>Disponível</option>
						<option value={0}>Indisponível</option>
					</select>
					<div className="input-group-prepend">
						<span className="input-group-text bg-info"
							onClick={handleSubService}
							style={styleButtonPlus}>
							<i className="fa fa-plus" aria-hidden="true"></i>
						</span>
					</div>
				</div>
			</div>
			{Array.isArray(pv_servico) &&
                <div className='col-12'>
                	<label>Atividades de Serviços adicionados</label>
                	{pv_servico.map(item => (
                		<ListItemSubService key={item.serv_sub_cat_cod} data={pv_servico} item={item} />
                	))
                	}
                </div>
			}
		</div>
	)
}
export default ServiceSubCategory