import React, { useContext } from 'react'
import { formContext } from '../../../context/formContext'
import { requestContext } from '../../../context/requestContext'
import { authContext } from '../../../context/authContext'
const SearchComplements = ({ handleNew }) => {
    const { state, onChange } = useContext(formContext)
    const { getItemWhereComplements, getItemComplements } = useContext(requestContext)
    const { user } = useContext(authContext)
    const {
        comp_descricao
    } = state
    const handleSearch = () => {
        if (!comp_descricao) {
            return getItemComplements()
        }
        getItemWhereComplements(comp_descricao)
    }
    return (
        <div className='input-group input-group-sm'>
            <div className='input-group-append'>
                <button className='btn btn-tool btn-sm' onClick={() => getItemComplements()}>
                    Atualizar lista
                </button>
            </div>
            {user?.set_nivel <= 1 && (
                <div className='input-group-append'>
                    <button className='btn btn-tool btn-sm' onClick={handleNew}>
                        <i className='fas fa-plus'></i> Novo
                    </button>
                </div>
            )}
            <input type='text' className='form-control float-right'
                name='comp_descricao' value={comp_descricao} onChange={onChange}
                placeholder='Digite aqui para pesquisar' />
            <div className='input-group-append'>
                <button className='btn btn-default' onClick={handleSearch}>
                    <i className='fas fa-search'></i>
                </button>
            </div>
        </div>
    )
}
export default SearchComplements