import React, { useContext, useEffect, useState } from 'react'
import { formContext } from '../../../context/formContext'
import { requestContext } from '../../../context/requestContext'
import { config } from '../../../constants'
import SelectGroupIndexPavement from '../selectGroupIndexPavement'
const FormConsolidatedPavementIndex = () => {
	const { complements, getItemTypeIndexPavementIndicators } = useContext(requestContext)
	const { state, onChange, handleForm } = useContext(formContext)
	const [gp_index_irregularidade_longitudinal, setGpIndexIrregularidadeLongitudinal] = useState(null)
	useEffect(() => {
		const loadConfigGpSuperficie = async () => {
			const configuration_parameters = await config('gp_index_superficie')
			if (configuration_parameters) {
				const { config_para_value } = configuration_parameters
				if (config_para_value) {
					handleForm({
						gp_ind_pav_cod: Number(config_para_value)
					})
				}
			}
		}
		const loadConfigGpIrr = async () => {
			const configuration_parameters = await config('gp_index_irregularidade_longitudinal')
			if (configuration_parameters) {
				const { config_para_value } = configuration_parameters
				setGpIndexIrregularidadeLongitudinal(Number(config_para_value))
			}
		}
		loadConfigGpSuperficie()
		loadConfigGpIrr()
	}, [])
	const {
		ipc_date,
		gp_ind_pav_cod,
		tip_ind_pav_cod,
	} = state
	useEffect(() => {
		getItemTypeIndexPavementIndicators({
			id: 0,
			not_paginate: false
		})
	}, [])
	useEffect(() => {
		const loadState = () => {
			if (gp_ind_pav_cod && tip_ind_pav_cod) {
				handleForm({
					ipc_tip_cod: tip_ind_pav_cod,
					ipc_gp_ind_pav_cod: gp_ind_pav_cod
				})
			}
		}
		loadState()
	}, [tip_ind_pav_cod, gp_ind_pav_cod])
	return (
		<div className='row'>
			{Array.isArray(complements?.data) && complements?.data.filter(item => item.tip_ind_pav_cod >= 1 && item.tip_ind_pav_cod <= 3).map(item => (
				<div className='col-12' key={item.tip_ind_pav_cod}>
					<div className='row'>
						<SelectGroupIndexPavement id={`select_${item.tip_ind_pav_cod}`} label={item.tip_ind_pav_desc} />
						<div className="form-group col-md-6 col-sm-12">
							<label>Nota do levantamento de {item.tip_ind_pav_desc}:</label>
							<div className="input-group">
								<input
									type="number"
									className='form-control'
									id={item.tip_ind_pav_cod}
									disabled={(item.tip_ind_pav_desc === 'Igg2') && (Number(gp_ind_pav_cod) === gp_index_irregularidade_longitudinal)}
									placeholder="0.0"
								/>
							</div>
						</div>
					</div>
				</div>
			))}
			<div className="form-group col-md-6 col-sm-12">
				<label>Data do levantamento:</label>
				<div className="input-group">
					<input
						type="date"
						className={`form-control ${!ipc_date && 'is-invalid'
							}`}
						name="ipc_date"
						value={ipc_date}
						onChange={onChange}
						placeholder="_/__/____"
					/>
				</div>
			</div>
		</div>
	)
}
export default FormConsolidatedPavementIndex