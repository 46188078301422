import React, { useContext, useEffect } from 'react'
import { formContext } from '../../../context/formContext'
const ServiceCategory = () => {
	const { state, onChange, handleForm } = useContext(formContext)
	const {
		serv_cat_desc,
		serv_cat_disp,
	} = state
	useEffect(() => {
		if (serv_cat_disp === '0') {
			const confirm = window.confirm(`Você deseja mesmo tornar a categoria ${serv_cat_desc} como indisponível ? \n
            Se você confirmar essa ação todas as Atividades dessa categoria serão inativadas`)
			if(confirm) {
				return handleForm({
					serv_cat_disp: 0
				})
			}
		}
	}, [serv_cat_disp])
	return (
		<div className='row'>
			<div className="form-group col-md-6 col-sm-6">
				<label>Categoria Descrição:</label>
				<div className="input-group">
					<input type="text"
						onChange={onChange}
						name='serv_cat_desc' value={serv_cat_desc}
						className={`form-control ${!serv_cat_desc && 'is-invalid'}`}
						placeholder='Descrição da Categoria' />
				</div>
			</div>
			<div className="form-group col-md-6 col-sm-6">
				<label>Categoria Disponibilidade:</label>
				<select
					onChange={onChange}
					name='serv_cat_disp' value={serv_cat_disp}
					className={`form-control ${!serv_cat_disp && 'is-invalid'}`}
				>
					<option>Selecionar</option>
					<option value={1}>Disponível</option>
					<option value={0}>Indisponível</option>
				</select>
			</div>
		</div>
	)
}
export default ServiceCategory