import { useEffect, useRef } from "react"
import { MenuItem } from "../MenuItem"
import { Link } from "react-router-dom"

export const Menu = ({ activeMenu, setActiveMenu }) => {

    useEffect(() => {
        const controllTabes = () => {
            try {
                const getElemet = document.getElementById(`menu${activeMenu}`)
                getElemet.className = 'tab-pane fade in active show'
                Array.from({ length: 4 }).map((_, i) => i + 1).filter(i => i !== activeMenu).forEach(i => {
                    document.getElementById(`menu${i}`).className = 'tab-pane fade'
                })
                if (activeMenu < 5) {
                    document.getElementById('menu5').className = 'tab-pane fade'
                }
            } catch (error) {
                console.log(error)
            }
        }
        controllTabes()
    }, [activeMenu])

    return (
        <ul className="nav flex-column d-flex justify-content-between ">
            <li className="nav-item">
                <Link data-toggle="tab" to={'#'} onClick={() => setActiveMenu(1)}>
                    <MenuItem title={'Fotos'} desc={'Upload de imagens'} icon={'camera'} menuName={1} activeMenu={activeMenu} />
                </Link>
            </li>
            <li className="nav-item">
                <Link data-toggle="tab" to={'#'} onClick={() => setActiveMenu(2)}>
                    <MenuItem title={'Rodovias'} desc={'Concessionárias'} icon={'directions'} menuName={2} activeMenu={activeMenu} />
                </Link>
            </li>
            <li className="nav-item">
                <Link data-toggle="tab" to={'#'} onClick={() => setActiveMenu(3)}>
                    <MenuItem title={'Localização'} desc={'Km inicial e final'} icon={'location-arrow'} menuName={3} activeMenu={activeMenu} />
                </Link>
            </li>
            <li className="nav-item">
                <Link data-toggle="tab" to={'#'} onClick={() => setActiveMenu(4)}>
                    <MenuItem title={'Atividades'} desc={'Grupos e subgrupos'} icon={'tag'} menuName={4} activeMenu={activeMenu} />
                </Link>
            </li>
            <li className="nav-item">
                <Link data-toggle="tab" to={'#'} onClick={() => setActiveMenu(5)}>
                    <MenuItem title={'Concluir'} desc={'Resumo final'} icon={'arrow-right'} menuName={5} activeMenu={activeMenu} />
                </Link>
            </li>
        </ul>)
}