import React, { useContext, useEffect, useRef, useState } from 'react'
import { formContext } from '../../../context/formContext'
import { requestContext } from '../../../context/requestContext'
import { styleButtonSearch } from '../../../css/globlalStyle'
import { filter, find } from '../../../services/arrayHandless'
const SelectHighway = ({ col, setValuesFixed }) => {
	const {
		state, handleForm, onChangeSelectInput } = useContext(formContext)
	const { highway } = useContext(requestContext)
	const [items, setItems] = useState(null)
	const rod_cod_ref = useRef()
	const {
		rod_cod,
		conc_cod,
		rod_desc,
		rod_concessionaria,
	} = state
	useEffect(() => {
		if (conc_cod) {
			handleForm({
				rod_concessionaria: conc_cod
			})
		}
	}, [conc_cod])
	useEffect(() => {
		if (highway) {
			setItems(highway.data)
		}
	}, [highway])
	useEffect(() => {
		const filterByDealership = () => {
			const { data } = highway
			const filterData = filter(data, 'rod_concessionaria', rod_concessionaria)
			setItems(filterData.data)
		}
		if (rod_concessionaria && highway) {
			filterByDealership()
		}
	}, [rod_concessionaria])
	useEffect(() => {
		const loadValuesFixed = () => {
			if (rod_cod && items?.length) {
				const { data } = find(items, 'rod_cod', rod_cod)
				if (setValuesFixed) {
					setValuesFixed({
						...data
					})
				}
			}
		}
		loadValuesFixed()
	}, [rod_cod, items])
	useEffect(() => {
		const handleOnChange = ({ target }) => {
			try {
				if (setValuesFixed) {
					const value = JSON.parse(target.value)
					setValuesFixed({
						...value,
					})
				}
			} catch (error) {
				if (setValuesFixed) {
					return setValuesFixed(null)
				}
			}
		}
		const element = rod_cod_ref.current
		element?.addEventListener('change', handleOnChange)
		return () => {
			element?.removeEventListener('change', handleOnChange)
		}
	}, [])
	const handleCancel = () => {
		handleForm({
			rod_cod: '',
			rod_desc: '',
			rod_km: '',
			rod_sent_cod: '',
			rod_km_ini: '',
			rod_km_fim: '',
			rod_km_ini_mt: '',
			rod_km_fim_mt: '',
			rod_extencao: '',
			rod_tipo: '',
			rod_concessionaria: '',
		})
	}
	return (
		<div className={col ? `form-group ${col}` : 'form-group col-md-12 col-sm-12'}>
			<label>Rodovia:</label>
			<div className="input-group">
				{rod_desc ?
					<div className='form-control'>
						{rod_desc}
					</div>
					:
					<select
						ref={rod_cod_ref}
						name='rod_cod'
						onChange={onChangeSelectInput}
						className={`form-control ${!rod_cod && 'is-invalid'}`}>
						<option value={0}>
							{items?.length ? `${items.length} registro(s) encontrado(os)` : 'Selecione uma rodovia'}
						</option>
						{Array.isArray(items) && items.map(item => (
							<option key={item.rod_cod} value={JSON.stringify(item)}>
								{item.rod_desc}  - KM {item.rod_km}
							</option>
						))}
					</select>
				}
				{rod_desc &&
					<div className="input-group-prepend">
						<span className="input-group-text bg-info"
							style={styleButtonSearch}
							onClick={handleCancel}>
							<i className="fa fa-times-circle" aria-hidden="true"></i>
						</span>
					</div>
				}
			</div>
		</div >
	)
}
export default SelectHighway