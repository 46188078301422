import React, { useContext, useEffect, useState } from 'react'
import ContentHeader from '../../components/contentHeader'
import './style.css'
import { useNavigate } from "react-router-dom";
import { configurationsLinks } from '../../constants';
import { requestContext } from '../../context/requestContext';
import { handleError } from '../../services/isValid';
import { authContext } from '../../context/authContext';
const ManagerConfiguration = () => {
    const navigate = useNavigate();
    const { user } = useContext(authContext)
    const { setInfoRequest } = useContext(requestContext)
    const [items, setItems] = useState([])
    useEffect(() => {
        const loadItems = () => {
            try {
                if (user && configurationsLinks?.length) {
                    setItems(configurationsLinks.filter(item => item?.set_nivel.some(nivel => nivel === user?.set_nivel)))
                }
            } catch (error) {
                setInfoRequest(handleError(String(error)))
            }
        }
        loadItems()
    }, [])
    return (
        <>
            <ContentHeader title={'Configurações'} />
            <div className='content'>
                <div className="row p-5">
                    {items && items.map(item =>
                    (
                        <div onClick={() => navigate(item.path)} className="col-6 col-md-3 p-3 d-flex clickable">
                            <div className="mr-2">
                                <i className={`Icon fa fa-${item?.icon}`} aria-hidden="true"></i>
                            </div>
                            <div>
                                <strong>{item?.title}</strong>
                                <p className="Subtitle">{item?.subtitle}</p>
                            </div>
                        </div>
                    )
                    )}
                </div>
            </div>
        </>

    )
}
export default ManagerConfiguration