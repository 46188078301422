/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { formContext } from "../../../../context/formContext";
import { requestContext } from "../../../../context/requestContext";
const ListItemSubService = ({ item, data }) => {
    const { handleForm, state } = useContext(formContext);
    const { postItemCategorySubService, deleteItemCategorySubService } = useContext(requestContext);
    const [servsubcatdisp, setServSubCatDisp] = useState(null);
    const [servsubcatdesc, setServSubCatDesc] = useState(null);
    const [edit, setEdit] = useState(false);
    const { serv_cat_cod } = state;
    useEffect(() => {
        const setValuesFromItem = () => {
            if (item?.serv_sub_cat_disp) {
                setServSubCatDisp(item.serv_sub_cat_disp)
            }
            if (item?.serv_sub_cat_desc) {
                setServSubCatDesc(item.serv_sub_cat_desc)
            }
        }
        setValuesFromItem()
    }, [item])
    useEffect(() => {
        const verifyisEdit = () => {
            if (!servsubcatdisp) {
                return
            }
            setEdit(item.serv_sub_cat_disp !== servsubcatdisp);
        }
        verifyisEdit()
    }, [servsubcatdisp]);
    const handleRemove = () => {
        if (item?.serv_sub_cat_cod) {
            return handleDelete()
        }
        const itemFilter = data.filter(
            (value) => value.serv_sub_cat_desc !== item.serv_sub_cat_desc
        );
        handleForm({
            pv_servico: itemFilter,
        });
    };
    const handleDelete = () => {
        const { serv_sub_cat_cod, serv_sub_cat_desc, serv_cat_desc, serv_desc } = item;
        const confirm = window.confirm(`Você deseja mesmo remover esse o sub serviço ${serv_sub_cat_desc} de ${serv_cat_desc} -> ${serv_desc}?`)
        if (!confirm) {
            return
        }
        deleteItemCategorySubService({
            id: serv_sub_cat_cod,
            serv_cat_cod,
        })
    }
    const onChange = ({ target }) => {
        const { checked, name, value } = target;
        if (name === "servsubcatdesc") {
            return setServSubCatDesc(value);
        }
        setServSubCatDisp(!checked ? 0 : 1);
    };
    const handleSubmit = () => {
        if (
            item.serv_sub_cat_desc !== servsubcatdesc ||
            item.serv_sub_cat_disp !== servsubcatdisp
        ) {
            const confirm = window.confirm(
                `Deseja mesmo alterar a Atividade ${item.serv_sub_cat_desc} ?`
            );
            if (confirm) {
                return postItemCategorySubService({
                    serv_cat_cod,
                    id: item.serv_sub_cat_cod,
                    serv_sub_cat_desc: servsubcatdesc,
                    serv_sub_cat_disp: servsubcatdisp,
                });
            } else {
                return setServSubCatDisp(item.serv_sub_cat_disp);
            }
        }
    };
    return (
        item && (
            <div className="row mb-3">
                <div className="col-md-10 col-sm-12">
                    <div className="row">
                        {!edit && (
                            <>
                                {item.serv_desc && (
                                    <div className="col-md-4 col-sm-12">
                                        <div className="text-secondary mr-2">
                                            Serviço:
                                        </div>
                                        {item.serv_desc}
                                    </div>
                                )}
                                {item.serv_cat_desc && (
                                    <div className="col-md-4 col-sm-12">
                                        <div className="text-secondary">
                                            Categoria:
                                        </div>
                                        {item.serv_cat_desc}
                                    </div>
                                )}
                                <div className="col-md-4 col-sm-12">
                                    <div className="text-secondary">
                                        Atividade:
                                    </div>
                                    {item.serv_sub_cat_desc}
                                </div>
                            </>
                        )}
                        {edit ? (
                            <div className="form-group col-md-6 col-sm-12">
                                <input
                                    name="servsubcatdesc"
                                    value={servsubcatdesc}
                                    onChange={onChange}
                                    className={`form-control ${!servsubcatdesc && "is-invalid"
                                        }`}
                                />
                            </div>
                        ) : (
                            <div className="col-md-3 col-sm-6">{item.tip_serv_sub_cat_desc}</div>
                        )}
                        {item.serv_cat_prazo && (
                            <div className="col-md-3 col-sm-6">
                                {new Date(item.serv_cat_prazo).toLocaleDateString("pt-BR", {
                                    timeZone: "UTC",
                                })}
                            </div>
                        )}
                    </div>
                </div>
                <div className="col-md-2 col-sm-12">
                    {!edit ? (
                        <div
                            className="btn btn-info btn-sm mr-3"
                            onClick={() => setEdit(!edit)}
                        >
                            <i className="fa fa-edit"></i>
                        </div>
                    ) : (
                        <div
                            className="btn btn-info btn-sm mr-3"
                            onClick={() => handleSubmit()}
                        >
                            <i className="fa fa-save"></i>
                        </div>
                    )}
                    <div className="btn btn-secondary btn-sm" onClick={handleRemove}>
                        <i className="fa fa-trash"></i>
                    </div>

                </div>
                <hr className="w-100" />
            </div>
        )
    );
};
export default ListItemSubService;