import React, { useContext, useEffect, useRef } from 'react'
import { formContext } from '../../../../context/formContext'
import { requestContext } from '../../../../context/requestContext'
import { config } from '../../../../constants'
import { find } from '../../../../services/arrayHandless'
const FilterStatusOversight = ({
	multiple = true
}) => {
	const { handleForm, state: { ped_venda_status } } = useContext(formContext)
	const { status } = useContext(requestContext)
	const inputRef = useRef()
	const loadConfig = async () => {
		const config_ = await config('ped_venda_status_pendente_execucao')
		if (!config_ || !status?.data) {
			return
		}
		const { config_para_value } = config_
		if (config_para_value) {
			const { data: findStatus } = find(status.data, 'st_cod', Number(config_para_value))
			handleForm({
				...findStatus,
				ped_venda_status: findStatus?.st_cod
			})
		}
	}
	useEffect(() => {
		loadConfig()
	}, [status])
	const onBlur = () => {
		try {
			if (inputRef?.current?.options) {
				const itemsSeleted = []
				for (const option of inputRef?.current?.options) {
					if (!option?.selected) {
						continue
					}
					itemsSeleted.push(Number(option?.value))
				}
				const cleanIds = new Set([...itemsSeleted])
				handleForm({
					ped_venda_status: Array.from(cleanIds).join(',')
				})
			}
		} catch (error) {
			console.log(error)
		}
	}
	const RenderOption = ({ item }) => {
		try {
			const statusArray = String(ped_venda_status || '').split(',').map(i => Number(i));
			const result = statusArray.includes(item.st_cod);
			return (
				<option className={result ? 'bg-info text-light' : ''} key={item.st_cod} value={item.st_cod}>
					{item.st_desc}
				</option>

			)
		} catch (_) {
			return (
				<option key={item.st_cod} value={item.st_cod}>
					{item.st_desc}
				</option>
			)
		}
	}
	return (
		<div className="form-group">
			<label>Situação</label>
			<select
				ref={inputRef}
				className="form-control"
				multiple={multiple}
				onBlur={onBlur}
			>
				<option value={0}>Todas as Situações</option>
				{Array.isArray(status?.data) && status.data.map(item => <RenderOption item={item} />)}
			</select>
		</div>
	)
}
export default FilterStatusOversight