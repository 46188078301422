import React, { useContext, useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { requestContext } from '../../context/requestContext'
import ContentHeader from '../../components/contentHeader'
import { formContext } from '../../context/formContext'
import Spinner from '../../components/spinner'
import SearchContructionPlan from '../../components/form/searchContructionPlan'
import ListContructionPlan from '../../components/list/constructionPlan'
import FilterConstructionPlan from '../../components/form/filters/constructionPlan'
import { namesScreens } from '../../constants'
import { handleError } from '../../services/isValid'
const ManagerConstructionPlan = () => {
	const {
		setHighway,
		getItemConstructionPlan,
		getItemAllStatusOversight,
		getAllItemDealership,
		getAllItemHighway,
		construction_plan,
		setConstructionPlan,
		setInfoRequest
	} = useContext(requestContext)
	const { handleForm, state: {
		desc,
		conc_cod,
		rod_cod,
		ped_venda_date_init,
		ped_venda_date_fim,
		type_date
	} } = useContext(formContext)
	const navigate = useNavigate()
	const [itemsSelected, setItemsSelected] = useState([])
	useEffect(() => {
		getItemConstructionPlan({
			id: conc_cod,
			rod_cod,
			ped_venda_date_init,
			ped_venda_date_fim,
			desc,
			type_date
		})
	}, [])
	useEffect(() => {
		setHighway(null)
		getItemAllStatusOversight()
		getAllItemDealership()
		getAllItemHighway()
	}, [])
	const handlePage = (item) => {
		if (!item) {
			return
		}
		const { label } = item
		const { current_page, last_page } = construction_plan
		window.scrollTo(0, 0)
		if (label === 'pagination.previous') {
			return getItemConstructionPlan({
				id: conc_cod,
				rod_cod,
				ped_venda_date_init,
				ped_venda_date_fim,
				desc,
				type_date: current_page > 1 && `0?page=${current_page - 1}`,
			})
		}
		if (label === 'pagination.next') {
			return getItemConstructionPlan({
				id: conc_cod,
				rod_cod,
				ped_venda_date_init,
				ped_venda_date_fim,
				desc,
				type_date: current_page < last_page && `0?page=${current_page + 1}`
			})
		}
		if (!label) {
			return getItemConstructionPlan({
				id: conc_cod,
				rod_cod,
				ped_venda_date_init,
				ped_venda_date_fim,
				desc,
				type_date: current_page > 1 && `0?page=${current_page - 1}`,
			})
		}
		getItemConstructionPlan({
			id: conc_cod,
			rod_cod,
			ped_venda_date_init,
			ped_venda_date_fim,
			desc,
			type_date: `0?page=${label}`
		})
	}
	const handleNew = () => {
		setConstructionPlan(null)
		resetValues()
		navigate('/construction_plan/new')
	}

	const resetValues = () => handleForm({
		pl_ob_data_program_ini: '',
		pl_ob_data_program_fim: '',
		pl_ob_data_exec_ini: '',
		pl_ob_data_exec_fim: '',
		pl_ob_data_ana_ini: '',
		pl_ob_data_ana_fim: '',
		pl_ob_data_prev_ini: '',
		pl_ob_data_prev_fim: '',
		prog_ob_por_cen_progress: '',
		pl_ob_valor: '',
		conc_cod: '',
		rod_cod: '',
		anexos_plano_obra: '',
		pl_ob_cod: '',
		pl_ob_cod_item: '',
		pl_ob_desc_object: '',
		tip_ob_cod: '',
		conc_nome: '',
		rod_desc: '',
		rod_sent_cod: '',
		rod_sent_desc: '',
		sum_acob_perc_prev: 0,
	})
	const onClick = () => {
		getItemConstructionPlan({
			id: conc_cod,
			rod_cod,
			ped_venda_date_init,
			ped_venda_date_fim,
			desc,
			type_date
		})
	}
	const handleClar = () => {
		setItemsSelected([])
		handleForm({
			conc_cod: 0,
			conc_nome: '',
			rod_cod: 0,
			rod_desc: '',
			ped_venda_date_init: null,
			ped_venda_date_fim: null,
			type_date: 8
		})
		getItemConstructionPlan({
			id: 0,
			rod_cod: 0,
			ped_venda_date_init: null,
			ped_venda_date_fim: null,
			desc,
			type_date: 8
		})
	}
	const RenderPaginate = ({ item }) => {
		return (
			<li className="page-item" key={item.label}>
				<Link
					className={`page-link ${item.active && 'text-light bg-info'
					}`}
					to="#"
					onClick={() => handlePage(item)}
				>
					{item.label === 'pagination.previous' ? (
						<i className="fa fa-angle-left"></i>
					) : item.label === 'pagination.next' ? (
						<i className="fa fa-angle-right"></i>
					) : (
						item.label
					)}
				</Link>
			</li>
		)
	}
	const getGroup = () => {
		try {
			const plObCods = itemsSelected.map(item => item.pl_ob_cod).join(',')
			const pedVendaCods = itemsSelected.map(item => item.ped_venda_cod).join(',')
			const sorteredData = itemsSelected.sort((prev, next) => prev.pl_ob_cod - next.pl_ob_cod)
			const firstData = sorteredData[0]
			const {
				pl_ob_data_ana_ini: pl_ob_data_ana_ini_from_first,
				pl_ob_data_exec_ini: pl_ob_data_exec_ini_from_first,
				pl_ob_data_prev_ini: pl_ob_data_prev_ini_from_first,
				pl_ob_data_program_ini: pl_ob_data_program_ini_from_first,
				...restfirstdata
			} = firstData
			const lassData = sorteredData[sorteredData?.length - 1]
			const {
				pl_ob_data_ana_fim: pl_ob_data_ana_fim_from_lass,
				pl_ob_data_exec_fim: pl_ob_data_exec_fim_from_lass,
				pl_ob_data_prev_fim: pl_ob_data_prev_fim_from_lass,
				pl_ob_data_program_fim: pl_ob_data_program_fim_from_lass,
			} = lassData
			const fisc_obs = sorteredData.map(item => item.pl_ob_desc_object).join(', ')
			const sisf_sinc_int_amp_id = sorteredData.map(item => {
				if (item.sisf_sinc_int_amp_id) {
					return item.sisf_sinc_int_amp_id
				}
				return item.pl_ob_cod
			}).join(', ')
			handleForm({
				...restfirstdata,
				pl_ob_data_ana_ini: pl_ob_data_ana_ini_from_first,
				pl_ob_data_exec_ini: pl_ob_data_exec_ini_from_first,
				pl_ob_data_prev_ini: pl_ob_data_prev_ini_from_first,
				pl_ob_data_program_ini: pl_ob_data_program_ini_from_first,
				pl_ob_data_ana_fim: pl_ob_data_ana_fim_from_lass,
				pl_ob_data_exec_fim: pl_ob_data_exec_fim_from_lass,
				pl_ob_data_prev_fim: pl_ob_data_prev_fim_from_lass,
				pl_ob_data_program_fim: pl_ob_data_program_fim_from_lass,
				pl_ob_ped_venda: pedVendaCods,
				pl_ob_cod: plObCods,
				ped_venda_cod: pedVendaCods,
				fisc_obs,
				sisf_sinc_int_amp_id,
			})
			navigate(namesScreens.CONSTRUCTION_PLANVIEW)
		} catch (error) {
			console.log(error)
			setInfoRequest(handleError('Não foi possível juntar os planos de obras, tente novamente'))
		}
	}
	return (
		<>
			<ContentHeader title={'Plano de obras'} />
			<div className="content">
				<div className={itemsSelected?.length ? 'card' : 'card sticky-top'}>
					<div className="card-header row w-100">
						<h3 className="card-title col-8 d-none d-md-block">
							Obras cadastradas
						</h3>
						<div className="card-tools col-md-4	 col-sm-12">
							<SearchContructionPlan handleNew={handleNew} />
						</div>
					</div>
				</div>
				<div className='card'>
					<div className='card-body'>
						<FilterConstructionPlan />
					</div>
					<div className="card-footer">
						<div className="row">
							<div className="col-md-9 col-sm-8">
								<button className="btn btn-info mr-2" onClick={onClick}>
									<i className="fa fa-filter" aria-hidden="true"></i>
									Aplicar filtro</button>
								<button className="btn btn-dark" onClick={handleClar}>
									<i className="fa fa-eraser" aria-hidden="true"></i>
									Limpar filtro
								</button>
							</div>
							<div className="col-md-3 col-sm-4">
								{construction_plan?.total} ocorrências encontradas
							</div>
						</div>
					</div>
				</div>
				{itemsSelected?.length ? (
					<div className="card sticky-top">
						<div className='card-body'>
							<div className='col-md-4 col-sm-12'>
								<div className="btn btn-sm btn-secondary mr-2" onClick={() => getGroup()}>
									<i className="fa fa-eye mr-2"></i>
									Exibir os {itemsSelected?.length} planos de obras selecionados
								</div>
							</div>
						</div>
					</div>
				) : ''}
				<div className="card">
					<Spinner direction={'center'} label="carregando" />
					<ListContructionPlan
						data={construction_plan?.data}
						itemsSelected={itemsSelected}
						setItemsSelected={setItemsSelected}
					/>
					<div className="card-footer clearfix">
						<ul className="pagination pagination-sm m-0 float-right">
							{Array.isArray(construction_plan?.links) &&
								construction_plan.links.map((item) => (<RenderPaginate item={item} key={item.label} />))}
						</ul>
					</div>
				</div>
			</div >
		</>
	)
}
export default ManagerConstructionPlan
