import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { liClassNameNavBarOpened, namesScreens, tempNavItems } from '../../constants'
import { authContext } from '../../context/authContext'
const SiderBar = () => {
	const { user, userImage } = useContext(authContext)
	const [navItems, setNavItems] = useState([])
	const handOpenModal = (path) => {
		const li = document.getElementById(path)
		if (li.className === liClassNameNavBarOpened) {
			return li.className = 'nav-item'
		}
		li.className = liClassNameNavBarOpened
	}
	useEffect(() => {
		userImage()
	}, [])
	useEffect(() => {
		const loadNavitems = () => {
			if (!user) {
				return
			}

			const navItemsByProfile = tempNavItems.filter(item => item.set_nivel.some(x => x === user?.set_nivel))
			setNavItems(navItemsByProfile)
		}
		loadNavitems()
	}, [user])
	const generateId = (li) => {
		return li?.sub_nav_items?.length ? li.sub_nav_items[0].path : li.path
	}
	return (
		<aside className="main-sidebar sidebar-dark-primary elevation-4">
			{user?.set_nivel === undefined || user?.set_nivel === null ? (
				<div className='sidebar'>

				</div>
			) : (
				<>
					<div className="sidebar">
						<div className="user-panel mt-3 pb-3 mb-3 d-flex">
							<div className="image">
								<img src={user?.usu_img} className="img-circle elevation-2" alt="User Image" />
							</div>
							<div className="info">
								<Link to="#" className="d-block">
									{user?.name}
								</Link>
							</div>
						</div>
						<nav className="mt-2">
							<ul
								className="nav nav-pills nav-sidebar flex-column"
								data-widget="treeview"
								role="menu"
								data-accordion="false"
							>
								<li className="nav-item">
									<Link
										to="/"
										className="nav-link"
									>
										<i className='nav-icon fa fa-home'></i>
										<p>Inicio</p>
									</Link>
								</li>

								{navItems.sort((a, b) => a.label - b.label).map((li, i) => (
									<li
										id={generateId(li)}
										className='nav-item'
										key={i}>
										<Link
											onClick={() => handOpenModal(generateId(li))}
											to={li.path}
											className="nav-link"
										>
											<i className={`nav-icon ${li.name_icon}`}></i>
											<p>
												{li.label}
												{li.sub_nav_items.length ? (<i className="fas fa-angle-left right"></i>) : null}
											</p>
										</Link>
										{li.sub_nav_items.length ?
											<ul className="nav nav-treeview">
												{li.sub_nav_items.filter(li => li.set_nivel.some(x => x === user?.set_nivel)).sort((a, b) => a.label - b.label).map((ul, index) => (
													<li id={generateId(ul)} className="nav-item" key={index}>
														<Link
															onClick={() => handOpenModal(generateId(ul))}
															to={ul.path}
															className="nav-link"
														>
															<i className={`nav-icon ${ul.name_icon}`}></i>
															<p>
																{ul.label}
																{ul?.sub_nav_items?.length ? (<i className="fas fa-angle-left right"></i>) : null}
															</p>
														</Link>
														{ul?.sub_nav_items?.length ? (
															<ul className='nav nav-treeview'>
																{ul?.sub_nav_items?.filter(sub_ul => sub_ul.set_nivel.some(x => x === user?.set_nivel)).sort((a, b) => a.label - b.label).map(sub_ul => (
																	<li className="nav-item ml-2" key={sub_ul.path}>
																		<Link

																			to={sub_ul.path}
																			className="nav-link"
																		>
																			<i className={`nav-icon ${sub_ul.name_icon}`}></i>
																			<p>
																				{sub_ul.label}
																			</p>
																		</Link>
																	</li>
																))}
															</ul>
														) : null}
													</li>
												))}
											</ul>
											: null}
									</li>
								))}
								<li className="nav-item" style={{
									position: 'fixed',
									bottom: '0%'
								}}>
									<Link
										to={namesScreens.CONFIGURATIONS}
										className="nav-link"
									>
										<i className='nav-icon fa fa-cog'></i>
										<p>Configurações</p>
									</Link>
								</li>
							</ul>
						</nav>
					</div>
				</>
			)}
		</aside>
	)
}
export default SiderBar