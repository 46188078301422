import { useContext, useEffect } from "react"
import { formContext } from "../../../../context/formContext"
import { requestContext } from "../../../../context/requestContext"

export const Dealership = () => {

    const { onChangeSelectInput, state: { conc_nome } } = useContext(formContext)
    const { data, getAllItemDealershipLot } = useContext(requestContext)
    useEffect(() => {
        getAllItemDealershipLot()
    }, [])
    return (
        <>
            <label>Concessionária</label>
            <select
                className="form-control"
                placeholder='Selecione'
                name="conc_cod"
                onChange={onChangeSelectInput}
            >
                <option value={''}>
                    {conc_nome || 'Selecione'}
                </option>
                {Array.isArray(data?.data) && data.data.sort((a, b) => a.lot_conc_desc - b.lot_conc_desc).map(item => (
                    <option key={parseInt(item.conc_cod)} value={JSON.stringify(item)}>
                        Lote {item.lot_conc_desc} - {item.conc_nome}
                    </option>
                ))}
            </select>
        </>
    )
}