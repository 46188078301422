import React, { useContext, useState, useEffect, useRef } from 'react'
import { requestContext } from '../../../../context/requestContext'
import { formatDate } from '../../../../services/dateHandless'
import { useNavigate } from 'react-router-dom'
import Spinner from '../../../../components/spinner'
import { LOGO } from '../../../../config/flavor'
import { useReactToPrint } from 'react-to-print'
import { handleWaring } from '../../../../services/isValid'
import { groupBy } from '../../../../services/arrayHandless'

const ReportOversightRoutineView = ({
	reportData,
	handleCancel
}) => {
	const { loading, setInfoRequest } = useContext(requestContext)
	const [items, setItems] = useState([])
	const navigate = useNavigate()
	useEffect(() => {
		setInfoRequest(handleWaring('Se certifique de que todas as imagens foram carregadas antes de imprimir'))
	}, [])
	const [invisible, setInvisible] = useState(false)
	const dateRegex = /(\d{2}\/\d{2}\/\d{4})/
	const componentRef = useRef(null)
	const styles = {
		page: {
			flexDirection: 'column',
			backgroundColor: 'white',
			padding: 20,
			fontFamily: 'Helvetica'
		},
		section: {
			margin: 10,
			padding: 10,
			flexGrow: 1
		},
		header: {
			display: 'flex',
			flexDirection: 'row'
		},
		headerImage: {
			width: '40%',
		},
		headerText: {
			width: '50%',
			display: 'flex',
			flexDirection: 'column',
			padding: 20
		},
		line: {
			display: 'flex',
			flexDirection: 'row',
		},
		smallText: {
			fontSize: 14,
			marginBottom: 3
		},
		verySmallText: {
			fontSize: 10,
			marginBottom: 3
		},
		smallHeaderText: {
			fontSize: 16,
			width: '100%',
			color: '#9a9a9a',
			textAlign: 'right'

		},
		breakLine: {
			marginTop: 10
		},
		box: {
			border: '1px solid #808080',
			borderRadius: 10,
			padding: 10,
			minHeight: '26vh',
			marginBottom: '1rem',
			width: '100%',
			display: 'flex',
			flexDirection: 'column',
			flexWrap: 'wrap',
			justifyContent: 'space-around',
		},
		photoBox: {
			width: '100%',
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'space-around',
		},
		photoItem: {
			display: 'flex',
			flexDirection: 'column',
			maxWidth: '30%',
			marginBottom: 3,
			alignItems: 'center',
			justifyContent: 'center'
		},
		imgPhoto: {

			height: 120,
			width: 213,
			borderRadius: 10
		},
		invisible: {
			display: 'none'
		},
		visible: {
			display: 'block',
			width: '100%',
			marginLeft: 15,
			marginTop: 5
		}
	}
	const generatePdfDocument = async () => {
		setInvisible(true)
		setTimeout(() => {
			handlePrint()
		}, 3000)
	}
	const handlePrint = useReactToPrint({
		content: () => componentRef.current,
		copyStyles: true,
		onAfterPrint: () => afterPrint(),
	})


	const afterPrint = () => {
		setInvisible(false)
	}

	useEffect(() => {
		if (Array.isArray(reportData)) {
			prepareDateToPDF(reportData)
		}
	}, [reportData])

	const prepareDateToPDF = (array) => {
		const novoArray = array.map(item => {
			return {
				...item, pedido_venda_servico: item.pedido_venda_servico.map(pvs => {
					return { ...pvs, pedido_venda_servico_foto: defineFinalPhoto(pvs.pedido_venda_servico_foto) }
				})
			}
		})
		let finalArray = []

		for (let i = 0; i < novoArray.length; i += 3) {

			const tempArr = novoArray.sort((a, b) => b?.pedido_venda?.ped_venda_cod - a?.pedido_venda?.ped_venda_cod).slice(i, i + 3)
			finalArray.push(tempArr)
		}
		setItems(finalArray)
	}

	const defineFinalPhoto = (arrayData) => {

		const finalArray = arrayData.map(obj => {
			const date = new Date(obj.pvs_loc_foto_data * 1000)
			const dateOnly = new Date(date.getFullYear(), date.getMonth(), date.getDate())
			const reconvertedDate = Date.parse(dateOnly)
			return {
				...obj,
				pvs_loc_foto_data: reconvertedDate
			};
		});

		const groupedObj = groupBy(finalArray, 'pvs_loc_foto_data')

		const result = [];

		for (const key in groupedObj) {
			if (groupedObj.hasOwnProperty(key)) {
				const value = Array.isArray(groupedObj[key]) ? groupedObj[key] : [groupedObj[key]];
				value.sort((a, b) => {
					if (a.pvs_loc_foto_data < b.pvs_loc_foto_data) {
						return -1;
					}
					if (a.pvs_loc_foto_data > b.pvs_loc_foto_data) {
						return 1;
					}
					return 0;
				});
				result.push(value);
			}
		}


		let photo1 = null
		let photo2 = null
		let photo3 = null

		if (result?.length === 1) {
			photo1 = result[0][result[0]?.length - 1]
		} else if (result?.length === 2) {
			photo1 = result[0][result[0]?.length - 1]
			photo2 = result[1][result[1]?.length - 1]
		} else if (result?.length === 3) {
			photo1 = result[0][result[0]?.length - 1]
			photo2 = result[1][result[1]?.length - 1]
			photo3 = result[2][result[2]?.length - 1]
		} else if (result?.length === 4) {
			photo1 = result[0][result[0]?.length - 1]
			photo2 = result[3][result[3]?.length - 1]
			photo3 = null
		} else if (result?.length === 5) {
			photo1 = result[0][result[0]?.length - 1]
			photo2 = result[3][result[3]?.length - 1]
			photo3 = result[4][result[4]?.length - 1]
		}
		return [
			{ ...photo1, 'pvs_loc_foto_path': photo1?.pvs_loc_foto_path },
			{ ...photo2, 'pvs_loc_foto_path': photo2?.pvs_loc_foto_path },
			{ ...photo3, 'pvs_loc_foto_path': photo3?.pvs_loc_foto_path }
		]

	}
	const RenderContainer = () => {
		return (
			<>
				{items.map((item) => (
					<div key={item} size="A4" style={styles.page}>
						<div style={styles.header}>
							<img src={require('../../../../assets/Artesp/images/relatorio_logo.png')} alt="image_logo" style={styles.headerImage} />
							<div style={styles.headerText}>
								<p style={styles.smallHeaderText}>Relatório de conservação</p>
								<p style={styles.smallHeaderText}>Gerado no dia {formatDate()}</p>
							</div>
						</div>
						{Array.isArray(item) && item.map((element, i) => (
							<div key={i} style={styles.box}>
								<div style={styles.line}>
									<p style={{ ...styles.smallText, width: '50%' }}>NC Nº: {element.pedido_venda?.sisf_sinc_id || element.pedido_venda.ped_venda_cod} | Nº de rastreio: {element.pedido_venda.ped_venda_cod} | Lote: {element.pedido_venda.lot_conc_desc} | Data: {formatDate(element.pedido_venda.ped_venda_date)}</p>
									<p style={{ ...styles.smallText, width: '50%' }}>Concessionária: {element.pedido_venda.conc_nome}</p>
								</div>
								{element?.pedido_venda_servico?.length > 0 && element?.pedido_venda_servico?.map(servico => (
									<>
										<div style={styles.line}>
											<p style={{ ...styles.smallText, width: '50%' }}>Tipo Atividade: {servico.serv_desc}</p>
											<p style={{ ...styles.smallText, width: '50%' }}>Grupo Atividade: {servico.serv_cat_desc}</p>
										</div>
										<div style={styles.line}>
											<p style={styles.smallText}>Atividade: {servico.serv_sub_cat_desc}</p>
										</div>
										<div style={styles.line}>
											<p style={{ ...styles.smallText, width: '50%' }}>Status: {servico.st_desc} | Prazo: {formatDate(servico.pvs_prazo_fim)}</p>
										</div>
										<div style={styles.line}>
											<p style={styles.smallText}>Rodovia: {element.pedido_venda.rod_desc} | Inicial: {servico.pv_loc_km_ini + ',' + servico.pv_loc_km_init_mts + ' Km'} | Final: {servico.pv_loc_km_fim + ',' + servico.pv_loc_km_fim_mts + ' Km'} | Sentido: {servico.rod_sent_desc}</p>
										</div>
										<div style={styles.breakLine} />
										{servico?.pedido_venda_servico_foto?.length > 0 && (
											<>
												<div style={styles.photoBox}>
													{servico.pedido_venda_servico_foto[0] !== null &&
														<div style={styles.photoItem} >
															<img src={servico.pedido_venda_servico_foto[0].pvs_loc_foto_path} alt="Foto da NC" style={styles.imgPhoto} />
															<p style={styles.verySmallText}>{`Abertura ${servico.pedido_venda_servico_foto[0].pvs_loc_foto_legenda?.match(dateRegex) ? servico.pedido_venda_servico_foto[0].pvs_loc_foto_legenda?.match(dateRegex)[0] : ''}`}</p>
														</div>
													}
													{servico.pedido_venda_servico_foto[1].pvs_loc_foto_path !== undefined &&
														<div style={styles.photoItem} >
															<img src={servico.pedido_venda_servico_foto[1].pvs_loc_foto_path} alt="Foto da NC" style={styles.imgPhoto} />
															<p style={styles.verySmallText}>{`Retorno Concessionária ${servico.pedido_venda_servico_foto[1].pvs_loc_foto_legenda?.match(dateRegex) ? servico.pedido_venda_servico_foto[1].pvs_loc_foto_legenda?.match(dateRegex)[0] : ''}`}</p>
														</div>
													}
													{servico.pedido_venda_servico_foto[2].pvs_loc_foto_path !== undefined &&
														<div style={styles.photoItem} >
															<img src={servico.pedido_venda_servico_foto[2].pvs_loc_foto_path} alt="Foto da NC" style={styles.imgPhoto} />
															<p style={styles.verySmallText}>{`Retorno Fiscalização ${servico.pedido_venda_servico_foto[2].pvs_loc_foto_legenda?.match(dateRegex) ? servico.pedido_venda_servico_foto[2].pvs_loc_foto_legenda?.match(dateRegex)[0] : ''}`}</p>
														</div>
													}
												</div>
											</>

										)}
									</>
								))}
							</div>
						))}
					</div>
				))}
			</ >
		)
	}
	return (
		<>
			{Array.isArray(items) && (
				<div ref={componentRef}>
					<RenderContainer />
				</div>
			)}
			{!invisible && (
				<div className="card">
					<div className="card-body">
						{loading ? (
							<button className="btn btn-info mr-3">
								<Spinner label={'Gerando...'} direction={'center'} />
							</button>
						) : (
							<button className="btn btn-info mr-3" onClick={generatePdfDocument}>
								Imprimir <i className="fa fa-print"></i>
							</button>
						)}
						{handleCancel ? (
							<button className="btn btn-secondary" onClick={handleCancel}>
								Cancelar <i className="fa fa-times-circle"></i>
							</button>
						) : null}
					</div>
				</div>
			)}
		</>
	)
}
export default ReportOversightRoutineView
