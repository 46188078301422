import React, { useContext } from "react";
import { formContext } from "../../../context/formContext";
const FormComplements = () => {
    const { onChange, state } = useContext(formContext)
    const { comp_descricao, comp_ativo } = state
    return (
        <div className="row">
            <div className="form-group col-md-6 col-sm-12">
                <label>Complemento descrição:</label>
                <div className="input-group">
                    <input type="text"
                        className="form-control"
                        onChange={onChange}
                        name='comp_descricao' value={comp_descricao}
                        placeholder='Descreva o complemento' />
                </div>
            </div>
            <div className="form-group col-md-6 col-sm-12">
                <label>Complemento ativo:</label>
                <div className="input-group">
                    <select className={`form-control ${!comp_ativo && 'is-invalid'}`}
                        name='comp_ativo' value={comp_ativo} onChange={onChange}
                    >
                        <option>Selecionar</option>
                        <option value={1}>Ativo</option>
                        <option value={0}>Inativo</option>
                    </select>
                </div>
            </div>
        </div>
    )
}
export default FormComplements