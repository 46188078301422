import React from "react"
export const ImageBox = ({ items, handleAttach, handleRemove, onlyView = false }) => {
    return (
        <div className="ImageBox">
            <div className="row">
                {items?.map((item) => (
                    <div key={item.id} className="col-lg-3 col-md-4 col-sm-6 mb-1">
                        <div style={{ position: 'relative' }} className="ImageItem">
                            <img className="ImageItem" src={item.pvs_loc_foto_path} />
                            {!onlyView && <i className={'nav-icon fas fa-trash TrashIcon'} onClick={() => handleRemove(item)}></i>}
                        </div>

                    </div>
                ))}
                {!onlyView && <div className="col-lg-3 col-md-4 col-sm-6 mb-1">
                    <div className="ImageItem clickable" onClick={handleAttach}>
                        +
                    </div>
                </div>}
            </div>
        </div>
    )
}