import React, { useContext } from "react";
import { formContext } from "../../../context/formContext";
import ListCheckinspector from "../../list/checkinspector";
const ModalShowCheckInspection = ({
    openModalFisc,
    handleCloseModal
}) => {
    const { state: {
        ped_venda_cod
    }
    } = useContext(formContext)
    return (
        <div className={openModalFisc.className} id={`modal_fiscalizacao_${ped_venda_cod}`} style={{
            ...openModalFisc.style,
            overflowY: 'scroll'
        }}
            tabIndex='-1' role='dialog' aria-labelledby={`modal_fiscalizacao_label_${ped_venda_cod}`} aria-hidden='true'>
            <div className='modal-dialog modal-lg'>
                <div className='modal-content'>
                    <div className='modal-header'>
                        <h5 className='modal-title' id={`modal_fiscalizacao_label_${ped_venda_cod}`}>
                            Fiscalizações da Nc° {ped_venda_cod}
                        </h5>
                        <button type='button' onClick={handleCloseModal} className='close' data-dismiss='modal' aria-label='Close'>
                            <span aria-hidden='true'>&times;</span>
                        </button>
                    </div>
                    <div className='modal-body'>
                        <ListCheckinspector />
                    </div>
                </div>
            </div>
        </div>
    )
}
export default ModalShowCheckInspection