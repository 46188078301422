import React, { useEffect, useContext, useState } from 'react'
import { requestContext } from '../../context/requestContext'
import ContentHeader from '../../components/contentHeader'
import { formContext } from '../../context/formContext'
import { Link, useNavigate } from 'react-router-dom'
import ListOversight from '../../components/list/oversight'
import FormReport from '../../components/form/report'
import Spinner from '../../components/spinner'
import ExportReport from '../../components/exportReport'
import { getQueryParams } from '../../api'
import { paginateArray } from '../../services/arrayHandless'
import { handleWaring } from '../../services/isValid'
import { handleExportXLS } from '../../components/excel'
import { formatDate, formateHours } from '../../services/dateHandless'
const Report = () => {
	const {
		oversight,
		setReport,
		setHighway,
		getItemFiscalizacaoReport,
		getAllItemHighway,
		getAllItemDealership,
		getAllItemCardinalPoints,
		getAllItemService,
	} = useContext(requestContext)
	const { state, handleForm } = useContext(formContext)
	const [items, seItems] = useState(null)
	const [page, setPage] = useState(1)
	const navigate = useNavigate()
	useEffect(() => {
		resetValues()
		setHighway()
		getAllItemHighway()
		getAllItemDealership()
		getAllItemCardinalPoints()
		getAllItemService(null, 1)
	}, [])
	useEffect(() => {
		const loadPaginate = () => {
			if (Array.isArray(oversight?.data) && page > 0) {
				const paginated = paginateArray(oversight?.data, page, 14)
				const links = Array.from({ length: 14 }).map((_, i) => ({
					label: i === 0 ? 'pagination.previous' : i === 13 ? 'pagination.next' : i,
					active: i === page,
				}))
				const temp_items = {
					links,
					data: paginated,
					total: oversight.total
				}
				return seItems(temp_items)
			}
			return onSubmit()
		}
		loadPaginate()
	}, [oversight, page])
	const {
		conc_cod,
		rod_cod,
		rod_sent_cod,
		serv_cod,
		serv_cat_cod,
		serv_sub_cat_cod,
		serv_cat_prazo_from,
		serv_cat_prazo_to,
		rod_km_ini_from,
		rod_km_ini_to,
		ped_venda_status,
		ped_venda_responsavel,
		gp_cod
	} = state
	const onSubmit = () => {
		window.scrollTo(0, 0)
		const whereFilters = {
			ped_venda_concessionaria: conc_cod || null,
			ped_venda_status: ped_venda_status || null,
			ped_venda_responsavel: ped_venda_responsavel || null,
			ped_venda_rodovia: rod_cod || null,
			ped_venda_date_from: serv_cat_prazo_from || null,
			ped_venda_date_to: serv_cat_prazo_to || null,
			serv_cod: serv_cod || null,
			serv_cat_cod: serv_cat_cod || null,
			serv_sub_cat_cod: serv_sub_cat_cod || null,
			pv_loc_km_ini_from: rod_km_ini_from || null,
			pv_loc_km_ini_to: rod_km_ini_to || null,
			pv_loc_sentido: rod_sent_cod || null,
			gp_cod: gp_cod || null,
		}
		const queryFilter = getQueryParams(whereFilters)
		getItemFiscalizacaoReport(queryFilter)
	}
	const resetValues = () => {
		window.scrollTo(0, 0)
		setReport(null)
		handleForm({
			conc_cod: '',
			rod_cod: '',
			rod_sent_cod: '',
			rod_desc: '',
			conc_nome: '',
			serv_cod: '',
			serv_cat_prazo_from: '',
			serv_cat_prazo_to: '',
			rod_km_ini_from: '',
			rod_km_ini_to: '',
			ped_venda_status: '',
			gp_cod: '',
		})
	}
	const handlePrinter = () => {
		if (oversight?.total >= 1) {
			navigate('/report/print', {
				state: oversight
			})
		}
	}
	const handlePage = (item) => {
		if (!item) {
			return
		}
		const { label } = item
		window.scrollTo(0, 0)
		if (label === 'pagination.previous') {
			if (page === 1 || page < 1) {
				return setPage(1)
			}
			return setPage(page - 1)
		}
		if (label === 'pagination.next') {
			if (page === 14 || page > 14) {
				return setPage(14)
			}
			return setPage(page + 1)
		}
		if (!label) {
			return
		}
		setPage(parseInt(label))
	}
	const RenderLinkFooter = ({ item }) => {
		return (
			<li className="page-item" key={item.label}>
				<Link
					className={`page-link ${item.active && 'text-light bg-info'
						}`}
					to="#"
					onClick={() => handlePage(item)}
				>
					{item.label === 'pagination.previous' ? (
						<i className="fa fa-angle-left"></i>
					) : item.label === 'pagination.next' ? (
						<i className="fa fa-angle-right"></i>
					) : (
						item.label
					)}
				</Link>
			</li>
		)
	}
	const handleExportReport = () => {
		if (!Array.isArray(oversight?.data)) {
			return setInfoRequest(handleWaring('Não há NCs no relatório para serem exportadas'))
		}
		const result = []
		const { data } = oversight
		for (const item of data) {
			const { fiscalizacao } = item
			result.push({
				'ID': item.sisf_sinc_id || item.ped_venda_cod,
				'DATA FISCALIZACAO': formatDate(item.ped_venda_date),
				'HORA FISCALIZACAO': formateHours(item.ped_venda_date),
				'RODOVIA': item.rod_desc,
				'CONCESSIONARIA': item.conc_nome,
				'LOTE': item.lot_conc_desc,
				'CODIGO RODOVIA': item.rod_km,
				'KM INICIAL': item.pv_loc_km_ini,
				'M INICIAL': item.pv_loc_km_init_mts,
				'KM FINAL': item.pv_loc_km_fim,
				'M FINAL': item.pv_loc_km_fim_mts,
				'SENTIDO': item.rod_sent_desc,
				'DATA RETORNO': '',
				'STATUS RETORNO': item.st_desc,
				'TIPO ATIVIDADE': item.serv_desc,
				'GRUPO ATIVIDADE': item.serv_cat_desc,
				'ATIVIDADE': item.pvs_obs,
				'NUMERO NOTIFICACAO': '',
				'DATA ENVIO': item.ped_venda_date,
				'DATA REPARO': item.pvs_prazo_fim,
				'RESPONSAVEL TECNICO': item.name,
				'HORA ENVIO': item.ped_venda_date,
				'LATITUDE': item.pv_loc_lat,
				'LONGITUDE': item.pv_loc_lng,
				'DATA FISC': '',
				'CONCESSIONÁRIA GERAL': '',
			})
			if (fiscalizacao.length) {
				for (const fisc of fiscalizacao) {
					result.push({
						'ID': item.sisf_sinc_id || item.ped_venda_cod,
						'DATA FISCALIZACAO': '',
						'HORA FISCALIZACAO': '',
						'RODOVIA': '',
						'CONCESSIONARIA': '',
						'LOTE': '',
						'CODIGO RODOVIA': '',
						'KM INICIAL': '',
						'M INICIAL': '',
						'KM FINAL': '',
						'M FINAL': '',
						'SENTIDO': '',
						'DATA RETORNO': '',
						'STATUS RETORNO': fisc.fisc_obs,
						'TIPO ATIVIDADE': '',
						'GRUPO ATIVIDADE': '',
						'ATIVIDADE': '',
						'NUMERO NOTIFICACAO': '',
						'DATA ENVIO': '',
						'DATA REPARO': '',
						'RESPONSAVEL TECNICO': fisc.name,
						'HORA ENVIO': '',
						'LATITUDE': fisc.fisc_lat,
						'LONGITUDE': fisc.fisc_lng,
						'DATA FISC': formatDate(fisc.created_at),
						'CONCESSIONÁRIA GERAL': '',
					})
				}

			}
		}
		const headers = Object.keys(result[0]).map(item => ({
			label: item.replace(/_/g, ' '),
			key: item
		}))
		const csvdata = {
			data: result,
			headers: headers,
			fileName: `Relatório-${formatDate(new Date())}`,
		}
		handleExportXLS({ setInfoRequest, data: csvdata.data, headers: csvdata.headers, fileName: csvdata.fileName })
	}
	return (
		<>
			<ContentHeader title={'Relatório de Fiscalizações de Não Conformidade'} />
			<div className="content">
				<div className="card">
					<div className="card-header">
						<h3 className="card-title">Opções de filtros</h3>
					</div>
					<div className="card-body">
						<FormReport onSubmit={() => onSubmit()} />
					</div>
				</div>
				{items?.total >= 1 && (
					<ExportReport
						onSubmit={() => handleExportReport(items)}
						resetValues={() => resetValues()}
						handlePrinter={handlePrinter}
					/>
				)}
				<div className="card">
					{!items?.total >= 1 ? (
						<div className="card-body">
							<Spinner label={'Carregando...'} />
							<div className="text-secondary">Os resultados aparecerão aqui</div>
						</div>
					) : (
						<>
							<div className="card-body">
								<Spinner label={'Carregando...'} />
								<ListOversight data={items} />
							</div>
							<div className="card-footer clearfix">
								<ul className="pagination pagination-sm m-0 float-right">
									{Array.isArray(items?.links) &&
										items.links.map((item) => (
											<RenderLinkFooter item={item} key={item.label} />
										))}
								</ul>
							</div>
						</>
					)}
				</div>
			</div>

		</>
	)
}
export default Report