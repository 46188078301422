import React, { useContext, useEffect } from 'react'
import { requestContext } from '../../context/requestContext'
import ContentHeader from '../../components/contentHeader'
import Spinner from '../../components/spinner'
import SearchHighwayLimit from '../../components/form/searchHighwayLimit'
import { formContext } from '../../context/formContext'
import { useNavigate } from 'react-router-dom'
import { authContext } from '../../context/authContext'
import ListHighwayLimit from '../../components/list/highwayLimit'
import ListHighwayLimitPagination from './pagination'
import FormFilterHighwayLimit from '../../components/form/filters/highwayLimit'
import { namesScreens } from '../../constants'
const ManagerHighwayLimit = () => {
	const { getItemHighwayLimit, limithigway } = useContext(requestContext)
	const { handleForm, state: { conc_cod, rod_cod, gp_cod } } = useContext(formContext)
	const { user } = useContext(authContext)
	const navigate = useNavigate()
	useEffect(() => {
		getItemHighwayLimit({
			id: gp_cod,
			conc_cod,
			rod_cod,
		})
	}, [])
	const handleNew = () => {
		handleForm({
			lim_cod: '',
			lim_gp: '',
			lim_emp: '',
			lim_rod: '',
			lim_conc: '',
			lim_km_ini: '',
			lim_km_fim: '',
			lim_km_ini_mts: '',
			lim_km_fim_mts: '',
			rod_km_ini: '',
			rod_km_ini_mt: '',
			rod_km_fim: '',
			rod_km_fim_mt: '',
			conc_cod: '',
			rod_cod: '',
			emp_cod: '',
			gp_cod: '',
		})
		navigate(`${namesScreens.REGISTERS}${namesScreens.HIGHWAY_LIMITNEW}`)
	}
	return (
		<>
			<ContentHeader title={'Cadastro de Limites'} />
			<div className="content">
				<div className="card sticky-top">
					<div className="card-header">
						<h3 className="card-title d-none d-md-block">
							Limites cadastrados
						</h3>
						<div className="card-tools">
							<SearchHighwayLimit handleNew={handleNew} />
						</div>
					</div>
				</div>
				<div className="card">
					<div className="card-header">
						<h3 className="card-title d-none d-md-block">
							Opções de filtros
						</h3>
					</div>
					<div className="card-body">
						<FormFilterHighwayLimit />
					</div>
				</div>
				<div className="card">
					<Spinner direction={'center'} label="carregando" />
					<ListHighwayLimit data={limithigway} />
					<div className="card-footer clearfix">
						<ListHighwayLimitPagination data={limithigway} />
						{user && user.set_nivel === 1 && (
							<ul className="pagination pagination-sm m-0 float-left">
								<li className="page-item">
									<div className="page-link" onClick={handleNew}>
										Novo <i className="fa fa-plus mr-1 ml-1"></i>
									</div>
								</li>
							</ul>
						)}
					</div>
				</div>
			</div>
		</>
	)
}
export default ManagerHighwayLimit
