import React, { useContext, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import ContentHeader from '../../components/contentHeader'
import SearchService from '../../components/form/searchService'
import ListService from '../../components/list/service'
import Spinner from '../../components/spinner'
import { requestContext } from '../../context/requestContext'
import { authContext } from '../../context/authContext'
import { formContext } from '../../context/formContext'
import { namesScreens } from '../../constants'
const ManagerService = () => {
	const { getItemService, service, getWhereItemService } = useContext(requestContext)
	const { user } = useContext(authContext)
	const { handleForm, state: { serv_desc } } = useContext(formContext)
	const navigate = useNavigate()
	useEffect(() => {
		const fetchItems = () => {
			if (serv_desc) {
				return getWhereItemService(serv_desc)
			}
			getItemService()
		}
		fetchItems()
	}, [])
	const handlePage = (item) => {
		if (!item) {
			return
		}
		const { label } = item
		const { current_page, last_page } = service
		window.scrollTo(0, 0)
		if (label === 'pagination.previous') {
			if (serv_desc) {
				return getWhereItemService(current_page > 1 && `${serv_desc}?page=${current_page - 1}`)
			}
			return getItemService(current_page > 1 && `0?page=${current_page - 1}`)
		}
		if (label === 'pagination.next') {
			if (serv_desc) {
				return getWhereItemService(current_page < last_page && `${serv_desc}?page=${current_page + 1}`)
			}
			return getItemService(current_page < last_page && `0?page=${current_page + 1}`)
		}
		if (!label) {
			if (serv_desc) {
				return getWhereItemService(serv_desc)
			}
			return getItemService()
		}
		if (serv_desc) {
			return getWhereItemService(`${serv_desc}?page=${label}`)
		}
		getItemService(`0?page=${label}`)
	}
	const handleNew = () => {
		handleForm({
			serv_valor: '',
			serv_cat_cod: '',
			serv_desc: '',
			serv_disp: '',
			serv_cat_desc: '',
			serv_cat_disp: '',
			serv_sub_cat_desc: '',
			serv_sub_cat_disp: '',
			pv_servico: '',
		})
		navigate('/service/new')
	}
	return (
		<>
			<ContentHeader title={'Cadastro de serviços'} />
			<div className='content'>
				<div className="card sticky-top">
					<div className="card-header">
						<h3 className="card-title col-8 d-none d-md-block">Serviços cadastradas</h3>
						<div className="card-tools col-md-4 col-sm-12">
							<SearchService handleNew={handleNew} />
						</div>
					</div>
				</div>
				<div className="card">
					<Spinner direction={'center'} label='carregando' />
					<ListService data={service} />
					<div className="card-footer clearfix">
						<ul className="pagination pagination-sm m-0 float-right">
							{Array.isArray(service?.links) && service.links.map((item) => (
								<li className="page-item" key={item.label}>
									<Link className={`page-link ${item.active && 'text-light bg-info'}`} to="#" onClick={() => handlePage(item)}>
										{item.label === 'pagination.previous' ? <i className="fa fa-angle-left"></i> :
											item.label === 'pagination.next' ? <i className="fa fa-angle-right"></i> :
												item.label
										}
									</Link>
								</li>
							))}
						</ul>
						{user?.set_nivel <= 1 && (
							<ul className="pagination pagination-sm m-0 float-left">
								<li className="page-item">
									<Link className="page-link" to={`${namesScreens.REGISTERS}${namesScreens.SERVICENEW}`}>
                                        Novo <i className='fa fa-plus mr-1 ml-1'></i>
									</Link>
								</li>
							</ul>
						)}
					</div>
				</div>
			</div>
		</>
	)
}
export default ManagerService