import React, { useContext, useEffect, useRef, useState } from 'react'
import { formContext } from '../../../context/formContext'
import { styleImage } from '../../../css/globlalStyle'
import { requestContext } from '../../../context/requestContext'
import { authContext } from '../../../context/authContext'
import { handleWaring } from '../../../services/isValid'
import { storageCache } from '../../../services/cacheManager'
import { namesCache } from '../../../constants'
import ButtonOpenMap from '../../map/buttonOpenMap'
const ImageItem = ({ item, src, data, legend, removePhoto, setPhotos }) => {
	const { setBases64 } = useContext(formContext)
	const { deleteItemOversightPhotos, setInfoRequest } = useContext(requestContext)
	const { user } = useContext(authContext)
	const ref_img = useRef()
	const [error, setError] = useState(false)
	const handleRemove = async () => {
		if (removePhoto) {
			return handleRemoveFromOtherComponent()
		}
		if (item) {
			const { pvs_loc_foto_pedidovenda, pvs_loc_foto_cod, pvs_loc_foto_legenda } = item
			const confirm = window.confirm(`Você deseja mesmo remover a foto ${pvs_loc_foto_legenda}`)
			if (confirm) {
				const result = await deleteItemOversightPhotos({
					id: pvs_loc_foto_cod, pvs_cod: pvs_loc_foto_pedidovenda
				})
				if (!result) {
					return
				}
				const newData = data.filter((value) => parseInt(value.pvs_loc_foto_cod) !== parseInt(pvs_loc_foto_cod))
				setPhotos(newData)
			}
		} else {
			const newData = data.filter((value) => value !== src)
			setBases64(newData)
		}
	}
	const handleRemoveFromOtherComponent = () => {
		const newData = data.filter(({ base64 }) => base64 !== src)
		removePhoto(newData)
	}
	const openImage = () => {
		const image = new Image()
		image.src = src
		const w = window.open('')
		w.document.write(image.outerHTML)
	}
	useEffect(() => {
		if (src && ref_img) {
			try {
				ref_img.current.onerror = function () {
					setError(true)
				}
			} catch (error) {
				setError(true)
			}
		}
	}, [ref_img, src])
	if (error) {
		return <div></div>
	}
	return (
		<div className="row">
			<div className="col-12 mb-3">
				<img
					ref={ref_img}
					onClick={openImage}
					className="rounded"
					src={src}
					style={styleImage}
					alt={src}
				/>
			</div>
			<div className='col-md-8 col-sm-12'>
				<p className='text-secondary'>
					{legend}
				</p>
			</div>
			<div className='col-md-4 col-sm-12'>
				<div className='row'>
					<div className='col-6'>
						<div className="btn btn-secondary" onClick={handleRemove}>
							<i className="fa fa-trash"></i>
						</div>
					</div>
					{(item.pvs_loc_foto_lat && item.pvs_loc_foto_lng) ? (
						<div className='col-6'>
							<ButtonOpenMap
								lat={item.pvs_loc_foto_lat}
								lng={item.pvs_loc_foto_lng}
							/>
						</div>
					) : null}
				</div>
			</div>
		</div>
	)
}
export default ImageItem
