import React, { useContext, useEffect, useState } from 'react'
import ContentHeader from '../../components/contentHeader'
import MyMap from '../../components/map'
import RealtimeFilter from '../../components/realtimeFilter'
import { requestContext } from '../../context/requestContext'
import { getQueryParams } from '../../api'
import { convertToTitleCase } from '../../services/stringHandless'
import { formContext } from '../../context/formContext'
import Spinner from '../../components/spinner'
import FormFilterPavementsIndicators from '../../components/form/filters/pavement'
import { handleWaring } from '../../services/isValid'
import InfoWindowMonitoring from '../../components/map/infoWindow/monitoring'
import { renderToString } from 'react-dom/server'
import { filter, filterAndRemoveRepeated, paginateToPostApi } from '../../services/arrayHandless/index.js'
import ListPavementIndicator from '../../components/list/pavementIndicator/index.js'
import PinContent from '../../components/pinContent/index.js'

const ManagerPavement = () => {
	const [filteredRodovia, setFilteredRodovia] = useState([])
	const [filteredConcessionaria, setFilteredConcessionaria] = useState([])
	const [filteredGrupoAtividade, setFilteredGrupoAtividade] = useState([])
	const [filteredAtividade, setFilteredAtividade] = useState([])
	const [filteredStatus, setFilteredStatus] = useState([])
	const [layerOptions, setLayerOptions] = useState(false)
	const [calloutArray, setCalloutArray] = useState(false)
	const { Oversightcount, getCountOversight, report, getItemReportOversightMap } = useContext(requestContext)
	const [arrConcCod, setArrConcCod] = useState([])
	const [arrRodCod, setArrRodCod] = useState([])
	const [arrPvsServCat, setArrPvsServCat] = useState([])
	const [arrPvsServSubCat, setArrPvsServSubCat] = useState([])
	const [arrStatusCod, setArrStatusCod] = useState([])
	const [markersService, setMarkersService] = useState([])
	const [concessionarias, setConcessionarias] = useState([])
	const [statusList, setStatusList] = useState([])
	const [rodovias, setRodovias] = useState([])
	const [grupoAtividade, setGrupoAtividade] = useState([])
	const [atividade, setAtividade] = useState([])
	const [localRefresh, setLocalRefresh] = useState(false)
	const [noData, setNoData] = useState(false)

	const [showMap, setShowMap] = useState(true)
	const [monitoring, setMonitoring] = useState(null)

	const { getAllItemDealership,
		data,
		getItemAllStatusOversight,
		status,
		getAllItemHighway,
		highway,
		getAllItemCategoryService,
		servicecat,
		getAllItemServiceSubCat,
		serviceSubcat,
		getItemAllMonitoringIndicators,
		getItemAllConsolidatedPavementIndex,
		getItemPavementIndicators,
		setInfoRequest,
		cardinal_points
	} = useContext(requestContext)
	const { state: {
		rod_cod,
		conc_cod,
		rod_km_ini_from,
		rod_km_ini_to,
		serv_cat_prazo_from,
		serv_cat_prazo_to
	} } = useContext(formContext)
	const fetchIPcs = async (loc_lipc_cod) => {
		if (!loc_lipc_cod) {
			return []
		}
		const { data: items } = await getItemAllConsolidatedPavementIndex({
			id: loc_lipc_cod
		})
		return items
	}
	const fetchItemsMonitoring = async () => {
		if ((!conc_cod || !serv_cat_prazo_from || !serv_cat_prazo_to) && Array.isArray(cardinal_points?.data)) {
			return setInfoRequest(handleWaring('Você deve preencher as opções de filtro para poder filtrar pelos monitoramentos'))
		}
		const finalData = []
		const sleep = (ms) => {
			return new Promise(resolve => setTimeout(resolve, ms))
		}
		let page = 0
		let totalPages = 2
		const processPages = async () => {
			while (page <= totalPages) {
				const { data: items, last_page } = await getItemAllMonitoringIndicators({
					id: 0,
					rod_cod,
					rod_concessionaria: conc_cod,
					mon_date_from: serv_cat_prazo_from,
					mon_date_to: serv_cat_prazo_to,
					rod_km_ini_from,
					rod_km_ini_to: `${rod_km_ini_to || 0}?page=${page}`,
				})
				if (page === 0) {
					totalPages = last_page
				}
				if (!items?.length) {
					page = totalPages
					break
				}
				for (const item of items) {
					const {
						mon_pav_lat,
						mon_pav_lng,
						...rest
					} = item

					finalData.push({
						lat: mon_pav_lat,
						lng: mon_pav_lng,
						...rest,
					})
				}
				if (page % 2 === 0) {
					await sleep(3000)
				}
				page++
			}
			if (finalData?.length) {
				const loc_lipc_cod = finalData.map(item => item.loc_lipc_cod)
				const pageSize = 100
				const pages = paginateToPostApi(loc_lipc_cod, pageSize)
				const ipcs = []
				for (const page of pages) {
					const response = await fetchIPcs(filterAndRemoveRepeated(page))
					if (response?.length) {
						response.forEach(element => {
							ipcs.push(element)
						})
					}
				}
				if (ipcs.length) {
					const finalDataWithContent = finalData.map(item => {
						const { data: findIPC } = filter(ipcs, 'ipc_loc_lipc_cod', item.loc_lipc_cod)
						if (findIPC) {
							const htmlString = renderToString(<InfoWindowMonitoring state={{ ...item }} ipcs={findIPC} />)
							return {
								lat: item.lat,
								lng: item.lng,
								content: htmlString,
							}
						}
						return {
							lat: item.lng,
							lng: item.lng,
						}
					})
					return setMonitoring(finalDataWithContent)
				}
				return setMonitoring(finalData)
			}
		}
		processPages()
	}
	const position = {
		'lat': -21.9958878,
		'lng': -47.4463856,
		'content': 'Local abertura Não Conformidade'
	}
	useEffect(() => {
		const fetchItemsNavp = () => {
			if (conc_cod && monitoring?.length) {
				getItemPavementIndicators({ id: conc_cod, rod_cod })
			}
		}
		fetchItemsNavp()
	}, [conc_cod, rod_cod, monitoring])
	useEffect(() => {
		getAllItemDealership()
		getItemAllStatusOversight()
		getAllItemHighway()
		getAllItemCategoryService(1)
		filterMap()
		filterCallouts()
	}, [])

	useEffect(() => {
		if (data?.data) {
			setFilteredConcessionaria(data?.data)
			setConcessionarias(data?.data)
		}
	}, [data])

	useEffect(() => {
		if (status?.data) {
			setStatusList(status?.data)
			setFilteredStatus(status?.data)
		}
	}, [status])

	useEffect(() => {
		if (highway?.data) {
			setRodovias(highway?.data)
			setFilteredRodovia(highway?.data)
		}
	}, [highway])

	useEffect(() => {
		if (servicecat?.data) {
			setGrupoAtividade(servicecat?.data)
			setFilteredGrupoAtividade(servicecat?.data)
		}
	}, [servicecat])

	useEffect(() => {
		if (serviceSubcat?.data) {
			setAtividade(serviceSubcat?.data)
			setFilteredAtividade(serviceSubcat?.data)
		}
	}, [serviceSubcat])

	useEffect(() => {
		if (Oversightcount?.data?.count_by_status) {
			setCalloutArray(Oversightcount?.data?.count_by_status)
		}

	}, [Oversightcount])

	useEffect(() => {

		setLayerOptions({
			'radius': 20,
			'positions': []
		})

		if (report?.data !== undefined && report?.data?.length > 0) {

			const finalArr = report?.data?.map(item => (
				{
					pvs_lat: item.pvs_lat,
					pvs_lng: item.pvs_lng,
					conc_nome: item.conc_nome,
					rod_km: item.rod_km,
					serv_cat_desc: item.serv_cat_desc,
					serv_sub_cat_desc: item.serv_sub_cat_desc,
					fotos: item.fotos,
					km_ini: item.pv_loc_km_ini,
					mts_ini: item.pv_loc_km_init_mts,
					km_fim: item.pv_loc_km_fim,
					mts_fim: item.pv_loc_km_fim_mts
				}))
			const tempPositions = finalArr?.map(item =>
			({
				'lat': item.pvs_lat,
				'lon': item.pvs_lng,
				'size': 63,
				'count': 1
			})
			)

			const markersServiceTemp = finalArr?.map(item =>
			({
				'lat': item.pvs_lat,
				'lng': item.pvs_lng,
				'icon_color': '#78ab91',
				'content': renderToString(<PinContent item={item} />)
			})
			)

			setNoData(false)

			setMarkersService(markersServiceTemp)

			setLayerOptions({
				'radius': 20,
				'positions': tempPositions
			})
		} else {
			setNoData(true)
		}
	}, [report])

	const filterCallouts = () => {

		const whereFilters = {
			conc_cod: arrConcCod?.length > 0 ? arrConcCod : 0,
			rod_cod: arrRodCod?.length > 0 ? arrRodCod : 0,
			pvs_servico: [1],
			pvs_servico_cat: arrPvsServCat?.length > 0 ? arrPvsServCat : 0,
			pvs_servico_sub_cat: arrPvsServSubCat?.length > 0 ? arrPvsServSubCat : 0,
			status_cod: arrStatusCod?.length > 0 ? arrStatusCod : 0,
		}
		const finalQuery = getQueryParams(whereFilters)
		getCountOversight(finalQuery)

		filterMap()

	}

	const filterMap = () => {

		setShowMap(false)
		setLocalRefresh(!localRefresh)
		const whereFilters = {
			ped_venda_concessionaria: arrConcCod?.length > 0 ? arrConcCod : 0,
			ped_venda_status: arrStatusCod?.length > 0 ? arrStatusCod : 0,
			ped_venda_responsavel: null,
			ped_venda_rodovia: arrRodCod?.length > 0 ? arrRodCod : 0,
			ped_venda_date_from: null,
			ped_venda_date_to: null,
			pvs_servico: 1,
			pvs_servico_cat: arrPvsServCat?.length > 0 ? arrPvsServCat : 0,
			pvs_servico_sub_cat: arrPvsServSubCat?.length > 0 ? arrPvsServSubCat : 0,
			pv_loc_km_ini_from: null,
			pv_loc_km_ini_to: null,
			pv_loc_sentido: null,
			gp_cod: null,
			isIn: 1
		}
		const queryFilter = getQueryParams(whereFilters)
		getItemReportOversightMap(queryFilter)
	}

	const selectOptions = (type, id) => {

		if (type === 'rod_cod' && arrRodCod !== undefined) {
			if (arrRodCod?.includes(id)) {
				const tempArr = [...arrRodCod]
				const index = arrRodCod?.indexOf(id)
				tempArr?.splice(index, 1)
				setArrRodCod(tempArr)
			} else {
				const tempArr = [...arrRodCod]
				tempArr.push(id)
				setArrRodCod(tempArr)
			}
		} else if (type === 'conc_cod' && arrConcCod !== undefined) {
			if (arrConcCod?.includes(id)) {
				const tempArr = [...arrConcCod]
				const index = arrConcCod?.indexOf(id)
				tempArr?.splice(index, 1)
				setArrConcCod(tempArr)
			} else {
				const tempArr = [...arrConcCod]
				tempArr.push(id)
				setArrConcCod(tempArr)
			}
		} else if (type === 'serv_cat_cod' && arrPvsServCat !== undefined) {
			getAllItemServiceSubCat(id)
			if (arrPvsServCat?.includes(id)) {
				const tempArr = [...arrPvsServCat]
				const index = arrPvsServCat?.indexOf(id)
				tempArr?.splice(index, 1)
				setArrPvsServCat(tempArr)
			} else {
				const tempArr = [...arrPvsServCat]
				tempArr.push(id)
				setArrPvsServCat(tempArr)
			}
		} else if (type === 'serv_sub_cat_cod' && arrPvsServSubCat !== undefined) {
			if (arrPvsServSubCat?.includes(id)) {
				const tempArr = [...arrPvsServSubCat]
				const index = arrPvsServSubCat?.indexOf(id)
				tempArr?.splice(index, 1)
				setArrPvsServSubCat(tempArr)
			} else {
				const tempArr = [...arrPvsServSubCat]
				tempArr.push(id)
				setArrPvsServSubCat(tempArr)
			}
		}
		else {
			if (arrStatusCod?.includes(id)) {
				const tempArr = [...arrStatusCod]
				const index = arrStatusCod?.indexOf(id)
				tempArr?.splice(index, 1)
				setArrStatusCod(tempArr)
			} else {
				const tempArr = [...arrStatusCod]
				tempArr.push(id)
				setArrStatusCod(tempArr)
			}
		}
	}

	return (
		<>
			<ContentHeader title={'Monitoramento de Pavimento'} />
			<div className="content">
				<div className="card card-info">
					<div className='card-header'>
						<div className='card-title'>
							Não Conformidades
						</div>
					</div>
					<div className="card-body row">
						{Array.isArray(calloutArray) && calloutArray?.map(item => (
							<div key={item.st_desc} className={'callout callout-secondary col-lg-3 col-6'} style={{ borderLeftColor: item.st_cor_valor }}>
								<h3 className={'text-bold'}>{item.count}</h3>
								<h6> {item.st_desc}</h6>
							</div>
						))}
					</div>

					<div className="card-body">
						<div className='row'>
							<div className='col-md-9 col-sm-12'>
								{layerOptions?.positions?.length > 0 && showMap
									?
									<MyMap
										zoom={7}
										position={position}
										markersPhotos={markersService}
										layer={layerOptions}
									/>
									:
									<LoadMapScreen status={showMap} funcStatus={setShowMap} noData={noData} />
								}
							</div>
							<div className="col-md-3 col-sm-12">
								<div className="sidebar">
									<nav className="mt-2">
										<div className="accordion" id="accordionExample">
											<div className="card">
												<div className="card-header" id="headingOne">
													<h5 className="mb-0">
														<button className="btn btn-link collapsed text-bold text-secondary" type="button" data-toggle="collapse" data-target='#collapse1' aria-expanded="false" aria-controls="collapseOne">
															NC por Concessionária
														</button>
													</h5>
												</div>
												<div id='collapse1' className="collapse" aria-labelledby="headingOne" data-parent="#accordionExample">
													<RealtimeFilter
														fullArray={concessionarias}
														filteredArray={filteredConcessionaria}
														fieldId={'conc_cod'}
														fieldDescription={'conc_nome'}
														setFilterFunc={setFilteredConcessionaria}
														placeholder={'Concessionária'}
														funcOnSelect={selectOptions}
													/>
												</div>
											</div>

											<div className="card">
												<div className="card-header" id="headingOne">
													<h5 className="mb-0">
														<button className="btn btn-link collapsed text-bold text-secondary" type="button" data-toggle="collapse" data-target='#collapse3' aria-expanded="false" aria-controls="collapseOne">
															NC por Grupo de Atividade
														</button>
													</h5>
												</div>
												<div id='collapse3' className="collapse" aria-labelledby="headingOne" data-parent="#accordionExample">
													<RealtimeFilter
														fullArray={grupoAtividade}
														filteredArray={filteredGrupoAtividade}
														fieldId={'serv_cat_cod'}
														fieldDescription={'serv_cat_desc'}
														setFilterFunc={setFilteredGrupoAtividade}
														placeholder={'Grupo Atividade'}
														funcOnSelect={selectOptions}
													/>
												</div>
											</div>
											<div className="card">
												<div className="card-header" id="headingOne">
													<h5 className="mb-0">
														<button className="btn btn-link collapsed text-bold text-secondary" type="button" data-toggle="collapse" data-target='#collapse4' aria-expanded="false" aria-controls="collapseOne">
															NC por Atividade
														</button>
													</h5>
												</div>
												<div id='collapse4' className="collapse" aria-labelledby="headingOne" data-parent="#accordionExample">
													<RealtimeFilter
														fullArray={atividade}
														filteredArray={filteredAtividade}
														fieldId={'serv_sub_cat_cod'}
														fieldDescription={'serv_sub_cat_desc'}
														setFilterFunc={setFilteredAtividade}
														placeholder={'Atividade'}
														funcOnSelect={selectOptions}
													/>
												</div>
											</div>
											<div className="card">
												<div className="card-header" id="headingOne">
													<h5 className="mb-0">
														<button className="btn btn-link collapsed text-bold text-secondary" type="button" data-toggle="collapse" data-target='#collapse5' aria-expanded="false" aria-controls="collapseOne">
															NC por Status
														</button>
													</h5>
												</div>
												<div id='collapse5' className="collapse" aria-labelledby="headingOne" data-parent="#accordionExample">
													<RealtimeFilter
														fullArray={statusList}
														filteredArray={filteredStatus}
														fieldId={'st_cod'}
														fieldDescription={'st_desc'}
														setFilterFunc={setFilteredStatus}
														placeholder={'Status'}
														funcOnSelect={selectOptions}
													/>
												</div>
											</div>
											<div className="card">
												<div className="card-header" id="headingOne">
													<h5 className="mb-0">
														<button className="btn btn-link collapsed text-bold text-secondary" type="button" data-toggle="collapse" data-target='#collapse6' aria-expanded="false" aria-controls="collapseOne">
															NC por Rodovia
														</button>
													</h5>
												</div>
												<div id='collapse6' className="collapse" aria-labelledby="headingOne" data-parent="#accordionExample">
													<RealtimeFilter
														fullArray={rodovias}
														filteredArray={filteredRodovia}
														fieldId={'rod_cod'}
														fieldDescription={'rod_desc'}
														setFilterFunc={setFilteredRodovia}
														placeholder={'Rodovia'}
														funcOnSelect={selectOptions}
													/>
												</div>
											</div>
											<div className='d-grid gap-2'>
												<button
													style={{ width: '100%' }}
													className='btn btn-info'
													onClick={filterCallouts}
												>
													<i className="fa fa-filter" aria-hidden="true"></i> Filtrar
												</button>
											</div>

										</div>
									</nav>

								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="card card-info">
					<div className='card-header'>
						<div className='card-title'>
							{convertToTitleCase('LEVANTAMENTO DE ÍNDICES DO PAVIMENTO CONSOLIDADO')}
						</div>
					</div>
					<div className='card-body'>
						<FormFilterPavementsIndicators filterByKm label={'monitoramento'}
							onSubmit={fetchItemsMonitoring} />
					</div>
					<div className='card-body'>
						<Spinner label={'Carregando...'} direction={'center'} />
						<div className='row'>
							<div className='col-md-9 col-sm-12'>
								{monitoring?.length ? (
									<MyMap
										zoom={7}
										position={position}
										markersPhotos={monitoring}
										polyline
									/>
								) : (
									''
								)}
							</div>
							{monitoring?.length && (
								<div className='col-md-3 col-sm-12'>
									<ListPavementIndicator items={cardinal_points?.data} simpleList />
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

const LoadMapScreen = ({ status, funcStatus, noData }) => {
	useEffect(() => {
		setTimeout(() => {
			funcStatus(!status)
		}, '1000')
	})
	return (
		<div style={{ height: '100%', color: 'grey' }} className="col-12 d-flex justify-content-center align-items-center">
			{!noData && <div className="spinner-border spinner-border-sm" role="status" />}
			<p className='m-0 p-0 ml-2'>{noData ? 'Sem dados para os parâmetros selecionados' : 'Carregando...'}</p>

		</div>
	)
}
export default ManagerPavement