import React from 'react'
const ExportReport = ({ onSubmit }) => {
	return (
		<div className='row'>
			<div className='col-6'>
				{onSubmit && (
					<button className="btn btn-success mr-3" onClick={onSubmit}>
						Baixar Relatório <i className="fa fa-table"></i>
					</button>
				)}
			</div>
		</div>)
}
export default ExportReport