import React, { useEffect, useState } from 'react'
import { searchSomeWordInString } from '../../services/stringHandless'
import { allowedImageExtensions, allowedMoviesExtensions, iconsAttachments, namesScreens } from '../../constants'
import { getExt } from '../../services/files'
import { find as Find } from '../../services/arrayHandless'
import { useLocation } from 'react-router-dom'
const RenderIcon = ({ path }) => {
	const [view, setView] = useState(0)
	const [find, setFind] = useState(null)
	const [ext, setExt] = useState(null)
	const [showPreview, setShowPreview] = useState(false)
	const { location } = useLocation()
	useEffect(() => {
		const controllDisabledInputs = () => {
			if (!location?.pathname) {
				return
			}
			const { pathname } = location
			if (pathname === namesScreens.REPORT_CONSTRUCTION_PLAN) {
				return setShowPreview(true)
			}
			return setShowPreview(false)
		}
		controllDisabledInputs()
	}, [location])
	useEffect(() => {
		const getIcon = () => {
			if (!path) {
				return
			}
			const ext = getExt(path)
			setExt(ext)
			iconsAttachments.forEach(item => {
				item.ext.forEach(ex => {
					if (searchSomeWordInString(ex, [ext])) {
						setFind(item)
					}
				})
			})
		}
		getIcon()
	}, [path])
	const handleView = () => {
		const ext = getExt(path)
		const exts = allowedImageExtensions.map(item => ({
			type: item
		}))
		const { data: findView } = Find(exts, 'type', ext)
		if (!findView) {
			return window.open(path, 'blank').focus()
		}
		setView(!view)
	}

	if (!find) {
		<div className="text-center">
			<i className={'fa fa-file fa-10x'}></i>
		</div>
	}
	const RenderPreview = () => {
		if (allowedMoviesExtensions.includes(ext)) {
			return (
				<video controls width={'100%'} height={750} src={path} autoPlay></video>
			)
		}
		return (<img src={path} style={{ width: '16rem' }} alt="" />)
	}
	if (showPreview) {
		return (
			<RenderPreview />
		)
	}
	return (
		<div className={`text-center bg-${find?.color} p-3`} title="Visualizar" onClick={handleView} style={{
			cursor: 'pointer'
		}}>
			{view && find ? (
				<RenderPreview />
			) : (
				<i className={`${find?.name} fa-10x`}></i>
			)
			}
		</div >
	)
}
export default RenderIcon