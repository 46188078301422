import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import ContentHeader from '../../components/contentHeader'
import { authContext } from '../../context/authContext'
import { requestContext } from '../../context/requestContext'
import { storageCache } from '../../services/cacheManager'
import { ChartDashBoardDealership, ChartDashBoardHighway, ChartDashBoardLocate, ChartDashBoardService } from '../../components/charts'
import { handleWaring } from '../../services/isValid'
import { FLAVOR } from '../../config/flavor'
import { ENV } from '../../config'
import { convertToTitleCase } from '../../services/stringHandless'
import TabsChardDashboard from '../../components/tabs/chartDashboard'
import { namesCache, namesScreens } from '../../constants'
import { overflowY } from './styles'
import { randomNumber } from '../../services/randomId'
const Dashboard = () => {
	const tabclassName = 'fade active show'
	const [byDealership, setByDealership] = useState(tabclassName)
	const [byHighway, setByHighway] = useState('')
	const [byLocation, setByLocation] = useState('')
	const [byActive, setByActive] = useState('')
	const {
		Oversightcount,
		getCountOversight,
		googleMapsApiKey,
		getItemApiKey,
		getItemStatusOversight,
		getAllItemConfig,
		setLoading,
		setInfoRequest,
	} = useContext(requestContext)
	const [itemsChart, setItemsChart] = useState(null)
	const { user } = useContext(authContext)
	useEffect(() => {
		const body = document.getElementById('root')
		document.title = `${FLAVOR} - ${convertToTitleCase(ENV)}`
		body.className = 'wrapper'
		setLoading(false)
		storageCache(namesCache.VERSION)
	}, [])
	useEffect(() => {
		getAllItemConfig()
	}, [])
	useEffect(() => {
		const loadStatusOversight = () => {
			getItemStatusOversight()
		}
		if (user?.set_nivel === 0) {
			loadStatusOversight()
		}
	}, [user])
	useEffect(() => {
		if (!googleMapsApiKey) {
			getItemApiKey()
		}
	}, [googleMapsApiKey])
	useEffect(() => {
		getCountOversight()
	}, [])
	useEffect(() => {
		if (Oversightcount) {
			try {
				const { data: {
					count_by_dealership,
					count_by_status,
					count_by_highway,
					count_by_locale,
					count_by_service,
				} } = Oversightcount
				setItemsChart({
					count_by_dealership: count_by_dealership?.length && count_by_dealership,
					count_by_status: count_by_status?.length && count_by_status,
					count_by_highway: count_by_highway?.length && count_by_highway,
					count_by_locale: count_by_locale?.length && count_by_locale,
					count_by_service: count_by_service?.length && count_by_service,
				})
			} catch (error) {
				setInfoRequest(handleWaring(String(error)))
			}
		}
	}, [Oversightcount])
	return (
		<>
			<ContentHeader title="" />
			<div className="content-fluid">
				<div className="content p-3">
					{Array.isArray(itemsChart?.count_by_status) && (
						<div className="card">
							<div className="card-header">
								<div className="card-title">
									Não Conformidade por situação
								</div>
							</div>
							<div className="card-body">
								<div className="row">
									{itemsChart?.count_by_status.map(item =>
									(
										<div key={item.st_desc} className={'callout callout-secondary col-lg-3 col-6'} style={{ borderLeftColor: item.st_cor_valor }}>
											<h3 className={'text-bold'}>{item.count}</h3>
											<h6> {item.st_desc}</h6>
										</div>
									))}
								</div>
							</div>
							<div className="card-footer">
								<Link to={namesScreens.OVERSIGHT} className="text-dark">Ver essas ocorrências</Link>
							</div>
						</div>
					)}
					<div className='card'>
						<div className='card-header'>
							<div className='row'>
								<div className='col-md-3 col-sm-12'>
									<div className='card-title'>
										Gŕaficos de Não conformidades
									</div>
								</div>
								<div className='col-md-9 col-sm-12'>
									<TabsChardDashboard
										setByDealership={setByDealership}
										setByHighway={setByHighway}
										setByLocation={setByLocation}
										setByActive={setByActive}
										tabclassName={tabclassName}
									/>
								</div>
							</div>
						</div>
						<div className='card-body'>
							<div className="bd-example bd-example-tabs mt-3">
								<div className="tab-content" id="chartTab">
									<div className={`tab-pane fade ${byDealership}`} id="dealership" role="tabpanel" aria-labelledby="dealership-tab">
										{itemsChart?.count_by_dealership && (
											<ChartDashBoardDealership data={itemsChart?.count_by_dealership}
												field_label={'conc_nome'} />
										)}
									</div>
									<div className={`tab-pane fade ${byHighway}`} id="highway" role="tabpanel" aria-labelledby="highway-tab">
										{itemsChart?.count_by_highway && (
											<div className='row'>
												<div className='col-md-8 col-sm-12'>
													<ChartDashBoardHighway data={itemsChart?.count_by_highway}
														field_label={'rod_desc'} />
												</div>
												<div className='card col-md-4 col-sm-12' style={overflowY}>
													<div className='card-body'>
														{itemsChart.count_by_highway.map(item => (
															<div className='col-12' key={randomNumber()}>
																<span className='text-secondary mr-2'>
																	{item.rod_desc}:
																</span>
																{item.count} {'Nc\'s'}
															</div>
														))}
													</div>
												</div>
											</div>
										)}
									</div>
									<div style={overflowY} className={`tab-pane fade ${byLocation}`} id="location" role="tabpanel" aria-labelledby="location-tab">
										{itemsChart?.count_by_locale && (
											<ChartDashBoardLocate data={itemsChart?.count_by_locale}
												field_label={'pv_loc_km_ini.rod_km'} />
										)}
									</div>
									<div className={`tab-pane fade ${byActive}`} id="active" role="tabpanel" aria-labelledby="active-tab">
										{itemsChart?.count_by_service && (
											<div className='row'>
												<div className='col-md-8 col-sm-12'>
													<ChartDashBoardService data={itemsChart?.count_by_service}
														field_label={'pvs_obs'} />
												</div>
												<div className='card col-md-4 col-sm-12' style={overflowY}>
													<div className='card-body'>
														{itemsChart.count_by_service.map(item => (
															item.pvs_obs ?
																<div className='col-12' key={randomNumber()}>
																	<span className='text-secondary mr-2'>
																		{item.pvs_obs}:
																	</span>
																	{item.count} {'Nc\'s'}
																</div> : ''
														))}
													</div>
												</div>
											</div>
										)}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}
export default Dashboard
