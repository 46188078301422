import React from "react";
const RenderActive = ({ item }) => {
    return (
        <>
            {
                item.serv_desc && (
                    <div className='col-md-4 mb-3 col-sm-12'>
                        <span className='text-secondary mr-2'>
                            Tipo Atividade:
                        </span>
                        {item.serv_desc}
                    </div>
                )
            }
            {
                item.serv_cat_desc && (
                    <div className='col-md-4 mb-3 col-sm-12'>
                        <span className='text-secondary mr-2'>
                            Grupo Atividade:
                        </span>
                        {item.serv_cat_desc}
                    </div>
                )
            }
            {
                item.serv_sub_cat_desc && (
                    <div className='col-md-4 mb-3 col-sm-12'>
                        <span className='text-secondary mr-2'>
                            Atividade:
                        </span>
                        {item.serv_sub_cat_desc}
                    </div>
                )
            }
        </>
    )
}
export default RenderActive