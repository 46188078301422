import React, { useContext } from "react";
import { requestContext } from "../../../context/requestContext";
import { handleWaring } from "../../../services/isValid";
import { storageCache } from "../../../services/cacheManager";
import { namesCache } from "../../../constants";
const ButtonOpenMap = ({ lat, lng }) => {
    const { setInfoRequest } = useContext(requestContext)
    const handleOpenLink = () => {
        if (lat && lng) {
            return window.open(`https://www.google.com/maps/place/${lat},${lng}`, '_blank').focus()
        }
        storageCache(namesCache.LASS_MESSAGE)
        return setInfoRequest(handleWaring('Não contêm A Latitude e Longitude'))
    }
    return (
        <button className="btn btn-info" onClick={handleOpenLink}>
            <i className="fa fa-map"></i>
        </button>
    )
}
export default ButtonOpenMap