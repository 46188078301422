import React, { useContext, useEffect, useRef, useState } from 'react'
import { formContext } from '../../../context/formContext'
import { requestContext } from '../../../context/requestContext'
import { isValid, handleWaring } from '../../../services/isValid'
const ListAneDesc = ({ item, data, key }) => {
	const { handleForm, state } = useContext(formContext)
	const { setInfoRequest, postItemDealershipContractAttachment,
		deleteItemDealershipContractAttachment } = useContext(requestContext)
	const [wasEdited, setWasEdited] = useState(false)
	const [edit, setEdit] = useState(false)
	const ane_desc_infla = useRef()
	const [newValue, setNewValue] = useState({
		ane_desc_infla: '',
		ane_clausula_infla: ''
	})
	useEffect(() => {
		setNewValue({
			ane_desc_infla: item.ane_desc_infla,
			ane_clausula_infla: item.ane_clausula_infla
		})
	}, [item])
	useEffect(() => {
		setWasEdited(item.ane_desc_infla?.length !== newValue.ane_desc_infla?.length || item.ane_clausula_infla?.length !== newValue.ane_clausula_infla?.length)
	}, [item.ane_clausula_infla?.length, item.ane_desc_infla?.length, newValue])
	useEffect(() => {
		if (edit) {
			ane_desc_infla.current.focus()
		}
	}, [edit])
	const {
		cont_ncontrato
	} = state
	const handleRemove = () => {
		const itemFilter = data.filter(value => value.ane_clausula_infla !== item.ane_clausula_infla)
		handleForm({
			ane_desc: itemFilter
		})
	}
	const handleEdit = () => {
		setEdit(!edit)
	}
	const onSubmit = () => {
		if (wasEdited) {
			const confirm = window.confirm(`Deseja mesmo alterar o anexo e a clausula do contrato ${cont_ncontrato} ?`)
			if (confirm) {
				if (!isValid(newValue)) {
					return setInfoRequest(handleWaring())
				}
				setWasEdited(false)
				setEdit(false)
				return postItemDealershipContractAttachment({
					...newValue,
					ane_cod: item.ane_cod,
					ane_contrato: item.ane_contrato,
					id: item.ane_cod,
				})
			} else {
				setNewValue({
					ane_desc_infla: item.ane_desc_infla,
					ane_clausula_infla: item.ane_clausula_infla
				})
				setEdit(false)
			}
		}
	}
	const handleDelete = () => {
		const confirm = window.confirm(`Deseja mesmo remover a clausula ${item.ane_clausula_infla} contida no Anexo ${item.ane_desc_infla}`)
		if (confirm) {
			handleRemove()
			return deleteItemDealershipContractAttachment({
				id: item.ane_cod
			})
		}
	}
	const onChange = ({ target }) => {
		const {
			value,
			name
		} = target
		if (value === '') {
			return setNewValue({
				[name]: item[name]
			})
		}
		setNewValue({
			[name]: value
		})
	}
	if (!item) {
		return <div className='col-12'>Não há item para ser exibido</div>
	}
	return (<div className='row mb-3' key={key}>
		<div className='col-md-10 col-sm-12 mb-3'>
			<div className='row'>
				<div className={edit ? 'col-12' : 'col-md-2 col-sm-12 mb-2'}>
					{!edit ?
						item.ane_desc_infla
						:
						<input type='text' onChange={onChange}
							className="form-control"
							placeholder="Anexos referente ao contrato da notificação"
							ref={ane_desc_infla}
							name='ane_desc_infla' value={newValue.ane_desc_infla} />
					}
				</div>
				<div className='col-md-4 col-sm-8'>
					{item.cont_pen_infra ? item.cont_pen_infra : 'Não há penalidade definida'}
				</div>
				<div className='col-md-6 col-sm-12 mb-2'>
					{!edit ?
						item.ane_clausula_infla
						:
						<input type='text' onChange={onChange}
							className="form-control"
							placeholder="Clausula do Anexos referente ao contrato da notificação"
							name='ane_clausula_infla' value={newValue.ane_clausula_infla}
						/>
					}
				</div>
			</div>
		</div>
		<div className='col-md-2 col-sm-12'>
			{item.ane_cod ?
				wasEdited ?
					<div className='btn btn-info btn-sm mr-2' onClick={onSubmit}>
						<i className='fa fa-save'></i>
					</div>
					:
					<div className='btn btn-info btn-sm mr-2' onClick={handleEdit}>
						<i className='fa fa-edit'></i>
					</div>
				: ''}
			{!item.ane_cod ?
				<div className='btn btn-secondary btn-sm' onClick={handleRemove}>
					<i className='fa fa-trash'></i>
				</div>
				:
				<div className='btn btn-secondary btn-sm' onClick={handleDelete}>
					<i className='fa fa-trash'></i>
				</div>
			}
		</div>
		<div className='col-12'>
			<hr />
		</div>
	</div>)
}
export default ListAneDesc