import React, { useEffect, useState } from 'react'
const ContentHeader = ({ title }) => {
	const [path, setPath] = useState('')
	useEffect(() => {
		setPath(title && title)
	}, [title])
	return (
		<div className='content-header'>
			<div className='container-fluid'>
				<div className='row mb-2'>
					<div className='col-12'>
						<h1 className='m-0'>{path}</h1>
					</div>
				</div>
			</div>
		</div>
	)
}
export default ContentHeader