import React, { useContext } from 'react'
import { formContext } from '../../../context/formContext'
const FormStatus = () => {
	const { state, onChange } = useContext(formContext)
	const {
		st_ativo,
		st_desc
	} = state
	return (
		<div className="row">
			<div className="form-group col-md-6 col-sm-12">
				<label>Descrição:</label>
				<div className="input-group">
					<input type="text"
						onChange={onChange}
						name='st_desc' value={st_desc}
						className={`form-control ${!st_desc && 'is-invalid'}`}
						placeholder='Descrição do status' />
				</div>
			</div>
			<div className="form-group col-md-6 col-sm-6">
				<label>Situação:</label>
				<div className="input-group">
					<select name="st_ativo" value={st_ativo}
						className={`form-control ${!st_ativo && 'is-invalid'}`}
						onChange={onChange}
					>
						<option value={0}>
                            Inativo
						</option>
						<option value={1}>
                            Ativo
						</option>
					</select>
				</div>
			</div>
		</div>
	)
}
export default FormStatus