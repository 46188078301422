import React, { useContext, useEffect, useRef, useState } from 'react'
import { formContext } from '../../../context/formContext'
import { requestContext } from '../../../context/requestContext'
import { handleWaring, handleSuccess, isValid } from '../../../services/isValid'
import SelectServiceTimeLine from '../selectServiceTimeLine'
import Dealership from '../dealership'
import SelectHighway from '../selectHighway'
import HighwayExtension from '../highwayExtension'
import SelectCardinalPoints from '../selectCardinalPoints'
import { scrollToElement } from '../../../services/scroll'
import { filterAndRemove, find } from '../../../services/arrayHandless'
import { authContext } from '../../../context/authContext'
const FormTimeLine = ({ items, setItems }) => {
	const { user } = useContext(authContext)
	const { state, onChange, handleForm } = useContext(formContext)
	const { setInfoRequest } = useContext(requestContext)
	const [valuesFixed, setValuesFixed] = useState(null)
	const [fixedHigwayDealership, seFixedHigwayDealership] = useState(false)
	const [fixedHigwayExtension, seFiexedHigwayExtension] = useState(false)
	const [fixedServiceTimeLine, seFiexedServiceTimeLine] = useState(false)
	const [fixedDeadline, seFiexedDeadline] = useState(false)
	const [disableCheckBoxFixed, setDisableCheckBoxFixed] = useState(false)
	const timelineform = useRef()
	const {
		ped_venda_cod,
		ped_venda_date_ini,
		ped_venda_date_fim,
		serv_cod,
		serv_cat_cod,
		serv_sub_cat_cod,
		serv_sub_cat_desc,
		serv_desc,
		serv_cat_desc,
		pv_loc_obs,
		rod_desc,
		rod_km_ini,
		rod_km_ini_mt,
		rod_km_fim,
		rod_km_fim_mt,
		rod_sent_desc,
		rod_sent_cod,
		pvs_servico,
		pvs_servico_cat,
		pvs_servico_sub_cat,
		pvs_obs,
		conc_cod,
		rod_cod,
		conc_nome,
		pvs_qtd,
		pvs_cod,
		fisc_obs,
	} = state
	useEffect(() => {
		const loadCheckBox = () => {
			if (ped_venda_cod) {
				seFixedHigwayDealership(true)
				setDisableCheckBoxFixed(true)
				seFiexedHigwayExtension(true)
			} else {
				seFixedHigwayDealership(false)
				setDisableCheckBoxFixed(false)
				seFiexedHigwayExtension(false)
			}
			if (user?.set_nivel === 3) {
				seFixedHigwayDealership(true)
				setDisableCheckBoxFixed(true)
			}
		}
		loadCheckBox()
	}, [ped_venda_cod])
	useEffect(() => {
		handleForm({
			pvs_servico: serv_cod,
			pvs_servico_cat: serv_cat_cod,
			pvs_servico_sub_cat: serv_sub_cat_cod,
			pvs_obs: serv_sub_cat_desc,
		})
	}, [serv_cod,
		serv_cat_cod,
		serv_sub_cat_cod,
		serv_sub_cat_desc,])
	const onBlurPrazo = ({ target }) => {
		const { value, name } = target
		if (!items?.length) {
			return setItems(null)
		}
		if (name === 'ped_venda_date_ini') {
			const newItems = items.map(item => {
				return {
					...item,
					pvs_prazo_ini: `${value} 11:59:00`
				}
			})
			setItems(newItems)
		}
		if (name === 'ped_venda_date_fim') {
			const newItems = items.map(item => {
				return {
					...item,
					pvs_prazo_fim: `${value} 11:59:00`
				}
			})
			setItems(newItems)
		}
	}
	const onClearValues = () => {
		scrollToElement(timelineform.current)
		if (!fixedHigwayDealership) {
			handleForm({
				rod_desc: '',
				rod_concessionaria: '',
				conc_cod: '',
				conc_nome: '',
				rod_sent_desc: '',
				rod_sent_cod: '',
			})
		}
		if (!fixedHigwayExtension) {
			handleForm({
				rod_km_ini: '',
				rod_km_ini_mt: '',
				rod_km_fim: '',
				rod_km_fim_mt: '',
			})
		}
		if (!fixedServiceTimeLine) {
			handleForm({
				serv_cod: '',
				serv_cat_cod: '',
				serv_sub_cat_cod: '',
				serv_sub_cat_desc: '',
			})
		}
		if (!fixedDeadline) {
			handleForm({
				ped_venda_date_ini: '',
				ped_venda_date_fim: '',
			})
		}
		handleForm({
			ped_venda_cod: '',
			pv_loc_obs: '',
			pvs_servico: '',
			pvs_servico_cat: '',
			pvs_servico_sub_cat: '',
			pvs_obs: '',
			pvs_qtd: '',
			pvs_cod: '',
			pvs_pedidovenda: '',
		})
	}
	const handleAddItem = () => {
		const validItem = {
			rod_km_ini,
			rod_km_ini_mt,
			rod_km_fim,
			rod_km_fim_mt,
			pvs_servico,
			pvs_servico_cat,
			pvs_servico_sub_cat,
			pvs_obs,
			pv_loc_obs,
			rod_sent_cod,
			pvs_qtd
		}
		const isvalid = isValid(validItem)
		if (!isvalid) {
			return setInfoRequest(handleWaring())
		}
		const month = new Date(ped_venda_date_ini).toLocaleString('default', { month: 'long' })
		const pedido_venda_local = {
			pv_loc_km_ini: rod_km_ini,
			pv_loc_km_init_mts: rod_km_ini_mt,
			pv_loc_km_fim: rod_km_fim,
			pv_loc_km_fim_mts: rod_km_fim_mt,
		}
		const item = {
			conc_nome,
			conc_cod,
			rod_cod,
			month,
			pvs_cod,
			rod_desc,
			pvs_servico: serv_cod,
			serv_desc,
			serv_cat_desc,
			pvs_servico_cat: serv_cat_cod,
			pvs_servico_sub_cat: serv_sub_cat_cod,
			pvs_obs: serv_sub_cat_desc,
			pvs_prazo_ini: `${ped_venda_date_ini} 11:59:00`,
			pvs_prazo_fim: `${ped_venda_date_fim} 11:59:00`,
			pvs_lat: 0,
			pvs_lng: 0,
			pvs_qtd: pvs_qtd || 1,
			pedido_venda_local: {
				...pedido_venda_local,
				pv_loc_lat: 0,
				pv_loc_lng: 0,
				pv_loc_obs: pv_loc_obs,
				rod_sent_desc,
				pv_loc_sentido: rod_sent_cod,
			},
			pvs_servico_foto: [],
		}
		if (Array.isArray(items)) {
			const { data } = find(items, 'pvs_servico_sub_cat', serv_sub_cat_cod)
			if (data) {
				if (
					data.pedido_venda_local.pv_loc_km_ini === pedido_venda_local.pv_loc_km_ini &&
					data.pedido_venda_local.pv_loc_km_fim === pedido_venda_local.pv_loc_km_fim &&
					data.pedido_venda_local.pv_loc_km_init_mts === pedido_venda_local.pv_loc_km_init_mts &&
					data.pedido_venda_local.pv_loc_km_fim_mts === pedido_venda_local.pv_loc_km_fim_mts &&
					data.rod_cod === rod_cod &&
					data.pvs_prazo_ini === `${ped_venda_date_ini} 11:59:00` &&
					data.pvs_prazo_fim === `${ped_venda_date_fim} 11:59:00` &&
					data.pedido_venda_local.pv_loc_sentido === rod_sent_cod
				) {
					onClearValues()
					return setInfoRequest(handleWaring(`A atividade ${serv_sub_cat_desc} já foi adicionada no trecho KM inicial: ${data.pedido_venda_local.pv_loc_km_ini}, ${data.pedido_venda_local.pv_loc_km_init_mts} KM final: ${data.pedido_venda_local.pv_loc_km_fim}, ${data.pedido_venda_local.pv_loc_km_fim_mts}`))
				}
			}
			if (pvs_cod) {
				const findAndRemoveItem = filterAndRemove(items, 'pvs_cod', pvs_cod)
				setItems([...findAndRemoveItem.data, { ...item }])
			} else {
				setItems([...items, { ...item }])
			}
			onClearValues()
			return setInfoRequest(handleSuccess(`A atividade ${serv_sub_cat_desc} ${pvs_cod ? 'atualizada' : 'adicionada'} no trecho KM inicial: ${data.pedido_venda_local.pv_loc_km_ini}, ${data.pedido_venda_local.pv_loc_km_init_mts} KM final: ${data.pedido_venda_local.pv_loc_km_fim}, ${data.pedido_venda_local.pv_loc_km_fim_mts}`))
		}
		setItems([{ ...item }])
		onClearValues()
		return setInfoRequest(handleSuccess(`A atividade ${serv_sub_cat_desc} ${pvs_cod ? 'atualizada' : 'adicionada'}`))
	}
	const handleCancel = () => {
		onClearValues()
	}
	return (
		<div className="row">
			<div className="col-12">
				<div className="form-group">
					<div className="custom-control custom-switch custom-switch-off-secondary custom-switch-on-info">
						<input type="checkbox" className="custom-control-input" id="fixedHigwayDealership"
							checked={fixedHigwayDealership} onChange={() => seFixedHigwayDealership(!fixedHigwayDealership)}
							disabled={disableCheckBoxFixed}
						/>
						<label className="custom-control-label" htmlFor="fixedHigwayDealership">Fixar Concessionária e rodovia</label>
					</div>
				</div>
			</div>
			<Dealership />
			{conc_cod && (
				<SelectHighway setValuesFixed={setValuesFixed} />
			)}
			{rod_cod && (
				<div className="col-12">
					<div className="form-group">
						<div className="custom-control custom-switch custom-switch-off-secondary custom-switch-on-info">
							<input type="checkbox" className="custom-control-input" id="fiexedHigwayExtension"
								checked={fixedHigwayExtension} onChange={() => seFiexedHigwayExtension(!fixedHigwayExtension)} />
							<label className="custom-control-label" htmlFor="fiexedHigwayExtension">Fixar Trecho da rodovia</label>
						</div>
					</div>
				</div>
			)}
			{rod_cod && (
				<HighwayExtension valuesFixed={valuesFixed} />
			)}
			{rod_cod && (
				<SelectCardinalPoints />
			)}
			{rod_cod && (
				<div className="form-group col-md-6 col-sm-12">
					<label>Observações sobre o trecho preenchido:</label>
					<div className="input-group">
						<input type="text" onChange={onChange}
							className={`form-control ${!pv_loc_obs && 'is-invalid'}`}
							name='pv_loc_obs' value={pv_loc_obs}
							placeholder='Informações adicionais sobre o trecho' />
					</div>
				</div>
			)}
			<div className="col-12">
				<div className="form-group">
					<div className="custom-control custom-switch custom-switch-off-secondary custom-switch-on-info">
						<input type="checkbox" className="custom-control-input" id="fixedDeadline"
							checked={fixedDeadline} onChange={() => seFiexedDeadline(!fixedDeadline)}
						/>
						<label className="custom-control-label" htmlFor="fixedDeadline">Fixar Periodo</label>
					</div>
				</div>
			</div>
			<div className="form-group col-md-6 col-sm-12">
				<label>Data de inicio:</label>
				<div className="input-group">
					<input type="date" onChange={onChange}
						className={`form-control ${!ped_venda_date_ini && 'is-invalid'}`}
						name='ped_venda_date_ini' value={ped_venda_date_ini}
						onBlur={onBlurPrazo}
						placeholder='Quando inicia o cronograma' />
				</div>
			</div>
			<div className="form-group col-md-6 col-sm-12">
				<label>Data de termino:</label>
				<div className="input-group">
					<input type="date" onChange={onChange}
						className={`form-control ${!ped_venda_date_fim && 'is-invalid'}`}
						name='ped_venda_date_fim' value={ped_venda_date_fim}
						onBlur={onBlurPrazo}
						placeholder='Quando inicia o cronograma' />
				</div>
			</div>
			{ped_venda_cod && (
				<div className="form-group col-12">
					<label>Explique porque você está fazendo essa alteração:</label>
					<textarea type="text"
						className={`form-control ${!fisc_obs && 'is-invalid'}`}
						onChange={onChange}
						minLength={5}
						maxLength={100}
						name='fisc_obs' value={fisc_obs}
						placeholder='Descreva brevemente o porque você está fazendo alterações no cronograma' />
				</div>
			)}
			<div className="form-group col-12" ref={timelineform}>
				Adicione as atividades:
			</div>
			<div className="col-12">
				<div className="form-group">
					<div className="custom-control custom-switch custom-switch-off-secondary custom-switch-on-info">
						<input type="checkbox" className="custom-control-input" id="fixedServiceTimeLine"
							checked={fixedServiceTimeLine} onChange={() => seFiexedServiceTimeLine(!fixedServiceTimeLine)}
						/>
						<label className="custom-control-label" htmlFor="fixedServiceTimeLine">Fixar Atividade</label>
					</div>
				</div>
			</div>
			<SelectServiceTimeLine handleAddItem={handleAddItem} isQtd />
			{pvs_cod && (
				<div className="col-12 mb-3">
					<button className="btn btn-secondary" onClick={handleCancel}>
						<i className="fa fa-times-circle mr-2"></i>
						Cancelar a alteração
					</button>
				</div>
			)}
		</div>
	)
}
export default FormTimeLine