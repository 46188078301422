import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { requestContext } from '../../../context/requestContext';
const ModalNotications = ({ show, setShow, notifications, removeNotification }) => {
    const { check_inspection, getItemCheckInspection } = useContext(requestContext)
    const handleClose = () => {
        setShow(!show)
    }
    const handlePage = (item) => {
        if (!item) {
            return
        }
        const { label } = item
        const { current_page, last_page } = check_inspection
        window.scrollTo(0, 0);
        if (label === 'pagination.previous') {
            return getItemCheckInspection(current_page > 1 && `0?page=${current_page - 1}`)
        }
        if (label === 'pagination.next') {
            return getItemCheckInspection(current_page < last_page && `0?page=${current_page + 1}`)

        }
        if (!label) {
            return getItemCheckInspection()
        }
        return getItemCheckInspection(`0?page=${label}`)
    }
    return (
        <div className={show ? 'modal fade show' : 'modal fade'} id='modal-default' style={{
            display: show ? 'block' : 'none', paddingRight: 15,
            overflowY: 'scroll',
        }} aria-modal='true' role='dialog'>
            <div className='modal-dialog modal-xl'>
                <div className='modal-content'>
                    <div className='modal-header'>
                        <h4 className='modal-title'>Notificações</h4>
                        <button onClick={handleClose} type='button' className='close' data-dismiss='modal' aria-label='Close'>
                            <span aria-hidden='true'>×</span>
                        </button>
                    </div>
                    <div className='modal-body'>
                        {Array.isArray(notifications) &&
                            notifications.map((item, i) => (
                                <Link to='#' key={i} className='dropdown-item'
                                    onClick={() => removeNotification(item)}
                                >
                                    <div className='row p-1'>
                                        <div className='col-1 mb-3'>
                                            <i className='far fa-bell'></i>
                                        </div>
                                        <div className='col-10'>
                                            <div className='col-12 text-wrap bd-highlight'>
                                                {item.message}
                                            </div>
                                            <div className='col-12'>
                                                <span className='float-left text-muted text-sm'>
                                                    <i className='fa fa-clock-o' aria-hidden='true'></i> {item?.hours}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='dropdown-divider'></div>
                                </Link>
                            ))
                        }
                        <ul className='pagination pagination-sm m-0 float-right'>
                            {Array.isArray(check_inspection?.links) &&
                                check_inspection.links.map((item, i) => (
                                    <li className='page-item' key={item.label}>
                                        <Link
                                            className={`page-link ${item.active && 'text-light bg-info'
                                                }`}
                                            to='#'
                                            onClick={() => handlePage(item)}
                                        >
                                            {item.label === 'pagination.previous' ? (
                                                <i className='fa fa-angle-left'></i>
                                            ) : item.label === 'pagination.next' ? (
                                                <i className='fa fa-angle-right'></i>
                                            ) : (
                                                item.label
                                            )}
                                        </Link>
                                    </li>
                                ))}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default ModalNotications