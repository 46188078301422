import React, { useContext, useEffect } from 'react'
import { formContext } from '../../../context/formContext'
import { requestContext } from '../../../context/requestContext'
import { styleButtonSearch } from '../../../css/globlalStyle'
import { handleWaring } from '../../../services/isValid'
const SelectComplements = ({ col }) => {
	const { state, handleForm, onChangeSelectInput } = useContext(formContext)
	const { complements, setInfoRequest, getAllItemComplements } = useContext(requestContext)
	const { comp_cod, comp_descricao, pv_loc_obs } = state
	useEffect(() => {
		getAllItemComplements()
	}, [])
	const handleAdd = () => {
		if (comp_descricao) {
			const search = comp_descricao
			const indexsearch = pv_loc_obs.indexOf(search)
			if (indexsearch !== -1) {
				handleForm({
					comp_cod: '',
					comp_descricao: '',
				})
				return setInfoRequest(
					handleWaring(`Você já adicionou ${comp_descricao} como complemento`)
				)
			}
			handleForm({
				pv_loc_obs: `${pv_loc_obs ? `${pv_loc_obs},` : pv_loc_obs
				} ${comp_descricao}`,
			})
			handleForm({
				comp_cod: '',
				comp_descricao: '',
			})
		}
	}
	return (
		<div className={col || 'form-group col-md-12 col-sm-12'}>
			<label>Sugestões de Complementos:</label>
			<div className="input-group">
				{comp_descricao ? (
					<div className="form-control">
						{comp_descricao}
					</div>
				) : (
					<select
						name="comp_cod"
						onChange={onChangeSelectInput}
						className={`form-control ${!comp_cod && 'is-invalid'
						}`}
					>
						<option value={null}>Selecionar</option>
						{Array.isArray(complements?.data) &&
							complements.data.map((item) => (
								<option key={item.comp_cod} value={JSON.stringify(item)}>
									{item.comp_descricao}
								</option>
							))}
					</select>
				)}
				<div className="input-group-prepend">
					<span
						className="input-group-text bg-info"
						style={styleButtonSearch}
						onClick={handleAdd}
					>
						<i className="fa fa-plus" aria-hidden="true"></i>
					</span>
				</div>
			</div>
		</div>
	)
}
export default SelectComplements
