import React, { useContext } from "react"
import { formContext } from "../../../context/formContext"
import { requestContext } from "../../../context/requestContext"
import { authContext } from "../../../context/authContext"
const SearchService = ({ handleNew }) => {
    const { state, onChange } = useContext(formContext)
    const { getItemUsers } = useContext(requestContext)
    const { user } = useContext(authContext)

    const {
        name,
        set_nivel,
        set_cod,
        conc_cod,
        gp_cod,
    } = state
    const handleSearch = () => {
        getItemUsers({
            id: 0,
            set_nivel,
            set_cod,
            conc_cod,
            gp_cod,
            desc
        })
    }
    const handleRefresh = () => {
        getItemUsers({
            id: 0,
            set_nivel,
            set_cod,
            conc_cod,
            gp_cod,
        })
    }
    return (
        <div className="col-6">
            <div className="input-group input-group-sm">
                <div className="input-group-append">
                    <button className="btn btn-tool btn-sm" onClick={handleRefresh}>
                        Atualizar lista
                    </button>
                </div>
                {user?.set_nivel <= 1 && (
                    <div className="input-group-append">
                        <button className="btn btn-tool btn-sm" onClick={handleNew}>
                            <i className="fas fa-plus"></i> Novo
                        </button>
                    </div>
                )}
                <input type="text" className="form-control float-right"
                    name="name" value={name} onChange={onChange}
                    placeholder="Digite aqui para pesquisar" />
                <div className="input-group-append">
                    <button className="btn btn-default" onClick={handleSearch}>
                        <i className="fas fa-search"></i>
                    </button>
                </div>
            </div>
        </div>
    )
}
export default SearchService