import React, { useContext, useEffect, useRef, useState } from 'react'
import { formContext } from '../../../context/formContext'
import { getExt, generateBase64 } from '../../../services/files'
import { requestContext } from '../../../context/requestContext'
import Spinner from '../../spinner'
import { styleButtonPlus } from '../../../css/globlalStyle'
const FormHelp = () => {
	const { state, onChange, handleForm, bases64, setBases64 } = useContext(formContext)
	const { help_desc, ane_pl_ob_obs, email, name } = state
	const { setLoading } = useContext(requestContext)
	const input_file_ref = useRef()
	useEffect(() => {
		handleForm({
			ane_pl_ob_obs: '',
			help_desc: '',
		})
		setBases64(null)
	}, [])
	const [file, setFile] = useState({
		base64: '',
		fileName: '',
	})
	const onChangeFile = ({ target }) => {
		const { files } = target
		if (files.length === 0) {
			return setFile({
				fileName: '',
				base64: '',
			})
		}
		if (files.length > 1) {
			setFile({
				base64: files,
				fileName: `${files.length} adicionados`,
			})
		} else {
			const { name } = files[0]
			setFile({
				base64: files,
				fileName: name,
			})
		}
	}
	const handleAttachments = () => {
		input_file_ref.current.click()
	}
	const handleAdd = async () => {
		if (!file.base64 || !ane_pl_ob_obs) {
			return
		}
		const newFiles = []
		setLoading(true)
		for (let i = 0; i < file.base64.length; i++) {
			const element = file.base64[i]
			const { name } = element
			const ext = getExt(name)
			const filegenerateBse64 = await generateBase64(element)
			newFiles.push({
				fileName: name,
				ane_pl_ob_path: filegenerateBse64,
				ane_pl_ob_obs,
				ext,
			})
		}
		if (Array.isArray(bases64)) {
			return setBases64([...bases64, ...newFiles])
		}
		setBases64([...newFiles])
		handleForm({
			ane_pl_ob_obs: '',
		})
	}
	const handleResetValues = () => {
		setLoading(false)
		setFile({
			fileName: '',
			base64: '',
		})
		handleForm({
			ane_pl_ob_obs: '',
		})
	}
	return (
		<div className="col-12">
			<div className="card card-info">
				<div className="card-header">
					<h3 className="card-title">Como podemos Tutorial?</h3>
					<div className="card-tools">
						<button type="button" className="btn btn-tool" data-card-widget="collapse">
							<i className="fas fa-minus"></i>
						</button>
						<button type="button" className="btn btn-tool" data-toggle="modal" data-target="#concessionaria">
							<i className="fa fa-question-circle"></i>
						</button>
					</div>
				</div>
				<div className="card-body">
					<div className="row">
						<div className="form-group col-md-6 col-sm-12">
							<label>Seu email:</label>
							<div className="input-group">
								<input type="text" onChange={onChange}
									className={`form-control ${!email && 'is-invalid'}`}
									name='email' value={email}
									placeholder='Digite aqui seu e-mail de login' />
							</div>
						</div>
						<div className="form-group col-md-6 col-sm-12">
							<label>Seu Nome:</label>
							<div className="input-group">
								<input type="text" onChange={onChange}
									className={`form-control ${!name && 'is-invalid'}`}
									name='name' value={name}
									placeholder='Seu nome' />
							</div>
						</div>
					</div>
					<div className="form-group col-12">
						<label>Descreva brevemente o seu problema ou dúvida:</label>
						<div className="input-group">
							<textarea
								type="text"
								className={`form-control ${!help_desc && 'is-invalid'
								}`}
								onChange={onChange}
								name="help_desc"
								value={help_desc}
								maxLength={500}

								placeholder="Escreva aqui sua dúvida ou problema"
								rows={10}
							/>
						</div>
					</div>
				</div>
			</div>
			<div className="card card-info">
				<div className="card-header">
					<h3 className="card-title">Anexar print ou arquivo</h3>
					<div className="card-tools">
						<button type="button" className="btn btn-tool" data-card-widget="collapse">
							<i className="fas fa-minus"></i>
						</button>
						<button type="button" className="btn btn-tool" data-toggle="modal" data-target="#concessionaria">
							<i className="fa fa-question-circle"></i>
						</button>
					</div>
				</div>
				<div className="card-body">
					<div className="form-group col-md-2 col-sm-6">
						<label>Anexar:</label>
						<div className="input-group">
							<div className="btn btn-dark btn-sm" onClick={handleAttachments}>
                               Clique aqui para selecionar o anexo <i className="fa fa-paperclip"></i> {file.fileName}
							</div>
						</div>
					</div>
					<div className="form-group col-12">
						<label>Descrição do anexo:</label>
						<div className="input-group">
							<textarea
								type="text"
								onChange={onChange}
								rows={2}
								name="ane_pl_ob_obs"
								value={ane_pl_ob_obs}
								className={`form-control ${!ane_pl_ob_obs && 'is-invalid'
								}`}
								placeholder="Descreva brevemente o que é esse anexo"
							>
								{ane_pl_ob_obs}
							</textarea>
							<div className="input-group-prepend">
								<span
									className="input-group-text bg-info"
									onClick={() =>
										handleAdd().finally(() => {
											handleResetValues()
										})
									}
									style={styleButtonPlus}
								>
									<Spinner label=" " />
									<i className="fa fa-plus" aria-hidden="true"></i>
								</span>
							</div>
						</div>
					</div>
					<input
						name="file_attachments"
						className="d-none"
						type="file"
						onChange={onChangeFile}
						ref={input_file_ref}
						multiple
					/>
				</div>
			</div>
		</div>
	)
}
export default FormHelp