import React, { useContext, useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { authContext } from '../../context/authContext'
import { formContext } from '../../context/formContext'
import { requestContext } from '../../context/requestContext'
import ContentHeader from '../../components/contentHeader'
import SearchOversight from '../../components/form/searchOversight'
import ListOversight from '../../components/list/oversight'
import Spinner from '../../components/spinner'
import { recoverCache, storageCache } from '../../services/cacheManager'
import { namesCache, namesScreens } from '../../constants'
import FormReport from '../../components/form/report'
import { handleError, handleWaring } from '../../services/isValid'
import handleExportReport from '../../services/spreedsheet'
import ButtonsExportReport from '../../components/form/report/exportReport'
const OverSight = () => {
	const [selectedItems, setSelectedItems] = useState([])
	const [selectAll, setSelectAll] = useState(false)
	const recoverLassActualPage = () => {
		const ACTUAL_PAGE = recoverCache(namesCache.ACTUAL_PAGE)
		return ACTUAL_PAGE
	}
	const { state: {
		ped_venda_status,
		serv_cat_prazo_from,
		serv_cat_prazo_to,
		type_date,
		conc_cod,
		rod_cod,
		gp_cod,
		desc,
		rod_km_ini_from,
		rod_km_ini_to,
		serv_cod,
		serv_cat_cod,
		serv_sub_cat_cod,
		rod_sent_cod,
	},
		handleForm } = useContext(formContext)
	const {
		oversight,
		data,
		setHighway,
		setOversight,
		setServicecat,
		setServiceSubcat,
		getItemOversight,
		getItemAllStatusOversight,
		setInfoRequest
	} = useContext(requestContext)
	const { user } = useContext(authContext)
	const navigate = useNavigate()
	useEffect(() => {
		setHighway(null)
		getItemAllStatusOversight()
	}, [])
	const fetchItems = () => {
		if (!ped_venda_status) {
			return
		}
		return fecthOversight()
	}
	useEffect(() => {
		fetchItems()
	}, [ped_venda_status])
	const fecthOversight = () => {
		setSelectAll(false)
		setSelectedItems([])
		getItemOversight({
			id: ped_venda_status || 0,
			conc_cod: conc_cod,
			rod_cod: rod_cod,
			ped_venda_date_from: serv_cat_prazo_from,
			ped_venda_date_to: serv_cat_prazo_to,
			serv_cod,
			serv_cat_cod,
			serv_sub_cat_cod,
			type_date: type_date,
			pv_loc_km_ini_from: rod_km_ini_from || null,
			pv_loc_km_ini_to: rod_km_ini_to || null,
			pv_loc_sentido: rod_sent_cod || null,
			desc,
			gp_cod: recoverLassActualPage() ? (`${gp_cod || 0}?page=${recoverLassActualPage()}`) : (gp_cod || 0),
		})
	}

	const handlePage = (item) => {
		if (!item) {
			return
		}
		const { label } = item
		const { current_page, last_page } = oversight
		window.scrollTo(0, 0)
		if (label === 'pagination.previous') {
			storageCache(namesCache.ACTUAL_PAGE, current_page - 1)
			return getItemOversight(current_page > 1 && {
				id: ped_venda_status,
				conc_cod: conc_cod,
				rod_cod: rod_cod,
				ped_venda_date_from: serv_cat_prazo_from,
				ped_venda_date_to: serv_cat_prazo_to,
				serv_cod,
				serv_cat_cod,
				serv_sub_cat_cod,
				type_date: type_date,
				pv_loc_km_ini_from: rod_km_ini_from || null,
				pv_loc_km_ini_to: rod_km_ini_to || null,
				pv_loc_sentido: rod_sent_cod || null,
				desc,
				gp_cod: `${gp_cod}?page=${current_page - 1}`,
			})
		}
		if (label === 'pagination.next') {
			storageCache(namesCache.ACTUAL_PAGE, current_page + 1)
			return getItemOversight(current_page < last_page && {
				id: ped_venda_status,
				conc_cod: conc_cod,
				rod_cod: rod_cod,
				ped_venda_date_from: serv_cat_prazo_from,
				ped_venda_date_to: serv_cat_prazo_to,
				serv_cod,
				serv_cat_cod,
				serv_sub_cat_cod,
				type_date: type_date,
				pv_loc_km_ini_from: rod_km_ini_from || null,
				pv_loc_km_ini_to: rod_km_ini_to || null,
				pv_loc_sentido: rod_sent_cod || null,
				desc,
				gp_cod: `${gp_cod}?page=${current_page + 1}`,
			})
		}
		if (!label) {
			storageCache(namesCache.ACTUAL_PAGE, null)
			return getItemOversight({
				id: ped_venda_status,
				conc_cod: conc_cod,
				rod_cod: rod_cod,
				ped_venda_date_from: serv_cat_prazo_from,
				ped_venda_date_to: serv_cat_prazo_to,
				serv_cod,
				serv_cat_cod,
				serv_sub_cat_cod,
				type_date: type_date,
				pv_loc_km_ini_from: rod_km_ini_from || null,
				pv_loc_km_ini_to: rod_km_ini_to || null,
				pv_loc_sentido: rod_sent_cod || null,
				desc,
				gp_cod: gp_cod,
			})
		}
		storageCache(namesCache.ACTUAL_PAGE, label)
		return getItemOversight({
			id: ped_venda_status,
			conc_cod: conc_cod,
			rod_cod: rod_cod,
			ped_venda_date_from: serv_cat_prazo_from,
			ped_venda_date_to: serv_cat_prazo_to,
			serv_cod,
			serv_cat_cod,
			serv_sub_cat_cod,
			type_date: type_date,
			pv_loc_km_ini_from: rod_km_ini_from || null,
			pv_loc_km_ini_to: rod_km_ini_to || null,
			pv_loc_sentido: rod_sent_cod || null,
			desc,
			gp_cod: `${gp_cod || 0}?page=${label}`,
		})
	}
	const handleNew = () => {
		const getAPK = recoverCache(namesCache.GET_APK)
		if (!getAPK) {
			const confirme = window.confirm(
				'Para melhor experiência sugerimos você baixar o aplicativo da ARTESP para abertura de ocorrências, posso baixar o aplicativo? Pressione OK para baixar'
			)
			if (confirme) {
				storageCache(namesCache.GET_APK, 'yes')
				return navigate(namesScreens.VERSION)
			}
			storageCache(namesCache.GET_APK)
		}
		return navigate(namesScreens.OVERSIGHTNEW)
	}
	const resetValues = () => {
		window.scrollTo(0, 0)
		setOversight(null)
		setServicecat(null)
		setServiceSubcat(null)
		setSelectAll(false)
		setSelectedItems([])
		const cleanState = {
			conc_cod: '',
			rod_cod: '',
			rod_sent_cod: '',
			serv_cod: '',
			serv_desc: '',
			serv_cat_cod: '',
			serv_cat_desc: '',
			serv_sub_cat_cod: '',
			serv_sub_cat_desc: '',
			rod_sent_desc: '',
			serv_cat_prazo_from: '',
			serv_cat_prazo_to: '',
			rod_km_ini_from: '',
			rod_km_ini_to: '',
			ped_venda_status: '',
			st_cod: 0,
			gp_cod: '',
			conc_nome: '',
			rod_desc: '',
		}
		if (data?.data?.length === 1 && user?.set_nivel === 3) {
			delete cleanState.conc_cod
			delete cleanState.conc_nome
		}
		handleForm(cleanState)
		storageCache(namesCache.ACTUAL_PAGE)
	}
	const fetchItemsPaginated = async () => {
		try {
			const items = []
			if (oversight?.per_page) {
				const { last_page, from } = oversight
				let page = from + 1
				while (page <= last_page) {
					const response = await getItemOversight({
						is_report: true,
						id: ped_venda_status,
						conc_cod: conc_cod,
						rod_cod: rod_cod,
						ped_venda_date_from: serv_cat_prazo_from,
						ped_venda_date_to: serv_cat_prazo_to,
						serv_cod,
						serv_cat_cod,
						serv_sub_cat_cod,
						type_date: type_date,
						pv_loc_km_ini_from: rod_km_ini_from || null,
						pv_loc_km_ini_to: rod_km_ini_to || null,
						rod_sent_cod,
						desc,
						gp_cod: `${gp_cod || 0}?page=${page}`,
					})
					if (response?.data) {
						const { current_page, data } = response
						page = current_page + 1
						data.forEach(item => items.push(item))
					}
				}
			}
			return items
		} catch (error) {
			setInfoRequest(handleError(String(error)))
		}
	}
	const generateDate = async () => {
		setInfoRequest(handleWaring('Aguarde o relatório ser gerado.'))
		const items = await fetchItemsPaginated()
		oversight.data?.forEach(item => items.push(item))
		handleExportReport({
			report: { data: items },
			setInfoRequest: setInfoRequest
		})
	}
	useEffect(() => {
		const controllSelectAll = async () => {
			if (!selectAll) {
				return setSelectedItems([])
			}
			setInfoRequest(handleWaring(`Aguarde, estamos selecionando todas as Ncs. Dependendo da quantidade de Ncs esse processo pode demorar um pouco`))
			const items = await fetchItemsPaginated()
			setSelectedItems([...items.map(item => item.ped_venda_cod), ...oversight?.data?.map(item => item.ped_venda_cod)])
		}
		controllSelectAll().finally(() => {
			if (selectAll) {
				setInfoRequest(handleWaring('Todos as Ncs foram selecionadas'))
				const buttonExport = document.getElementById('menuExportReport')
				if (buttonExport?.click && buttonExport.scrollIntoView) {
					buttonExport?.click()
					buttonExport?.scrollIntoView()
				}
			}
		})
	}, [selectAll])
	const RenderLinkFooter = ({ item }) => {
		return (
			<li className="page-item" key={item.label}>
				<Link
					className={`page-link ${item.active && 'text-light bg-info'
						}`}
					to="#"
					onClick={() => handlePage(item)}
				>
					{item.label === 'pagination.previous' ? (
						<i className="fa fa-angle-left"></i>
					) : item.label === 'pagination.next' ? (
						<i className="fa fa-angle-right"></i>
					) : (
						item.label
					)}
				</Link>
			</li>
		)
	}
	return (
		<>
			<ContentHeader title={'Não conformidades de Rotina'} />
			<div className="content">
				<div className="card">
					<div className="card-header">
						<h3 className="card-title d-md-block d-none">Todas ocorrências</h3>
						<div className="card-tools">
							<SearchOversight handleNew={handleNew} />
						</div>
					</div>
					<div className="card-body">
						<FormReport />
					</div>
				</div>
				<ButtonsExportReport
					fetchItems={fetchItems}
					generateDate={generateDate}
					resetValues={resetValues}
					selectedItems={selectedItems}
					selectAll={selectAll}
					setSelectAll={setSelectAll}
				/>
				<div className='card'>
					<div className="card-body">
						<Spinner direction={'center'} label="carregando" />
						<ListOversight data={oversight}
							selectedItems={selectedItems}
							setSelectedItems={setSelectedItems}
						/>
					</div>
					<div className="card-footer clearfix">
						<ul className="pagination pagination-sm m-0 float-right">
							{Array.isArray(oversight?.links) &&
								oversight.links.map((item) => (
									<RenderLinkFooter item={item} key={item.label} />
								))}
						</ul>
						{user && user.set_nivel <= 2 && (
							<ul className="pagination pagination-sm m-0 float-left">
								<li className="page-item">
									<div className="page-link" onClick={handleNew}>
										Novo <i className="fa fa-plus mr-1 ml-1"></i>
									</div>
								</li>
							</ul>
						)}
					</div>
				</div>
			</div>
		</>
	)
}
export default OverSight
