import { handleExportXLS } from '../../components/excel'
import { formatDate, formateHours } from '../dateHandless'
import { handleWaring } from '../isValid'

const handleExportReport = ({
    report,
    setInfoRequest,
}) => {
    try {
        const { data } = report
        if (!Array.isArray(data)) {
            return setInfoRequest(handleWaring('Não há NCs no relatório para serem exportadas'))
        }
        const result = []
        for (const item of data) {
            const { fiscalizacao } = item
            result.push({
                'ID': item.sisf_sinc_id || item.ped_venda_cod,
                'DATA FISCALIZACAO': formatDate(item.ped_venda_date),
                'HORA FISCALIZACAO': formateHours(item.ped_venda_date),
                'RODOVIA': item.rod_desc,
                'CONCESSIONARIA': item.conc_nome,
                'LOTE': item.lot_conc_desc,
                'CODIGO RODOVIA': item.rod_km,
                'KM INICIAL': item.pv_loc_km_ini,
                'M INICIAL': item.pv_loc_km_init_mts,
                'KM FINAL': item.pv_loc_km_fim,
                'M FINAL': item.pv_loc_km_fim_mts,
                'SENTIDO': item.rod_sent_desc,
                'DATA RETORNO': '',
                'STATUS RETORNO': item.st_desc,
                'TIPO ATIVIDADE': item.serv_desc,
                'GRUPO ATIVIDADE': item.serv_cat_desc,
                'ATIVIDADE': item.pvs_obs,
                'NUMERO NOTIFICACAO': '',
                'DATA ENVIO': item.ped_venda_date,
                'DATA REPARO': item.pvs_prazo_fim,
                'RESPONSAVEL TECNICO': item.name,
                'HORA ENVIO': item.ped_venda_date,
                'LATITUDE': item.pv_loc_lat,
                'LONGITUDE': item.pv_loc_lng,
                'DATA FISC': '',
                'CONCESSIONÁRIA GERAL': '',
            })
            if (fiscalizacao?.length) {
                for (const fisc of fiscalizacao) {
                    result.push({
                        'ID': item.sisf_sinc_id || item.ped_venda_cod,
                        'DATA FISCALIZACAO': '',
                        'HORA FISCALIZACAO': '',
                        'RODOVIA': '',
                        'CONCESSIONARIA': '',
                        'LOTE': '',
                        'CODIGO RODOVIA': '',
                        'KM INICIAL': '',
                        'M INICIAL': '',
                        'KM FINAL': '',
                        'M FINAL': '',
                        'SENTIDO': '',
                        'DATA RETORNO': '',
                        'STATUS RETORNO': fisc.fisc_obs,
                        'TIPO ATIVIDADE': '',
                        'GRUPO ATIVIDADE': '',
                        'ATIVIDADE': '',
                        'NUMERO NOTIFICACAO': '',
                        'DATA ENVIO': '',
                        'DATA REPARO': '',
                        'RESPONSAVEL TECNICO': fisc.name,
                        'HORA ENVIO': '',
                        'LATITUDE': fisc.fisc_lat,
                        'LONGITUDE': fisc.fisc_lng,
                        'DATA FISC': formatDate(fisc.created_at),
                        'CONCESSIONÁRIA GERAL': '',
                    })
                }
            }
        }
        const headers = Object.keys(result[0]).map(item => ({
            label: item.replace(/_/g, ' '),
            key: item
        }))
        const csvdata = {
            data: result,
            headers: headers,
            fileName: `Relatório-${formatDate(new Date())}`,
        }
        handleExportXLS({ setInfoRequest, data: csvdata.data, headers: csvdata.headers, fileName: csvdata.fileName })
    } catch (error) {
        console.log(error)
    }
}
export default handleExportReport