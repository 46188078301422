import { LOCAL_URL } from './local-config'
import logo from '../assets/Artesp/images/logo.svg'
import relatorio_logo from '../assets/Artesp/images/relatorio_logo.png'
import Package from '../../package.json'
import markerPrimary from '../assets/icons/marker_#007bff.png'
import markerWarning from '../assets/icons/marker_#ffc107.png'
import markerSuccess from '../assets/icons/marker_#28a745.png'
import markerDanger from '../assets/icons/marker_#dc3545.png'
const FLAVOR = 'Artesep SGI-DIN'
const URL_TESTE = 'https://dswebtestapis.ddns.net'
const URL_HOMOL = ''
const URL_PRODUCTION = 'https://www.dswebapis.com.br'
const URL_DEVELOP = LOCAL_URL
const LOGO = logo
const RELATORIO_LOGO = relatorio_logo
const VERSION = Package.version
const OFFLINE_POST = true
const CONTACT = '5519999705447'
const URL_FILE = 'https://artesp-production.s3.amazonaws.com'
const URL_FILE_TESTE = 'https://artesp-test.s3.amazonaws.com'
const URL_FILE_HOMOL = 'https://artesp-sisf.s3.amazonaws.com'
const URL_FILE_DEVELOP = `${LOCAL_URL}/storage`
const API_VERSION = '1.0.40'
const MARKER_ICONS = [
	{
		path: markerPrimary,
		name: 'marker_#007bff',
	},
	{
		path: markerWarning,
		name: 'marker_#ffc107',
	},
	{
		path: markerSuccess,
		name: 'marker_#28a745',
	},
	{
		path: markerDanger,
		name: 'marker_#dc3545',
	},
]
export const CAMERA_CONFIG = {
	constraints: {
		audio: false,
		video: {
			width: 1024,
			height: 720,
			facingMode: 'environment',
		},
	},
}
export const PDF_CONFIG = {
	orientation: 'landscape',
	unit: 'pt',
	format: 'A4',
	options: {
		filename: new Date().toString(),
	},
}
export {
	FLAVOR,
	URL_TESTE,
	URL_HOMOL,
	URL_PRODUCTION,
	URL_DEVELOP,
	LOGO,
	VERSION,
	OFFLINE_POST,
	CONTACT,
	URL_FILE,
	URL_FILE_TESTE,
	URL_FILE_HOMOL,
	URL_FILE_DEVELOP,
	API_VERSION,
	MARKER_ICONS,
	RELATORIO_LOGO
}