import React, { useContext, useEffect } from 'react'
import { formContext } from '../../../context/formContext'
import { requestContext } from '../../../context/requestContext'
const SelectSector = ({ col }) => {
	const { state: {
		set_cod,
		set_desc,
	},
		onChangeSelectInput,
	} = useContext(formContext)
	const { sector, getItemSector } = useContext(requestContext)
	useEffect(() => {
		getItemSector()
	}, [])
	return (
		<div className={col ? `form-group ${col}` : 'form-group col-md-6 col-sm-12'}>
			<label>Setor</label>
			<div className="input-group">
				<select
					value={set_cod}
					name="set_cod"
					onChange={onChangeSelectInput}
					className={`form-control ${!set_cod ? 'is-invalid' : ''}`}
				>
					{set_desc ? <option>{set_desc}</option> : <option>Selecionar</option>}
					{Array.isArray(sector?.data) && (
						sector.data.map(item => (
							<option key={item.set_cod} value={JSON.stringify(item)}>
								{item.set_desc}
							</option>
						))
					)}
				</select>
			</div>
		</div>
	)
}
export default SelectSector