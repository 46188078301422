import React, { useContext, useEffect, useState } from 'react'
import { formContext } from '../../../context/formContext'
import { requestContext } from '../../../context/requestContext'
import { styleButtonSearch } from '../../../css/globlalStyle'
import { authContext } from '../../../context/authContext'
import { namesScreens } from '../../../constants'
import { useLocation } from 'react-router-dom'
const Dealership = ({ col, selectToUser }) => {
	const { state, onChangeSelectInput, handleForm } = useContext(formContext)
	const { data, getAllItemDealership } = useContext(requestContext)
	const { user, userProfile } = useContext(authContext)
	const [disabled, setDisabled] = useState(false)
	const location = useLocation()
	useEffect(() => {
		getAllItemDealership()
	}, [])
	useEffect(() => {
		const controllDisabledInputs = () => {
			const { pathname } = location
			if (pathname === namesScreens.REPORT_CONSTRUCTION_PLAN) {
				return setDisabled(true)
			}
			return setDisabled(false)
		}
		controllDisabledInputs()
	}, [location])
	const {
		conc_cod,
		reload,
		conc_nome
	} = state
	const cancelSelect = () => {
		handleForm({
			rod_cod: '',
			conc_cod: '',
			rod_concessionaria: '',
			conc_nome: '',
			rod_sent_desc: '',
			rod_desc: '',
			rod_km: '',
			rod_sent_cod: '',
			rod_km_ini: '',
			rod_km_fim: '',
			rod_km_ini_mt: '',
			rod_km_fim_mt: '',
			rod_extencao: '',
			rod_tipo: '',
		})
	}
	useEffect(() => {
		userProfile()
	}, [])
	useEffect(() => {
		const checkUserLevel = () => {
			if (user?.set_nivel === 3) {
				handleForm({ conc_cod: user.usu_emp, conc_nome: user.name })
				setDisabled(true)
			}
		}
		checkUserLevel()
	}, [user])
	const isSelectToUser = (el) => {
		if (selectToUser) {
			return el.lot_conc_desc !== null
		}
		return el.lot_conc_desc !== '0'
	}
	return (
		<div className={`form-group ${col || 'col-12'}`}>
			<label>Concessionária N° lote</label>
			<div className="input-group">
				{conc_nome ?
					<div className='form-control'>
						{conc_nome}
					</div>
					:
					<select onChange={onChangeSelectInput}
						className={`form-control ${!conc_cod && 'is-invalid'}`}
						disabled={disabled}
					>
						<option value={JSON.stringify({ reload: !reload })}>Selecionar uma Concessionária</option>
						{Array.isArray(data?.data) && data.data.filter(isSelectToUser).sort((a, b) => a.lot_conc_desc - b.lot_conc_desc).map(item => (
							<option key={parseInt(item.conc_cod)} value={JSON.stringify(item)}>
								Lote {item.lot_conc_desc} - {item.conc_nome}
							</option>
						))}
					</select>
				}
				{conc_nome && !disabled &&
					<div className="input-group-prepend" onClick={cancelSelect}>
						<span className="input-group-text bg-info" style={styleButtonSearch}>
							<i className="fa fa-times-circle" aria-hidden="true"></i>
						</span>
					</div>
				}
			</div>
		</div>
	)
}
export default Dealership