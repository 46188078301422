import React, { useContext } from 'react'
import { formContext } from '../../../context/formContext'
const FormTypeOversight = () => {
	const { state, onChange } = useContext(formContext)
	const { tip_ped_venda_desc } = state
	return (
		<div className="row">
			<div className="form-group col-md-12 col-sm-12">
				<label>Descrição Tipo NC:</label>
				<div className="input-group">
					<input
						type="text"
						onChange={onChange}
						name="tip_ped_venda_desc"
						value={tip_ped_venda_desc}
						className={`form-control ${
							!tip_ped_venda_desc && 'is-invalid'
						}`}
						placeholder="Descrição da Categoria"
					/>
				</div>
			</div>
		</div>
	)
}
export default FormTypeOversight
