import React, { useContext, useEffect, useState } from 'react'
import { formatDate } from '../../../../services/dateHandless'
import { Link } from 'react-router-dom'
import SimpleModal from '../../../modal/simple'
import HeaderTable from './header'
import ModalSegments from '../../../modal/pavement/segments'
import { formContext } from '../../../../context/formContext'
import { searchSomeWordInString } from '../../../../services/stringHandless'
import RenderIPC from './renderIPC'
import { loadConfigIGG, loadConfigIRI } from '../../../../services/paviment'
import { requestContext } from '../../../../context/requestContext'
import { handleWaring } from '../../../../services/isValid'
import { filter, filterAndRemove, filterAndRemoveRepeated } from '../../../../services/arrayHandless'
import ModalMonitoring from '../../../modal/pavement/monitoring'
const TableSegments = ({ data, crossMonitoring, widthList, setData }) => {
	const { state: { desc, rod_km }, onChange } = useContext(formContext)
	const { setInfoRequest } = useContext(requestContext)
	const [smallerValueIRI, setSmallerValueIRI] = useState(null)
	const [smallerValueIGG, setSmallerValueIGG] = useState(null)
	const [items, setItems] = useState(null)
	useEffect(() => {
		const loadItems = () => {
			if (data) {

				setItems(data)
			}
		}
		loadItems()
	}, [data])
	useEffect(() => {
		const loadConfig = async () => {
			try {
				await loadConfigIRI(setSmallerValueIRI)
				await loadConfigIGG(setSmallerValueIGG)
			} catch (error) {
				console.log(error)
			}
		}
		loadConfig()
	}, [])
	const [modalProperties, setModalProperties] = useState({
		modalWidth: 100,
		modalHeight: 'auto',
		modalTitle: '',
		modalChild: ''
	})
	const [show, setShow] = useState(false)
	const handleModalInfo = (item) => {
		const child = (<ModalSegments
			state={{ ...item }}
			footerInfo={'Segmento não será inserido nesse registro porque não encontramos a rodovia solicitada'}
		/>)
		setModalProperties({
			...modalProperties,
			modalTitle: `Segmento para rodovia ${item.localizacao_levantamento_indice_pavimento_consolidado.rod_km}`,
			modalChild: child
		})
		setShow(true)
	}
	const handleSearch = () => {
		if (!desc) {
			return setItems(data)
		}
		const filteredItems = data.filter(item => searchSomeWordInString(item.localizacao_levantamento_indice_pavimento_consolidado.rod_km, [desc]))
		setItems(filteredItems)
	}
	if (!Array.isArray(items)) {
		return (<div className="col-12 p-3">
			Não há itens para serem exibidos
		</div>)
	}
	const showCrossMonitoring = (lipc_id) => {
		if (!crossMonitoring?.length) {
			return setInfoRequest(handleWaring(`Você precisa Anexar RT-${rod_km || 'SP'}-486.510-628-P15-006-R0 (IGG)`))
		}
		const { data: findCrossMonitoring } = filter(crossMonitoring, 'lipc_id', lipc_id)
		if (!findCrossMonitoring?.length) {
			return setInfoRequest(handleWaring('Não há monitoramento para o trecho selecionado'))
		}
		const child = (<ModalMonitoring
			items={findCrossMonitoring}
		/>)
		const highway = filterAndRemoveRepeated(findCrossMonitoring.map(item => item.localizacao_levantamento_indice_pavimento_consolidado?.rod_km))
		setModalProperties({
			modalWidth: 100,
			modalHeight: 100,
			modalTitle: `Monitoramento para rodovia ${highway.join(',')}`,
			modalChild: child
		})
		setShow(true)
	}
	const handleDelete = (lipc_id) => {
		const { data: filteredData } = filterAndRemove(data, 'lipc_id', lipc_id)
		setData(filteredData)
	}
	return (
		<div className='col-12'>
			<SimpleModal
				visible={show}
				setVisible={setShow}
				properties={modalProperties}
			/>
			<div className='col-12 mb-3 mt-3'>
				<div className="input-group input-group-sm mb-3">
					<input type="text" className="form-control float-right"
						name="desc" value={desc} onChange={onChange}
						placeholder="Procurar por Código da Rodovia" />
					<div className="input-group-append">
						<div className="btn btn-default" onClick={handleSearch}>
							<i className="fas fa-search"></i>
						</div>
					</div>
				</div>
			</div>
			<div className='table-responsive' style={{
				overflowX: 'scroll',
				maxWidth: widthList
			}}>
				<table className="table table-dark table-hover p-0 m-0">
					<HeaderTable />
					<tbody>
						{Array.isArray(items) && items.map((item, i) => {
							const {
								rod_km,
								rod_sent_desc,
								loc_lipc_km_init,
								loc_lipc_km_init_mts,
								loc_lipc_km_fim,
								loc_lipc_km_fim_mts,
								loc_lipc_obs,
							} = item.localizacao_levantamento_indice_pavimento_consolidado
							let temp_ipc_date = new Date()
							const { lipc_id } = item
							if (item.indice_pavimento_consolidado.length) {
								const { ipc_date } = item.indice_pavimento_consolidado[0]
								temp_ipc_date = ipc_date
							}
							if (!item.localizacao_levantamento_indice_pavimento_consolidado?.rod_desc) {
								return (
									<tr key={i} className='text-secondary'>
										<td>{lipc_id}</td>
										<td>{rod_km} <Link to={'#'} className='text-decoration-none' onClick={() => handleModalInfo(item)}>
											<span className="badge badge-info">
												<i className='fa fa-info bg-light'></i>
											</span>
										</Link>
										</td>
										<td>{rod_sent_desc}</td>
										<td>{loc_lipc_obs}</td>
										<td>{`${loc_lipc_km_init},${loc_lipc_km_init_mts} mts`}</td>
										<td>{`${loc_lipc_km_fim},${loc_lipc_km_fim_mts} mts`}</td>
										{Array.isArray(item?.indice_pavimento_consolidado) ? item?.indice_pavimento_consolidado.sort(
											(prev, next) => prev.tip_ind_pav_desc - next.tip_ind_pav_desc
										).map(row => {
											const {
												ipc_val,
												ipc_tip_cod,
												tip_ind_pav_desc
											} = row
											return (
												<td className='text-center' key={ipc_tip_cod}>{ipc_val} - {tip_ind_pav_desc}</td>
											)
										}) :
											<>
												<td> {0}</td>
												<td> {0}</td>
												<td> {0}</td>
											</>}
										<td> {formatDate(temp_ipc_date)}</td>
										<td className='text-center'>
											<div className='btn btn-info btn-sm' onClick={() => showCrossMonitoring(lipc_id)}>
												<i className='fa fa-eye'></i>
											</div>
										</td>
									</tr>
								)
							}
							return (
								<tr key={i}>
									<td>{lipc_id}</td>
									<td>{rod_km}</td>
									<td>{rod_sent_desc}</td>
									<td>{loc_lipc_obs}</td>
									<td>{`${loc_lipc_km_init},${loc_lipc_km_init_mts} mts`}</td>
									<td>{`${loc_lipc_km_fim},${loc_lipc_km_fim_mts} mts`}</td>
									{Array.isArray(item?.indice_pavimento_consolidado)
										? item?.indice_pavimento_consolidado.map((row, index) => (
											<RenderIPC key={index} smallerValueIRI={smallerValueIRI}
												smallerValueIGG={smallerValueIGG} row={row} />
										)) :
										<>
											<td> {0}</td>
											<td> {0}</td>
											<td> {0}</td>
										</>}
									<td> {formatDate(temp_ipc_date)}</td>
									<td className='text-center'>
										<div className='btn btn-info btn-sm' onClick={() => showCrossMonitoring(lipc_id)}>
											<i className='fa fa-eye'></i>
										</div>
									</td>
									<td className='text-center'>
										<div className='btn btn-secondary btn-sm' onClick={() => handleDelete(lipc_id)}>
											<i className='fa fa-trash'></i>
										</div>
									</td>
								</tr>
							)
						})}
					</tbody>
				</table>
			</div >
		</div>
	)
}
export default TableSegments
